
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Document } from '@/domain/Document'
import Comments from '@/infrastucture/components/Comments.vue'
import { Person } from '@/infrastucture/users/Person'

@Component({
  components: {
    Comments,
  },
})
export default class CommentsTab extends Vue {
  @Prop({ type: Object }) public document!: Document
  private notifiers: Person[]
	constructor() {
    super()
    this.notifiers = this.document.notifiers()
	}

  public get documentId(): number {
    return this.document.id
  }

  @Watch('documentId', { immediate: false })
  public onChange(): void {
    this.notifiers = this.document.notifiers()
  }
}
