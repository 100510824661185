
import { IField } from '@/global/models/Field'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IFilteredTableCollection, IFilteredTableSource } from '../models/TableCollection'
import FLInput from '@/global/components/FLInput.vue'
import FLDatePicker from '@/global/components/FLDatePicker.vue'
import FLSelect from '@/global/components/FLSelect.vue'
import { PartnerRows } from '@/partners/models/PartnerRows'
@Component({
  components: {
    FLInput,
    FLDatePicker,
    FLSelect,
  },
})
export default class Table<T> extends Vue {
  @Prop({ type: Object, required: true }) public viewModel!: IFilteredTableSource<T> | IFilteredTableCollection<T>
  @Prop({ type: Boolean, default: false }) public loading!: boolean
  @Prop({ type: String, default: 'Id' }) public rowKey!: string
  public constructor() {
    super()
  }

  public get headerSet(): IField<unknown>[] {
    if (this.viewModel instanceof PartnerRows) return (this.viewModel as IFilteredTableCollection<T>).headerSet().allFields()
    return (this.viewModel as IFilteredTableSource<T>).headerColumns()
  }

  // eslint-disable-next-line
  public row(record: T): any {
    return {
      on: {
        click: () => {
          this.$emit('rowClick', record)
        },
      },
    }
  }
}
