import { set as _set } from 'lodash'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { Partner } from '@/partners/models/Partner'
import { AddressEntity } from '@/partners/models/PartnerJson'
import { Validator } from 'vee-validate'
import { Address } from './Address'
import { DeleteRequest } from '@/api/requests/DeleteRequest'
import { TableRecordForm } from '@/global/models/TableRecordForm'
import { AnyField, IField } from '@/global/models/Field'
import { Updatable } from './Updatable'

export class PartnerAddressForm extends TableRecordForm<Address, AddressEntity> {
  private readonly partner: Partner
  public constructor(origin: Address, partner: Partner, validator: Validator) {
    super(origin, validator, 'api/partneraddresses/')
    this.partner = partner
  }

  public get type(): IField<string> {
    return this.textFields.get('type') || new AnyField()
  }

  public get postCode(): IField<string> {
    return this.textFields.get('postCode') || new AnyField()
  }

  public get area(): IField<string> {
    return this.textFields.get('area') || new AnyField()
  }

  public get settlement(): IField<string> {
    return this.textFields.get('settlement') || new AnyField()
  }

  public get street(): IField<string> {
    return this.textFields.get('street') || new AnyField()
  }

  public get house(): IField<string> {
    return this.textFields.get('house') || new AnyField()
  }

  public asEntity(): Address {
    const address = new Updatable(this.record, this.postCode.content(), this.type.content(), this.area.content(), this.settlement.content(), this.street.content(), this.house.content())
    return address
  }

  public async add(): Promise<Address> {
    const record = this.asEntity()
    const request = new AuthorizationRequest(new PostRequest(new Request<AddressEntity>(this.uri), { Partner: this.partner.entity(), Address: record.entity() }))
    const response = await request.response()
    _set(record, 'id', response.data.id)
    return record
  }

  public async remove(): Promise<Address> {
    const data = new FormData()
    data.append('partnerId', this.partner.entity().id + '')
    data.append('id', this.key + '')
    const request = new AuthorizationRequest(new DeleteRequest(new Request<void>(this.uri), data))
    await request.response()
    return this.record
  }
}
