import { Entity } from '@/global/models/Entity'
import { IEntity } from '@/infrastucture/IEntity'
import { StageInfo } from '@/infrastucture/types'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { ObjectiveData } from '@/modules/types'
import { WorkflowStage } from './WorkflowStage'
import { Exclude, instanceToPlain, plainToInstance, Type } from 'class-transformer'
import _ from 'lodash'
import { Document } from './Document'
import { BillObjective, ContractObjective, DocumentObjective, IncomingDocumentObjective, OutgoingDocumentObjective } from './DocumentObjective'
import { IProcess } from './IProcess'
import { IProcessStage } from './IProcessStage'
import { IProcessTemplate } from './IProcessTemplate'
import { Member } from './Member'
import { Objective } from './Objective'
import { ObjectiveStage } from './ObjectiveStage'
import { ProcessTemplate } from './ProcessTemplate'
import { BillWorkflowObjective, ContractWorkflowObjective, IncomingDocumentWorkflowObjective, OutgoingDocumentWorkflowObjective, WorkflowObjective } from './WorkflowObjective'
import { Request } from '@/api/requests/Request'
import store from '@/store'
import { Users } from '@/infrastucture/users/Users'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { ValueEntityByTitle } from '@/store/types'
import { PutRequest } from '@/api/requests/PutRequest'
import { User } from '@/infrastucture/users/User'
import { ObjectiveStageTypeValue } from '@/infrastucture/LookupTypes'

export class ObjectiveProcess<T extends Objective> implements IProcess, IEntity {
  @Exclude()
	// eslint-disable-next-line
  protected type!: Function
	// eslint-disable-next-line
	@Type((options: any) => (options?.newObject as ObjectiveProcess<T>).type)
  private entity: T
  @Type(() => ObjectiveStage)
  private process: ObjectiveStage[]
  @Type(() => ProcessTemplate)
  private processTemplate: IProcessTemplate
  constructor(entity: T)
  constructor(entity: T, process: ObjectiveStage[], processTemplate: IProcessTemplate)
  constructor(entity: T, process?: ObjectiveStage[], processTemplate?: IProcessTemplate) {
    this.entity = entity
    this.process = process ?? []
    this.processTemplate = processTemplate ?? new ProcessTemplate()
  }

  public asWorkflow(stage: WorkflowStage, document: Document): WorkflowObjectiveProcess {
    const objective = new WorkflowObjective(this.entity, document)
    const process = new ObjectiveProcess(objective, this.process, this.processTemplate) as WorkflowObjectiveProcess
    return process
  }
  
  public get isCompleted(): boolean {
    const recieveStageType = this.processTemplate.stageType(ObjectiveStageTypeValue.review)
    const hasReciever = this.entity.has(recieveStageType)
    const current = this.current()
    return ((current.toString() === ObjectiveStageTypeValue.execution && !hasReciever) || (current.toString() === ObjectiveStageTypeValue.review && hasReciever)) &&
      current.isCompleted;
  }

  public get isStarted(): boolean {
    return this.current().toString() !== ObjectiveStageTypeValue.staging
  }

  public get id(): number {
    return this.entity.id
  }

  public get isAny(): boolean {
    return this.entity.isAny
  }

  public addIdentity(id: number): void {
    this.entity.addIdentity(id)
  }

  public get stateValue(): string {
    return this.entity.isRejected()
      ? 'Отклонено'
      : this.isCompleted
        ? 'Завершено'
        : this.current().toString()
  }

  public async completed(): Promise<IProcessStage> {
    const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/ObjectiveProcesses/complete'), plain))
		const response = await request.response()
		const stage = plainToInstance(ObjectiveStage, response.data)
		const i = Math.max(this.process.length - 1, 0)
		this.process.splice(i, 1, stage)
    return stage
  }

  public current(): ObjectiveStage {
    const last = _.last(this.process)
    if (!last) throw new Error('current is undefined')
    return last
  }

  public equals(other: Entity): boolean {
    return this.entity.equals(other)
  }

  public equalsById(other: IEntity): boolean {
    return this.entity.equalsById(other)
  }

  public has(member: Member): boolean
  public has(stageType: ValueEntity): boolean
  public has(arg: ValueEntity | Member): boolean {
    return (arg instanceof ValueEntity && _.some(this.process, stage => stage.equals(arg))) || (arg instanceof Member && this.entity.has(arg))
  }
  
  public isAssignedTo(member: User): boolean
  public isAssignedTo(member: Member): boolean
  public isAssignedTo(member: Member | User): boolean {
    const assignedTo = this.entity.member('Исполнитель')
    return !!assignedTo && member.equalsById(assignedTo)
  }

  public async next(): Promise<IProcessStage> {
    const recieveStageType = this.processTemplate.stageType(ObjectiveStageTypeValue.review)
    const hasReciever = this.entity.has(recieveStageType)
    if (this.current().toString() === ObjectiveStageTypeValue.review || (!hasReciever && this.current().toString() === ObjectiveStageTypeValue.execution)) {
      return await this.completed()
    }
    const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/ObjectiveProcesses/next'), plain))
		const response = await request.response()
		const process = plainToInstance(DefaultObjectiveProcess, response.data)
		this.process = process.process
    return this.current()
  }

  public async revert(): Promise<IProcessStage> {
    const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/ObjectiveProcesses/revert'), plain))
		const response = await request.response()
		const process = plainToInstance(DefaultObjectiveProcess, response.data)
		this.process = process.process
    return this.current()
  }

  public async reject(): Promise<IProcessStage> {
    const plain = instanceToPlain(this.entity)
		const request = new AuthorizationRequest(new PutRequest(new Request('api/ObjectiveProcesses/reject'), plain))
		await request.response()
    const valueEntityByTitle = store.getters['manuals/valueEntityByTitle'] as ValueEntityByTitle
		const status = valueEntityByTitle('statusList', 'Отклонён')
    this.entity.changeStatus(status)
    return this.current()
  }

  public toData(): ObjectiveData {
    return this.entity.toData()
  }

  public hasAccess(): boolean {
    const users = store.getters['manuals/users'] as Users
    return !this.entity.isRejected() && !this.isCompleted && this.current().equals(users.currentUser)
  }

  public canBeRevert(): boolean {
    return this.current().toString() === 'Приёмка'
  }

  public canBeReject(): boolean {
    return this.current().toString() === 'Постановка'
  }

  public toProcessData(): StageInfo {
    const data = this.entity.toData()
    const info: StageInfo = { id: data.id, title: data.assignedTo.toString(), completed: this.current().completeDate()  }
    return info
  }

  public toString(): string {
    return this.entity.toString()
  }
}

export class DefaultObjectiveProcess extends ObjectiveProcess<Objective> {
  protected type = Objective
  constructor() {
    super(new Objective())
  }
}

export class DocumentObjectiveProcess extends ObjectiveProcess<DocumentObjective> {
  protected type = DocumentObjective
  constructor() {
    super(new DocumentObjective())
  }
}

export class ContractObjectiveProcess extends ObjectiveProcess<ContractObjective> {
  protected type = ContractObjective
  constructor() {
    super(new ContractObjective())
  }
}

export class BillObjectiveProcess extends ObjectiveProcess<BillObjective> {
  protected type = BillObjective
  constructor() {
    super(new BillObjective())
  }
}

export class IncomingDocumentObjectiveProcess extends ObjectiveProcess<IncomingDocumentObjective> {
  protected type = IncomingDocumentObjective
  constructor() {
    super(new IncomingDocumentObjective())
  }
}

export class OutgoingDocumentObjectiveProcess extends ObjectiveProcess<OutgoingDocumentObjective> {
  protected type = OutgoingDocumentObjective
  constructor() {
    super(new OutgoingDocumentObjective())
  }
}

export class WorkflowObjectiveProcess extends ObjectiveProcess<WorkflowObjective> {
  protected type = WorkflowObjective
  constructor() {
    super(new WorkflowObjective())
  }
}

export class ContractWorkflowObjectiveProcess extends ObjectiveProcess<ContractWorkflowObjective> {
  protected type = ContractWorkflowObjective
  constructor() {
    super(new ContractWorkflowObjective())
  }
}

export class BillWorkflowObjectiveProcess extends ObjectiveProcess<BillWorkflowObjective> {
  protected type = BillWorkflowObjective
  constructor() {
    super(new BillWorkflowObjective())
  }
}

export class IncomingDocumentWorkflowObjectiveProcess extends ObjectiveProcess<IncomingDocumentWorkflowObjective> {
  protected type = IncomingDocumentWorkflowObjective
  constructor() {
    super(new IncomingDocumentWorkflowObjective())
  }
}

export class OutgoingDocumentWorkflowObjectiveProcess extends ObjectiveProcess<OutgoingDocumentWorkflowObjective> {
  protected type = OutgoingDocumentWorkflowObjective
  constructor() {
    super(new OutgoingDocumentWorkflowObjective())
  }
}
