import { Contacts } from './Contacts'
import { Reporters } from '../reporters/Reporters'

export class Updatable extends Contacts {
  public constructor(origin: Contacts, reporters: Reporters)
  public constructor(origin: Contacts, webSite: string, workPhone: string, email: string)
  public constructor(...args: unknown[]) {
    const origin = args[0] as Contacts
    super(origin)
    if (args[1] instanceof Reporters) {
      const reporters = args[1] as Reporters
      this.reporters = reporters.toArray()
    } else {
      const website = (args[1] as string) ?? ''
      const workPhone = (args[2] as string) ?? ''
      const email = (args[3] as string) ?? ''
      this.webSite = website
      this.workPhone = workPhone
      this.email = email
    }
  }
}
