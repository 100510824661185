import { set as _set } from 'lodash'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { ITransferable } from './AsyncCollection'
import { IRecord } from './Record'
import { ISerilazable } from './Serilazable'
import { PutRequest } from '@/api/requests/PutRequest'
import { DeleteRequest } from '@/api/requests/DeleteRequest'

export type EntityData = { id: number }

export interface IRemoteRecord<T extends EntityData> extends IRecord, ISerilazable<T>, ITransferable {
}

abstract class BaseRemoteRecord<T extends EntityData> implements ITransferable, ISerilazable<T> {
  protected origin: IRemoteRecord<T>
  protected uri: string
  public constructor(origin: IRemoteRecord<T>, uri: string) {
    this.origin = origin
    this.uri = uri
  }

  public entity(): T {
    return this.origin.entity()
  }

  public abstract transfer(): Promise<void>
}

export class RemoteRecord<T extends EntityData> extends BaseRemoteRecord<T> {
  public async transfer(): Promise<void> {
    const request = new AuthorizationRequest(new PutRequest(new Request<void>(this.uri), this.origin.entity()))
    await request.response()
  }
}

export class InsertableRemoteRecord<T extends EntityData> extends BaseRemoteRecord<T> {
  public async transfer(): Promise<void> {
    const request = new AuthorizationRequest(new PostRequest(new Request<T>(this.uri), this.origin.entity()))
    const response = await request.response()
    _set(this.origin, 'id', response.data.id)
  }
}

export class RemoveRemoteRecord<T extends EntityData> extends BaseRemoteRecord<T> {
  public async transfer(): Promise<void> {
    const request = new AuthorizationRequest(new DeleteRequest(new Request<void>(`${this.uri}/${this.origin.entity().id}`), {}))
    await request.response()
  }
}
