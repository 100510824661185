
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import CardContent from '@/global/components/CardContent.vue'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { WorkflowObjectiveProcess } from '@/domain/ObjectiveProcess'
import { handleAxiosError } from '@/services/utils'
import { IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import Comments from '@/infrastucture/components/Comments.vue'
import ObjectiveCardContent from '@/modules/components/ObjectiveCardContent.vue'
import { ObjectiveData } from '../types'
import { Document } from '@/domain/Document'
import _ from 'lodash'
import { DefaultWorkflowProcess, WorkflowProcess } from '@/domain/WorkflowProcess'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { plainToInstance } from 'class-transformer'
import { WorkflowSlot } from '@/workflow/WorkflowSlot'
import { BillProcessTitlesFactory } from '@/workflow/BillProcessTitlesFactory'
import Stepper from '@/infrastucture/components/Stepper.vue'

@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    CardContent,
    FieldSet,
    Comments,
    ObjectiveCardContent,
    Stepper,
  },
})
export default class WorkflowObjectiveCard extends Vue {
  // Сюда встраиваем слот с рабочим процессом и ловим событие и обновляем эту задачу
  @Prop({ type: Object }) public process!: WorkflowObjectiveProcess
  private loading = false
  protected buttonPanel: ButtonPanelSource
  protected workflow: IButtonSlot | null
  private worflowProcess: WorkflowProcess<Document> | null
	constructor() {
    super()
    this.buttonPanel = ButtonPanelSource.documentObjectiveCreate()
    this.workflow = null
    this.worflowProcess = null
	}

  public get document(): Document {
    return _.get(this.process, 'entity.document')
  }

  public get itemData(): ObjectiveData {
    return this.process.toData()
  }

  public get itemId(): number {
    return this.process.id
  }

  @Watch('itemId', { immediate: true })
  public async onChange(): Promise<void> {
    const documentId = this.document.id
    if (!documentId || this.process.isCompleted) return
    const request = new AuthorizationRequest(new Request(`api/workflow/${documentId}`))
    const response = await request.response()
    const process = plainToInstance(DefaultWorkflowProcess, response.data)
    this.worflowProcess = process
    this.workflow = new WorkflowSlot(this.worflowProcess, new BillProcessTitlesFactory())
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }
}
