import { Vue } from 'vue-property-decorator'
import { ITableSource } from '../table/models/TableCollection'
import { Entity } from './Entity'
import { IEntityCollection } from './EntityCollection'

export default abstract class ViewList<T extends Entity, TData = T> extends Vue {
  protected itemIsVisible = false
  protected abstract selectedItem: T
  protected abstract get anyItem(): T
  public abstract items: IEntityCollection<T>
  public constructor() {
    super()
  }

  public abstract dataCollection: ITableSource<TData>

  public openCard(key: number): void {
    if (key === -1) {
      this.selectedItem = this.anyItem
      this.itemIsVisible = true
      return
    }
    const item = this.items.byId(key)
    if (!item) return
    this.selectedItem = item
    this.itemIsVisible = true
  }

  public closeCard(): void {
    this.itemIsVisible = false
    this.selectedItem = this.anyItem
  }
}