
import { Component, Prop, Vue } from 'vue-property-decorator'
import FloatLabel from '@/global/components/FloatLabel.vue'
@Component({
  components: {
    FloatLabel,
  },
})
export default class FLInputNumber extends Vue {
  @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: Number, default: 0 }) public value!: number
  @Prop({ type: Number, default: 1 }) public step!: number
  @Prop({ type: Number, default: 0 }) public defaultValue!: number
  @Prop({ type: String, default: '.' }) public decimalSeparator!: string
  @Prop({ type: Number }) public min!: number
  @Prop({ type: Number }) public max!: number
  protected hasFocus = false

  public get hasValue(): boolean {
    return !!this.value && this.value !== this.defaultValue || typeof this.value === 'number'
  }

  public onChange(value: number): void {
    this.$emit('change', value)
    this.$emit('input', value)
  }
  public onPressEnter(event: KeyboardEvent): void {
    this.$emit('press-enter', event)
  }
  public onFocus(event: FocusEvent): void {
    this.hasFocus = true
    this.$emit('focus', event)
  }
  public onBlur(event: FocusEvent): void {
    this.hasFocus = false
    this.$emit('blur', event)
  }
}
