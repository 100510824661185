import { TimeInterval } from '@/infrastucture/TimeInterval'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { ObjectiveData, ObjectiveType } from '@/modules/types'
import { Type } from 'class-transformer'
import _ from 'lodash'
import moment from 'moment'
import { Member } from './Member'
import { Stage } from './Stage'

export class Objective extends ValueEntity {
	private description: string
	@Type(() => TimeInterval)
	private limitation: TimeInterval
	@Type(() => Member)
	private members: Member[]
	private percentComplete: number
	@Type(() => ValueEntity)
	private priority: ValueEntity
	@Type(() => ValueEntity)
	private status: ValueEntity
	constructor()
	constructor(other: Objective)
	constructor(other: ValueEntity, description: string, limitation: TimeInterval, members: Member[], percentComplete: number, priority: ValueEntity, status: ValueEntity)
	constructor(other?: ValueEntity, description?: string, limitation?: TimeInterval, members?: Member[], percentComplete?: number, priority?: ValueEntity, status?: ValueEntity) {
		const origin = other as Objective | undefined
		super(origin ?? new ValueEntity())
		this.description = origin?.description ?? description ?? ''
		this.limitation = origin?.limitation ?? limitation ?? new TimeInterval()
		this.members = origin?.members ?? members ?? []
		this.percentComplete = origin?.percentComplete ?? percentComplete ?? 0
		this.priority = origin?.priority ?? priority ?? new ValueEntity()
		this.status = origin?.status ?? status ?? new ValueEntity()
	}

	public isRejected(): boolean {
    return this.status.toString() === 'Отклонён'
  }

	public member(type: string): Member | undefined
	public member(stage: Stage): Member | undefined
	public member(memberType: ValueEntity): Member | undefined
	public member(memberType: ValueEntity | Stage | string): Member | undefined {
		// eslint-disable-next-line
		return _.find(this.members, (member) => member.equals(memberType as any)) // должно работать
	}

	public has(member: Member): boolean
	public has(stage: Stage): boolean
	public has(memberType: ValueEntity): boolean
	public has(memberType: ValueEntity | Member | Stage): boolean {
		return (memberType instanceof Stage && _.some(this.members, member => member.equals(memberType))) ||
			memberType instanceof ValueEntity && _.some(this.members, member => member.equals(memberType)) ||
			memberType instanceof Member && _.some(this.members, member => member.equalsById(memberType))
	}

	public equalsByStatus(statusTitle: string): boolean {
		return this.status.toString() == statusTitle
	}

	public changeStatus(status: ValueEntity): void {
		this.status = status
	}

	public toData(): ObjectiveData {
		return {
			id: this.id,
			itemType: ObjectiveType.default,
			title: this.title,
			itemTitle: this.title,
			description: this.description,
			start: moment(this.limitation.start),
			finish: moment(this.limitation.finish),
			initiator: this.member('Инициатор')?.toData() || new ValueEntity(),
			assignedTo: this.member('Исполнитель')?.toData() || new ValueEntity(),
			reciever: this.member('Приёмщик')?.toData() || new ValueEntity(),
			percentComplete: this.percentComplete,
			priority: this.priority,
			status: this.status,
		}
	}
}