import { Collection } from '@/global/models/Collection'
import { Field } from '@/global/models/Field'
import { IFilteredTableSource } from '@/global/table/models/TableCollection'
import { ITableColumn } from '@/global/table/viewModels/Column'
import _, { Dictionary } from 'lodash'
import store from '@/store'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Users } from '@/infrastucture/users/Users'
import { defaultFilter } from '@/services/utils'
import { Reporter } from '@/partners/reporters/Reporter'
import { PartnerCollection } from '@/partners/models/PartnerCollection'

// eslint-disable-next-line
export abstract class AbstractTableSource<T extends Dictionary<any>> extends Collection<T> implements IFilteredTableSource<T> {
	protected _headerColumns: Field<unknown>[]
	public constructor(array: T[]) {
		super(array)
    this._headerColumns = []
	}

  protected get usersData(): ValueEntity[] {
    return (store.getters['manuals/users'] as Users).select(item => item.toData()).toArray()
  }

  public get contractCategories(): ValueEntity[] {
    return store.getters['manuals/contractCategories']
  }

  public get contractTypes(): ValueEntity[] {
    return store.getters['manuals/contractTypes']
  }

  public get accountTypes(): ValueEntity[] {
    return store.getters['manuals/accountTypes']
  }

  public get paymentTypes(): ValueEntity[] {
    return store.getters['manuals/paymentTypes']
  }

  public get contractSubtypes(): ValueEntity[] {
    return store.getters['manuals/contractSubtypes']
  }

  public get deliveryTypes(): ValueEntity[] {
    return store.getters['manuals/deliveryTypes']
  }

  public get documentTypes(): ValueEntity[] {
    return store.getters['manuals/documentTypes']
  }

  public get facilities(): ValueEntity[] {
    return store.getters['manuals/facilities']
  }

  public get statusList(): ValueEntity[] {
    return store.getters['manuals/statusList']
  }

	public get reportersData(): ValueEntity[] {
		return (store.getters['manuals/reporters'] as Collection<Reporter>).select(item => item.toData()).toArray()
	}

  public get partnersData(): ValueEntity[] {
		return (store.getters['manuals/partners'] as PartnerCollection).select(item => item.toData()).toArray()
	}

	public headerColumns(): Field<unknown>[] {
		return this._headerColumns
	}

  public abstract columns(): ITableColumn<T>[]

	public source(): T[] {
		const fields = this.headerColumns()
		const filtered = this.where((item) => _.every(fields, (field) => {
      const value = item[field.key]
      return defaultFilter(value, field)
    }))
		return filtered.toArray()
	}
}