import { Validator } from 'vee-validate'
import { AbstractValidatableForm } from './AbstractForm'
import { IFieldSet } from './IFieldSet'
import { EntityData, InsertableRemoteRecord, IRemoteRecord, RemoveRemoteRecord } from './RemoteRecord'

export abstract class RecordForm<TEntity extends IRemoteRecord<TData> & IFieldSet, TData extends EntityData> extends AbstractValidatableForm<TEntity, number> {
  protected record: TEntity
  protected uri: string
  public constructor(record: TEntity, validator: Validator, uri: string) {
    super(record.asFieldSet(), validator)
    this.record = record
    this.uri = uri
  }

  public get isAny(): boolean {
    return this.key <= 0
  }

  public async add(): Promise<TEntity> {
    const record = this.asEntity()
    const insertable = new InsertableRemoteRecord(record, this.uri)
    await insertable.transfer()
    return record
  }

  public async update(): Promise<TEntity> {
    const record = this.asEntity()
    await record.transfer()
    return record
  }

  public async remove(): Promise<TEntity> {
    const record = this.asEntity()
    const removable = new RemoveRemoteRecord(record, this.uri)
    await removable.transfer()
    return record
  }
}
