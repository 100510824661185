
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import CardContent from '@/global/components/CardContent.vue'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { Document } from '@/domain/Document'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { DocumentObjectiveProcess } from '@/domain/ObjectiveProcess'
import { first, handleAxiosError, objectiveMemberFromEntity } from '@/services/utils'
import { ObjectiveFields } from '../types'
import { Field, IField } from '@/global/models/Field'
import moment from 'moment'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Users } from '@/infrastucture/users/Users'
import { DocumentObjective } from '@/domain/DocumentObjective'
import { Objective } from '@/domain/Objective'
import { Entity } from '@/global/models/Entity'
import { TimeInterval } from '@/infrastucture/TimeInterval'
import { Member } from '@/domain/Member'
import _ from 'lodash'
import { PostRequest } from '@/api/requests/PostRequest'
import { User } from '@/infrastucture/users/User'

@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    CardContent,
    FieldSet,
  },
})
export default class ObjectiveCreationCard extends Vue {
  @Prop({ type: Object }) public document!: Document
  private loading = false
  private invalid = true
  private hasReciver = new Field(false, 'hasReciver', { label: 'Поставить на приёмку'  }, 'FLCheckbox')
  private reciever = new Field(new ValueEntity(), 'reciever', { label: 'Приёмщик', valueEntities: this.usersData, labelInValue: true, disabled: true  }, 'FLSelect')
	constructor() {
    super()
	}

  public get priorities(): ValueEntity[] {
    const list = this.$store.getters['manuals/priorities']
    return list.length > 0 ? list : [new ValueEntity(new Entity(1), 'Низкий')]
  }

  public get statusList(): ValueEntity[] {
    return this.$store.getters['manuals/statusList']
  }

  public get users(): Users {
    return this.$store.getters['manuals/users']
  }

  public get usersData(): ValueEntity[] {
    return this.users.select(item => item.toData()).toArray()
  }

  public get currentUser(): User {
    return this.users.currentUser
  }

  public get itemFields(): ObjectiveFields {
    return {
      title: new Field('', 'title', { label: 'Наименование',  }, 'FLInput', ['required']),
      description: new Field('', 'description', { label: 'Описание',  }, 'FLTextArea', ['required']),
      start: new Field(moment(), 'start', { label: 'Начало',  }, 'FLDatePicker', ['required']),
      finish: new Field(moment(), 'finish', { label: 'Окончание',  }, 'FLDatePicker', ['required']),
      priority: new Field(new ValueEntity(), 'priority', { label: 'Приоритет', valueEntities: this.priorities, labelInValue: true  }, 'FLSelect', ['required']),
      initiator: new Field(this.currentUser.toData(), 'initiator', { label: 'Инициатор', valueEntities: this.usersData, disabled: true, labelInValue: true  }, 'FLSelect'),
      assignedTo: new Field(new ValueEntity(), 'assignedTo', { label: 'Ответственный', valueEntities: this.usersData, labelInValue: true  }, 'FLSelect', ['required']),
      hasReciver: this.hasReciver,
      reciever: this.reciever,
    }
  }

  public get itemFieldsList(): IField<unknown>[] {
    return Object.values(this.itemFields)
  }

  public get hasRecieverValue(): boolean {
    return this.hasReciver.content()
  }

  public get itemId(): number {
    return this.document.id
  }

  @Watch('hasRecieverValue', { immediate: true })
  public onRecieverChange(value: boolean | undefined): void {
    this.reciever.props.disabled = !value
  }

  /* @Watch('itemId', { immediate: true })
  public async onChange(): Promise<void> {
  } */

  public membersList(): Member[] {
    const initiator = objectiveMemberFromEntity(this.itemFields.initiator.content(), 'Инициатор')
    const assignedTo = objectiveMemberFromEntity(this.itemFields.assignedTo.content(), 'Исполнитель')
    const reciever = objectiveMemberFromEntity(this.itemFields.reciever.content(), 'Приёмщик')
    const members = [initiator, assignedTo, reciever]
    return _.filter(members, item => !item.isAny)
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
      const status = first(this.statusList, item => item.toString() === 'Проект')
      const item = new DocumentObjective(new Objective(new ValueEntity(new Entity(), this.itemFields.title.content()), this.itemFields.description.content(),
        new TimeInterval(this.itemFields.start.content()?.toDate() || moment().toDate(), this.itemFields.finish.content()?.toDate() || moment().toDate()),
        this.membersList(), 0, this.itemFields.priority.content(), status),
        this.document)
      const request = new AuthorizationRequest(new PostRequest(new Request('/api/documentObjectives'), instanceToPlain(item)))
      const response = await request.response()
      const process = plainToInstance(DocumentObjectiveProcess, response.data)
      this.$emit('added', process)
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }
}
