
import { ITableSource } from '@/global/table/models/TableCollection'
import { ITableColumn } from '@/global/table/viewModels/Column'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class SettingsTable<T> extends Vue {
  @Prop({ type: Object, required: true }) public collection!: ITableSource<T>
  @Prop({ type: Boolean, default: false }) public loading!: boolean
  @Prop({ type: String, default: 'key' }) public rowKey!: string

  protected columns: ITableColumn<T>[]

  public constructor() {
    super()
    this.columns = this.collection.columns()
  }

  // eslint-disable-next-line
  public row(record: T): any {
    return {
      on: {
        click: () => {
          this.$emit('rowClick', record)
        },
      },
    }
  }
}
