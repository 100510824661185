import { IEntity } from '@/infrastucture/IEntity'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { IProcessTemplate } from './IProcessTemplate'
import { IWorkflowProcess } from './IWorkflowProcess'
import { Member } from './Member'
import { WorkflowStage } from './WorkflowStage'
import { Document } from './Document'
import { Entity } from '@/global/models/Entity'
import _ from 'lodash'
import { Exclude, instanceToPlain, plainToInstance, Type } from 'class-transformer'
import { WorkflowTemplate } from './WorkflowTemplate'
import { ProcessTemplate } from './ProcessTemplate'
import { StageView } from '@/infrastucture/types'
import { WorkflowObjectiveProcess } from './ObjectiveProcess'
import { first } from '@/services/utils'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import store from '@/store'
import { ValueEntityByTitle } from '@/store/types'
import { PutRequest } from '@/api/requests/PutRequest'
import { Bill } from './Bill'
import { BillData } from '@/modules/BillData'
import { StageTypeValue, Status } from '@/infrastucture/LookupTypes'
import { IncomingDocument } from './IncomingDocument'
import { IncomingDocumentData } from '@/modules/IncomingDocumentData'
import { OutgoingDocument } from './OutgoingDocument'
import { OutgoingDocumentData } from '@/modules/OutgoingDocumentData'

export class WorkflowProcess<T extends Document> implements IWorkflowProcess, IEntity {
	@Exclude()
	// eslint-disable-next-line
  protected type!: Function
	// eslint-disable-next-line
	@Type((options: any) => (options?.newObject as WorkflowProcess<T>).type)
	protected document: T
	@Type(() => WorkflowTemplate)
	private processTemplate: IProcessTemplate
	@Type(() => WorkflowStage)
	private stages: WorkflowStage[]
	@Type(() => ValueEntity)
	private objectiveMemberTypes: ValueEntity[]
	@Type(() => ValueEntity)
	private objectiveStatuses: ValueEntity[]
	@Type(() => ValueEntity)
	private priorities: ValueEntity[]
	@Type(() => ProcessTemplate)
	private objectiveProcess: IProcessTemplate
	constructor(document: T)
	constructor(other: WorkflowProcess<T>)
	constructor(other: WorkflowProcess<T>, document: T)
	constructor(document: T, processTemplate: IProcessTemplate, stages: WorkflowStage[], objectiveMemberTypes: ValueEntity[],
		objectiveStatuses: ValueEntity[], priorities: ValueEntity[], objectiveProcess: IProcessTemplate)
	constructor(document: T | WorkflowProcess<T>, processTemplate?: IProcessTemplate | T, stages?: WorkflowStage[], objectiveMemberTypes?: ValueEntity[],
		objectiveStatuses?: ValueEntity[], priorities?: ValueEntity[], objectiveProcess?: IProcessTemplate) {
		const other = document as WorkflowProcess<T>
		this.document = !(processTemplate instanceof WorkflowTemplate && !!processTemplate) ? processTemplate as T : other.document ?? document
		this.processTemplate = other.processTemplate ?? processTemplate ?? new WorkflowTemplate()
		this.stages = other.stages ?? stages ?? []
		this.objectiveMemberTypes = other.objectiveMemberTypes ?? objectiveMemberTypes ?? []
		this.objectiveStatuses = other.objectiveStatuses ?? objectiveStatuses ?? []
		this.priorities = other.priorities ?? priorities ?? []
		this.objectiveProcess = other.objectiveProcess ?? objectiveProcess ?? new ProcessTemplate()
	}

	public get statusValue(): string {
		if (this.isAny) return ''
		const canBeNext = this.processTemplate.canBeNext(this.current.toString())
		return !canBeNext ? Status.realise : _.get(this.document, 'status')?.toString() || ''
	}

	public get stateValue(): string {
		if (this.isAny) return ''
		const canBeNext = this.processTemplate.canBeNext(this.current.toString())
    return !canBeNext ? 'Завершено' : this.current.toString()
  }

	public isCompleted(member: Member): boolean {
		return this.current.currentProcess(member).isCompleted
	}

	public isStarted(member: Member): boolean {
		return this.current.isStarted(member)
	}

	public get id(): number {
		return this.document.id
	}

	public get isAny(): boolean {
		return this.document.isAny
	}

	public addIdentity(id: number): void {
		this.document.addIdentity(id)
	}

	public equals(other: Entity): boolean {
		return this.document.equals(other)
	}

	public equalsById(other: IEntity): boolean {
		return this.document.equalsById(other)
	}

	public get current(): WorkflowStage {
		const last = _.last(this.stages)
		if (!last) throw new Error('current is undefined')
		return last
	}

	public set current(value: WorkflowStage) {
		const i = Math.max(this.stages.length - 1, 0)
		this.stages.splice(i, 1, value)
	}

	public async completed(member: Member): Promise<WorkflowStage> {
		const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/workflow/complete'), { process: plain, member: instanceToPlain(member) }))
		const response = await request.response()
		const stage = plainToInstance(WorkflowStage, response.data)
		this.current = stage
		return this.current
	}

	public async next(member: Member): Promise<WorkflowStage> {
		const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/workflow/next'), { process: plain, member: instanceToPlain(member) }))
		const response = await request.response()
		const process = plainToInstance(DefaultWorkflowProcess, response.data)
		this.stages = process.stages
		return this.current
	}

	public async revert(member: Member): Promise<WorkflowStage> {
		const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/workflow/revert'), { process: plain, member: instanceToPlain(member) }))
		const response = await request.response()
		const process = plainToInstance(DefaultWorkflowProcess, response.data)
		this.stages = process.stages
		return this.current
	}

	public async started(member: Member): Promise<WorkflowStage> {
		const plain = instanceToPlain(this)
		const request = new AuthorizationRequest(new PostRequest(new Request('api/workflow/start'), { process: plain, member: instanceToPlain(member) }))
		const response = await request.response()
		const stage = plainToInstance(WorkflowStage, response.data)
		this.current = stage
		return this.current
	}
	
	public async reject(): Promise<IWorkflowProcess> {
		const plain = instanceToPlain(this.document)
		const request = new AuthorizationRequest(new PutRequest(new Request('api/workflow/reject'), plain))
		await request.response()
		const valueEntityByTitle = store.getters['manuals/valueEntityByTitle'] as ValueEntityByTitle
		const status = valueEntityByTitle('statusList', 'Отклонён')
		this.document.changeStatus(status)
		return this
	}

	public hasStage(stageType: StageTypeValue): boolean {
		return !this.isAny && this.stages.some(item => item.equals(stageType))
	}

	public hasAccess(member: Member): boolean {
		return this.current.hasMember(member) && !this.document.isRejected()
	}

	public canBeRevert(): boolean {
		const canBeReverted = this.processTemplate.canBeReverted(this.current)
		return canBeReverted
	}

	public canBeNext(): boolean {
		if (!this.processTemplate.canBeNext(this.current.toString())) return false
		const nextStageType = this.processTemplate.next(this.processTemplate.stageType(this.current.toString()))
		const members = this.document.membersByType(nextStageType)
		return members.length > 0
	}

	public currentMember(): Member {
		if (this.isAny) return new Member()
		const membersByCurrentUser = this.document.membersByCurrentUser()
		if (membersByCurrentUser.length <= 0) return new Member()
		const currentMember = _.find(membersByCurrentUser, item => this.hasAccess(item) && item.equals(this.processTemplate.stageType(this.current.toString())))
		if (!currentMember) return new Member()
		return currentMember
	}

	public workflowObjective(stageId: number, objectiveId: number): WorkflowObjectiveProcess {
		const stage = first(this.stages, item => item.id === stageId)
		return stage.workflowObjective(objectiveId, this.document)
	}

	public toProcessData(): StageView[] {
		return _.map(this.stages, item => item.toData())
	}

	public toString(): string {
		return this.document.toString()
	}
}

export class DefaultWorkflowProcess extends WorkflowProcess<Document> {
	protected type
	constructor() {
		super(new Document())
		this.type = Document
	}
}


export class BillWorkflowProcess extends WorkflowProcess<Bill> {
  protected type
	constructor()
	constructor(origin: WorkflowProcess<Bill>)
	constructor(origin: WorkflowProcess<Bill>, document: Bill)
	constructor(origin?: WorkflowProcess<Bill>, document?: Bill) {
		if (!origin) super(new Bill())
		else if (!document) super(origin)
		else super(origin, document)
		this.type = Bill
	}

	public static create(): BillWorkflowProcess {
		const document = Bill.create()
		return new BillWorkflowProcess(new WorkflowProcess(document), document)
	}

	public toData(): BillData {
		return this.document.toData()
	}
}

export class IncomingDocumentWorkflowProcess extends WorkflowProcess<IncomingDocument> {
  protected type
	constructor()
	constructor(origin: WorkflowProcess<IncomingDocument>)
	constructor(origin: WorkflowProcess<IncomingDocument>, document: IncomingDocument)
	constructor(origin?: WorkflowProcess<IncomingDocument>, document?: IncomingDocument) {
		if (!origin) super(new IncomingDocument())
		else if (!document) super(origin)
		else super(origin, document)
		this.type = IncomingDocument
	}

	public static create(): IncomingDocumentWorkflowProcess {
		const document = IncomingDocument.create()
		return new IncomingDocumentWorkflowProcess(new WorkflowProcess(document), document)
	}

	public toData(): IncomingDocumentData {
		return this.document.toData()
	}
}

export class OutgoingDocumentWorkflowProcess extends WorkflowProcess<OutgoingDocument> {
  protected type
	constructor()
	constructor(origin: WorkflowProcess<OutgoingDocument>)
	constructor(origin: WorkflowProcess<OutgoingDocument>, document: OutgoingDocument)
	constructor(origin?: WorkflowProcess<OutgoingDocument>, document?: OutgoingDocument) {
		if (!origin) super(new OutgoingDocument())
		else if (!document) super(origin)
		else super(origin, document)
		this.type = OutgoingDocument
	}

	public static create(): OutgoingDocumentWorkflowProcess {
		const document = OutgoingDocument.create()
		return new OutgoingDocumentWorkflowProcess(new WorkflowProcess(document), document)
	}

	public toData(): OutgoingDocumentData {
		return this.document.toData()
	}
}