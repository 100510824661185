import { ViewButton, AsyncDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { handleAxiosError } from '@/services/utils'

export class LoadingButton extends ViewButton {
  private origin: AsyncDelegateButton
  constructor(other: AsyncDelegateButton) {
    super(other.key, other.icon, other.title, other.style)
    this.origin = other
    this.loading = false
  }

  public loading: boolean

  public get disabled(): boolean {
    return this.origin.disabled
  }

  public get visible(): boolean {
    return this.origin.visible
  }

  public async action(): Promise<void> {
    this.loading = true
    try {
      await this.origin.action()
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }
}