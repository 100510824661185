import axios, { AxiosRequestConfig } from 'axios'

export const resourceConfig: AxiosRequestConfig = {
  // withCredentials: true,
  timeout: 30000,
  baseURL: 'https://vixedo.ru/', // https://localhost:44367/, http://194-58-111-114.cloudvps.regruhosting.ru:8080/
  headers: {
    /* 'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache', */
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

export const localAPIConfig: AxiosRequestConfig = {
  // timeout: 30000,
  baseURL: 'https://localhost:44367/',
  headers: {
    /* 'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache', */
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

export const localAuthConfig: AxiosRequestConfig = {
  // timeout: 30000,
  baseURL: 'https://localhost:44329/',
  headers: {
    /* 'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache', */
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

export const localAdminConfig: AxiosRequestConfig = {
  // timeout: 30000,
  baseURL: 'https://localhost:44313/',
  headers: {
    /* 'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache', */
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

export const testClient = axios.create(localAPIConfig)

export const testAuthClient = axios.create(localAuthConfig)

export const testAdminClient = axios.create(localAdminConfig)
