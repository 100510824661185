
import { IField } from '@/global/models/Field'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FLInput from '@/global/components/FLInput.vue'
import FLDatePicker from '@/global/components/FLDatePicker.vue'
import FLSelect from '@/global/components/FLSelect.vue'
import FLTextArea from '@/global/components/FLTextArea.vue'
import FLInputNumber from '@/infrastucture/components/FLInputNumber.vue'
import FLCheckbox from '@/infrastucture/components/FLCheckbox.vue'
import VatAmountDropdown from './VatAmountDropdown.vue'
import FLSelectExtra from '@/infrastucture/components/FLSelectExtra.vue'
import UserSelect from '@/infrastucture/components/UserSelect.vue'
import _ from 'lodash'
@Component({
  components: {
    FLInput,
    FLDatePicker,
    FLSelect,
    FLTextArea,
    FLInputNumber,
    FLCheckbox,
    VatAmountDropdown,
    FLSelectExtra,
    UserSelect,
  },
})
export default class FieldSet extends Vue {
  @Prop({ type: Array, required: true }) protected formFields!: IField<unknown>[]
  @Prop({ type: Boolean, default: false }) public viewOnly!: boolean
  public constructor() {
    super()
    Vue.observable(this.$validator.fields.items)
  }

  public get invalid(): boolean {
    return _.some(this.$validator.fields.items, (field) => field.flags.invalid || false)
  }

  @Watch('invalid', { immediate: true })
  public onInvalidChanged(): void {
    this.$emit('invalid-changed', this.invalid)
  }
}
