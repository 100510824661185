import { Collection } from '@/global/models/Collection'
import { Field } from '@/global/models/Field'
import { IFilteredTableSource } from '@/global/table/models/TableCollection'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { dateComparer, stringComparer } from '@/services/comparers'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import store from '@/store'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { ObjectiveData, objectiveTypeList } from './types'
import { defaultFilter } from '@/services/utils'
import { Users } from '@/infrastucture/users/Users'

export class ObjectiveTableSource extends Collection<ObjectiveData> implements IFilteredTableSource<ObjectiveData> {
	protected _headerColumns: Field<unknown>[]
	private users: ValueEntity[]
  private priorities: ValueEntity[]
	private _topFilters: Field<unknown>[]
	public constructor(array: ObjectiveData[]) {
		super(array)
		this.users = (store.getters['manuals/users'] as Users).select(user => user.toData()).toArray()
    this.priorities = store.getters['manuals/priorities']
		this._headerColumns = [
			new Field('', 'itemTitle', { label: 'Тема', allowClear: true }, 'FLInput'),
			new Field<Moment | null>(null, 'start', { label: 'Начало', allowClear: true }, 'FLDatePicker'),
      new Field<Moment | null>(null, 'finish', { label: 'Окончание', allowClear: true }, 'FLDatePicker'),
      new Field<ValueEntity>(new ValueEntity(), 'priority',
			{ label: 'Приоритет', allowClear: true, valueEntities: this.priorities, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
		]
		const typeField = new Field('', 'itemType', { label: 'Вид задачи', labels: objectiveTypeList, allowClear: true, labelInValue: true }, 'FLSelectExtra')
		const initiatorField = new Field<ValueEntity>(new ValueEntity(), 'initiator',
		{ label: 'Инициатор', allowClear: true, valueEntities: this.users, labelInValue: true }, 'FLSelectExtra')
		const assignedToField = new Field<ValueEntity>(new ValueEntity(), 'assignedTo',
		{ label: 'Исполнитель', allowClear: true, valueEntities: this.users, labelInValue: true }, 'FLSelectExtra')
		this._topFilters = [
			typeField,
			initiatorField,
			assignedToField,
		]
	}
	public headerColumns(): Field<unknown>[] {
		return this._headerColumns
	}

	public topFilters(): Field<unknown>[] {
		return this._topFilters 
	}

  public columns(): ITableColumn<ObjectiveData>[] {
		return [
			new TableColumn<ObjectiveData>('Тема', 'itemTitle', '400px', false, (prev, next) => stringComparer(prev.itemTitle.toString(), next.itemTitle.toString()), { customRender: 'toString' }, { title: 'itemTitle' }),
			new TableColumn<ObjectiveData>('Описание', 'description', '400px', false, (prev, next) => stringComparer(prev.description.toString(), next.description.toString()), { customRender: 'toString' }),
      new TableColumn<ObjectiveData>('Начало', 'start', '250px', false, (prev, next) => dateComparer(moment(prev.start), moment(next.start)), { customRender: 'dateFormat' }, { title: 'start' }),
      new TableColumn<ObjectiveData>('Окончание', 'finish', '250px', false, (prev, next) => dateComparer(moment(prev.finish), moment(next.finish)), { customRender: 'dateFormat' }, { title: 'finish' }),
			new TableColumn<ObjectiveData>('Приоритет', 'priority', '250px', false, (prev, next) => stringComparer(prev.priority.toString(), next.priority.toString()), { customRender: 'toString' }, { title: 'priority' }),
			new TableColumn<ObjectiveData>('Инициатор', 'initiator', '250px', false, (prev, next) => stringComparer(prev.initiator.toString(), next.initiator.toString()), { customRender: 'toString' }),
			new TableColumn<ObjectiveData>('Ответственный', 'assignedTo', '250px', false, (prev, next) => stringComparer(prev.assignedTo.toString(), next.assignedTo.toString()), { customRender: 'toString' }),
		]
	}
	public source(): ObjectiveData[] {
		const fields = this.headerColumns().concat(this.topFilters())
		const filtered = this.where((item) => _.every(fields, (field) => {
      const value = item[field.key]
      return defaultFilter(value, field)
    }))
		return filtered.toArray()
	}
}