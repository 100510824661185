
import { KeyLabel } from '@/global/models/ui'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Component, Prop, Vue } from 'vue-property-decorator'
import FLSelect from '@/global/components/FLSelect.vue'
@Component({
  components: {
    FLSelect,
  },
})
export default class FLSelectExtra extends Vue {
  @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: Boolean, default: false }) public allowClear!: boolean
  @Prop({ type: String, default: '' }) public icon!: string
  // eslint-disable-next-line
  @Prop({ type: [String, Number, Object, Array] }) public value!: any // Refactor
  // eslint-disable-next-line
  @Prop({ type: [String, Number, Object, Array] }) public defaultValue!: any // Refactor
  @Prop({ type: Array, default: () => [] }) public labels!: string[]
  @Prop({ type: Array, default: () => [] }) public keyLabels!: KeyLabel[]
  @Prop({ type: Array, default: () => [] }) public valueEntities!: ValueEntity[]
  @Prop({ type: String, default: '' }) public dropdownClassName!: string
  @Prop({ type: [Boolean, Function], default: true }) public filterOption!: boolean
  @Prop({ type: Boolean, default: false }) public loading!: boolean
  @Prop({ type: Boolean, default: false }) public dropdownMatchSelectWidth!: boolean
  @Prop({ type: Boolean, default: false }) public labelInValue!: boolean
  @Prop({ type: Boolean, default: false }) public showSearch!: boolean
  public constructor() {
    super()
  }

}
