
import { IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IViewButton } from '../viewModels/ViewButton'
@Component
export default class ButtonSection extends Vue {
  @Prop({ type: Object, required: true }) protected viewModel!: IButtonSlot
  public constructor() {
    super()
    this.viewModel.on((event, ...args) => this.$emit(event, ...args))
  }
  public get buttons(): Array<IViewButton> {
    return this.viewModel.buttons
  }
}
