import { AuthorizationAccess } from '@/oauth/models/AuthorizationAccess'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { IOAuthState, RootState } from './types'

const accessJson = () => localStorage.getItem('AuthorizationAccessEntity')

const state: IOAuthState = {
  access: new AuthorizationAccess()
}
const getters: GetterTree<IOAuthState, RootState> = {
  access(state: IOAuthState) {
    if (state.access.isAny() && accessJson()) {
      const accessEntity = JSON.parse(accessJson() || '{}')
      return AuthorizationAccess.createFromData(accessEntity)
    }
    return state.access
  }
}
const mutations: MutationTree<IOAuthState> = {
  setAccess(state, access: AuthorizationAccess) {
    state.access = access
  },
}
const actions: ActionTree<IOAuthState, RootState> = {
  setAccess({ commit }, access: AuthorizationAccess) {
    localStorage.setItem('AuthorizationAccessEntity', JSON.stringify(access.entity()))
    commit('setAccess', access)
  },
}

export const oauthOptions: Module<IOAuthState, RootState> = {
  namespaced: true,
  state,
  getters: getters,
  mutations,
  actions,
}
