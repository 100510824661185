import { Collection } from '@/global/models/Collection'
import { ITableSource } from '@/global/table/models/TableCollection'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { PartnerAddressForm } from './PartnerAddressForm'

export class AddressForms extends Collection<PartnerAddressForm> implements ITableSource<PartnerAddressForm> {
  public columns(): ITableColumn<PartnerAddressForm>[] {
    return [
      new TableColumn('addButton', 'empty', 50, false, false, undefined, { title: 'addButton' }),
      new TableColumn('Тип', 'type', 250, false, false, { customRender: 'type' }),
      new TableColumn('Индекс', 'postCode', 125, false, false, { customRender: 'postCode' }),
      new TableColumn('Область/Край', 'area', 300, false, false, { customRender: 'area' }),
      new TableColumn('Населенный пункт', 'settlement', 200, false, false, { customRender: 'settlement' }),
      new TableColumn('Улица', 'street', 250, false, false, { customRender: 'street' }),
      new TableColumn('Дом', 'house', 100, false, false, { customRender: 'house' }),
      new TableColumn('', 'editOrSave', 100, false, false, { customRender: 'editOrSave' }),
      new TableColumn('', 'removeOrCancel', 100, false, false, { customRender: 'removeOrCancel' }),
    ]
  }

  public source(): PartnerAddressForm[] {
    return this.toArray()
  }
}