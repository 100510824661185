import { every as _every } from 'lodash'
import { Collection } from '@/global/models/Collection'
import { Field } from '@/global/models/Field'
import { Fieldset } from '@/global/models/Fieldset'
import { ITableSource } from '@/global/table/models/TableCollection'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { PartnerRow } from './PartnerRow'
import { stringComparer } from '@/services/comparers'

export class PartnerRows extends Collection<PartnerRow> implements ITableSource<PartnerRow> {
	protected _headerSet: Fieldset
	public constructor(array: PartnerRow[]) {
		super(array)
		this._headerSet = new Fieldset(1, [
			new Field('', 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput'),
			new Field('', 'inn', { label: 'ИНН', allowClear: true }, 'FLInput'),
		], [])
	}
	public headerSet(): Fieldset<number> {
		return this._headerSet
	}
  public columns(): ITableColumn<PartnerRow>[] {
		return [
			new TableColumn<PartnerRow>('Наименование', 'itemTitle', '250px', false, (prev, next) => stringComparer(prev.itemTitle.toString(), next.itemTitle.toString()), { customRender: 'toString' }, { title: 'itemTitle' }),
      new TableColumn<PartnerRow>('ИНН', 'inn', '200px', false, true, { customRender: 'toString' }, { title: 'inn' }),
      new TableColumn<PartnerRow>('Email', 'email', '200px', false, true, { customRender: 'toString' }),
      new TableColumn<PartnerRow>('Телефон', 'workPhone', '200px', false, true, { customRender: 'toString' }),
		]
	}
	public source(): PartnerRow[] { // TODO class column, передавать ему row, который он сам и отфильтрует.
		const fields = this.headerSet().allFields()
		const filtered = this.where((item) => _every(fields, (field) => item.has(field)))
		return filtered.toArray()
	}
}
