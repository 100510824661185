import { IWorkflowProcess } from '@/domain/IWorkflowProcess'
import { Member } from '@/domain/Member'
import { IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { IViewButton, Emitter, AsyncDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { LoadingButton } from '@/infrastucture/Buttons'
import _ from 'lodash'
import Vue from 'vue'
import { IProcessTitlesFactory } from './IProcessTitlesFactory'

export class WorkflowSlot implements IButtonSlot {
  private process: IWorkflowProcess
  private titlesFactory: IProcessTitlesFactory
  private currentMember: Member
  private emitter: Emitter | null

  constructor($process: IWorkflowProcess, $titlesFactory: IProcessTitlesFactory) {
    this.process = $process
    this.titlesFactory = $titlesFactory
    this.currentMember = this.process.currentMember()
    this.emitter = null
  }

  protected canBeRevert(): boolean {
    return this.process.canBeRevert() && !this.process.isCompleted(this.currentMember)
  }

  public get buttons(): IViewButton[] {
    // В hasAccess проверить также и статус отклонения
    if (this.process.isAny || !this.process.hasAccess(this.currentMember)) return []
    const titles = this.titlesFactory.titles(this.process.current.toString())
    const btns = [
      new LoadingButton(new AsyncDelegateButton('start', 'login', titles.start, 'ant-office-btn-link', async () => { await this.process.started(this.currentMember) },
        () => this.process.isStarted(this.currentMember), () => true)),
      new LoadingButton(new AsyncDelegateButton('next', 'logout', titles.next, 'ant-office-btn-link', async () => { await this.process.next(this.currentMember) },
        () => !this.process.canBeNext(), () => this.process.canBeNext())),
      new LoadingButton(new AsyncDelegateButton('complete', 'check-circle', titles.complete, 'ant-office-btn-link', async () => { await this.process.completed(this.currentMember) },
        () => this.process.isCompleted(this.currentMember), () => true)),
      new LoadingButton(new AsyncDelegateButton('revert', 'rollback', titles.revert, 'ant-office-btn-link', async () => { await this.process.revert(this.currentMember) },
        () => !this.canBeRevert(), () => true)),
      new LoadingButton(new AsyncDelegateButton('reject', 'close-circle', titles.reject, 'ant-office-btn-link', async () => { await this.process.reject() },
        () => !this.canBeRevert(), () => true)),
    ]
    Vue.observable(btns)
    return _.filter(btns, btn => btn.visible)
  }

  public on(emitter: Emitter): void {
    this.emitter = emitter
  }
}