import { Entity } from '@/global/models/Entity'
import { StageView } from '@/infrastucture/types'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { first } from '@/services/utils'
import { User } from '@/infrastucture/users/User'
import { Type } from 'class-transformer'
import _ from 'lodash'
import moment from 'moment'
import { Document } from './Document'
import { IWorkflowStage } from './IWorkflowStage'
import { Member } from './Member'
import { Objective } from './Objective'
import { DefaultObjectiveProcess, ObjectiveProcess, WorkflowObjectiveProcess } from './ObjectiveProcess'

export class WorkflowStage extends Entity implements IWorkflowStage {
	@Type(() => ValueEntity)
	private stageType: ValueEntity
	@Type(() => Date)
	private start: Date
	@Type(() => Date)
	private finish: Date | undefined
	@Type(() => DefaultObjectiveProcess)
	private objectiveProcesses: DefaultObjectiveProcess[]
	constructor()
	constructor(other: Entity, stageType: ValueEntity, start: Date, finish: Date | undefined, objectiveProcesses: DefaultObjectiveProcess[])
	constructor(other?: Entity, stageType?: ValueEntity, start?: Date, finish?: Date | undefined, objectiveProcesses?: DefaultObjectiveProcess[]) {
		super(other?.id ?? 0)
		this.stageType = stageType ?? new ValueEntity()
		this.start = start ?? moment(0).toDate()
		this.finish = finish
		this.objectiveProcesses = objectiveProcesses ?? []
	}

	public get isCompleted(): boolean {
		return _.every(this.objectiveProcesses, process => process.isCompleted)
	}

	public get isSingle(): boolean {
		return this.objectiveProcesses.length === 1
	}

	public isStarted(member: Member): boolean {
		return this.currentProcess(member).isStarted
	}

	// eslint-disable-next-line
	public completed(member: Member): WorkflowStage {
		throw new Error('Not implemented')
	}

	// eslint-disable-next-line
	public started(member: Member): WorkflowStage {
		throw new Error('Not implemented')
	}
	
	public equals(other: string): boolean
	public equals(other: WorkflowStage): boolean
	public equals(other: ValueEntity): boolean
	public equals(other: ValueEntity | WorkflowStage | string): boolean {
		if (other instanceof WorkflowStage) return this.equalsById(other)
		if (other instanceof ValueEntity) return this.stageType.equals(other)
		return this.stageType.toString() === other
	}

	public currentProcess(member: Member): ObjectiveProcess<Objective> {
		const current = _.find(this.objectiveProcesses, process => process.has(member))
		if (!current) throw new Error('current is undefined')
		return current
	}

	public hasMember(member: User): boolean
	public hasMember(member: Member): boolean
	public hasMember(member: Member | User): boolean {
		const hasUser = this.objectiveProcesses.some(item => item.isAssignedTo(member))
		return hasUser
	}

	public workflowObjective(objectiveId: number, document: Document): WorkflowObjectiveProcess {
		const process = first(this.objectiveProcesses, item => item.id === objectiveId)
		return process.asWorkflow(this, document)
	}

	public toData(): StageView {
		const infos = _.map(this.objectiveProcesses, item => item.toProcessData())
		const status = this.isCompleted
			? 'finish'
			: _.some(this.objectiveProcesses, item => item.isStarted)
				? 'process'
				: 'wait'
		return { id: this.id, title: this.stageType.toString(), info: infos, status  }
	}

	public toString(): string {
		return this.stageType.toString()
	}
}