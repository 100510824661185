import { Field } from '@/global/models/Field'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { dateComparer, stringComparer } from '@/services/comparers'
import { Moment } from 'moment'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { AbstractTableSource } from './AbstractTableSource'
import { OutgoingDocumentData } from './OutgoingDocumentData'

export class OutgoingDocumentTableSource extends AbstractTableSource<OutgoingDocumentData> {
	public constructor(array: OutgoingDocumentData[]) {
		super(array)
		this._headerColumns = [
			new Field('', 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput'),
			new Field('', 'registrationNumber', { label: 'Рег. номер', allowClear: true }, 'FLInput'),
			new Field<Moment | null>(null, 'registrationDate', { label: 'Дата регистрации', allowClear: true }, 'FLDatePicker'),
			new Field<ValueEntity>(new ValueEntity(), 'deliveryType',
			{ label: 'Способ доставки', allowClear: true, valueEntities: this.deliveryTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'documentType',
			{ label: 'Вид документа', allowClear: true, valueEntities: this.documentTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'reporter',
			{ label: 'Контакт контрагента', allowClear: true, valueEntities: this.reportersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'facility',
			{ label: 'Объект', allowClear: true, valueEntities: this.facilities, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'initiator',
			{ label: 'Инициатор', allowClear: true, valueEntities: this.usersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'executor',
			{ label: 'Отправитель', allowClear: true, valueEntities: this.usersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
		]
	}

  public columns(): ITableColumn<OutgoingDocumentData>[] {
		return [
			new TableColumn<OutgoingDocumentData>('Наименование', 'itemTitle', '400px', false, (prev, next) => stringComparer(prev.itemTitle, next.itemTitle), { customRender: 'toString' }, { title: 'itemTitle' }),
			new TableColumn<OutgoingDocumentData>('Рег. номер', 'registrationNumber', '250px', false, (prev, next) => stringComparer(prev.registrationNumber, next.registrationNumber), { customRender: 'toString' }, { title: 'registrationNumber' }),
			new TableColumn<OutgoingDocumentData>('Дата регистрации', 'registrationDate', '250px', false, (prev, next) => dateComparer(prev.registrationDate, next.registrationDate), { customRender: 'dateFormat' }, { title: 'registrationDate' }),
			new TableColumn<OutgoingDocumentData>('Способ доставки', 'deliveryType', '250px', false, (prev, next) => stringComparer(prev.deliveryType.toString(), next.deliveryType.toString()), { customRender: 'toString' }, { title: 'deliveryType' }),
			new TableColumn<OutgoingDocumentData>('Вид документа', 'documentType', '250px', false, (prev, next) => stringComparer(prev.documentType.toString(), next.documentType.toString()), { customRender: 'toString' }, { title: 'documentType' }),
			new TableColumn<OutgoingDocumentData>('Описание', 'description', '250px', false, (prev, next) => stringComparer(prev.description, next.description), { customRender: 'toString' }),
			new TableColumn<OutgoingDocumentData>('Место хранения', 'storage', '200px', false, (prev, next) => stringComparer(prev.storage, next.storage), { customRender: 'toString' }),
			new TableColumn<OutgoingDocumentData>('Контакт контрагента', 'reporter', '250px', false, (prev, next) => stringComparer(prev.reporter.toString(), next.reporter.toString()), { customRender: 'toString' }, { title: 'reporter' }),
			new TableColumn<OutgoingDocumentData>('Объект', 'facility', '250px', false, (prev, next) => stringComparer(prev.facility.toString(), next.facility.toString()), { customRender: 'toString' }, { title: 'facility' }),
			new TableColumn<OutgoingDocumentData>('Инициатор', 'initiator', '250px', false, (prev, next) => stringComparer(prev.initiator.toString(), next.initiator.toString()), { customRender: 'toString' }, { title: 'initiator' }),
      new TableColumn<OutgoingDocumentData>('Отправитель', 'executor', '250px', false, (prev, next) => stringComparer(prev.executor.toString(), next.executor.toString()), { customRender: 'toString' }, { title: 'executor' }),
		]
	}
}