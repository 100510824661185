
import { IField } from '@/global/models/Field'
import { Component, Prop, Vue } from 'vue-property-decorator'
import FLInput from '@/global/components/FLInput.vue'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import { PartnerButtonPanel } from '../viewModels/PartnerButtonPanel'
import CardContent from '@/global/components/CardContent.vue'
import { ContentTab, ItemContentTab, ViewCardContent } from '@/global/viewModels/ViewCardContent'
import { PartnerForm } from '../models/PartnerForm'
import FLDatePicker from '@/global/components/FLDatePicker.vue'
import ReportersTab from './Tabs/ReportersTab.vue'
import AddressesTab from './Tabs/AddressesTab.vue'
import { Moment } from 'moment'
import { RecordPartner } from '../models/RecordPartner'
import { FlexibleButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { PartnerQuickAction } from '../viewModels/PartnerQuickAction'
import { ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { handleAxiosError } from '@/services/utils'
@Component({
  components: {
    FLInput,
    ButtonPanel,
    ButtonSection,
    CardContent,
    FLDatePicker,
    ReportersTab,
    AddressesTab,
  },
})
export default class ItemCard extends Vue {
  @Prop({ type: Object }) public item!: RecordPartner
  @Prop({ type: Boolean, default: false }) public viewOnly!: boolean
  protected buttonPanel = PartnerButtonPanel.create()
  protected qaSection: FlexibleButtonSlot
  protected form: PartnerForm
  protected cardContent: ViewCardContent
  protected loading = false

  public constructor() {
    super()
    Vue.observable(this.$validator.fields.items)
    this.form = new PartnerForm(this.item.asForm(), this.$validator, this.item)
    const qa = new PartnerQuickAction([
      new ViewDelegateButton('edit', 'edit', 'Редактировать', 'ant-office-btn-link', () => { this.$emit('to-edit') }, () => !this.viewOnly, () => this.viewOnly),
      new ViewDelegateButton('save', 'save', 'Сохранить', 'ant-office-btn-link', async () => await this.save(), () => this.viewOnly || this.form.invalid(), () => !this.viewOnly),
    ])
    this.qaSection = qa
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('reporters', 'Контакты', this.item),
      new ItemContentTab('addresses', 'Адреса', this.item),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
  }

  public get commonFields(): IField<string | Moment | null>[] {
    return this.form.commonFields
  }

  public get contactFields(): IField<string>[] {
    return this.form.contactFields
  }

  public async save(): Promise<void> { // TODO refactor
    try {
      this.loading = true
      const partner = await this.form.save()
      if (this.item.isAny) this.$emit('added', partner)
      else this.$emit('updated', partner)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }    
  }
}
