import { Field, IField } from '@/global/models/Field'
import { ISerilazable } from '@/global/models/Serilazable'
import { ActivityInfo } from './PartnerJson'

export class Activity implements ISerilazable<ActivityInfo> {
  protected okved: string
  protected okato: string
  protected oktmo: string
  protected okpo: string
  protected description: string
  public constructor()
  public constructor(other: Activity)
  public constructor(okved: string, okato: string, oktmo: string, okpo: string, description: string)
  public constructor(...args: unknown[]) {
    const other = args[0] as Activity | undefined
    const okved = other?.okved ?? args[0] as string ?? ''
    const okato = other?.okato ?? args[1] as string ?? ''
    const oktmo = other?.oktmo ?? args[2] as string ?? ''
    const okpo = other?.okpo ?? args[3] as string ?? ''
    const description = other?.description ?? args[4] as string ?? ''
    this.okved = okved
    this.okato = okato
    this.oktmo = oktmo
    this.okpo = okpo
    this.description = description
  }

  public static any(): Activity {
    return new Activity()
  }

  public static createFromData(entity: ActivityInfo): Activity {
    return new Activity(entity.okved, entity.okato, entity.oktmo, entity.okpo, entity.description)
  }

  public entity(): ActivityInfo {
    return {
      okved: this.okved,
      okato: this.okato,
      oktmo: this.oktmo,
      okpo: this.okato,
      description: this.description,
    }
  }

  public asFields(): IField<string>[] {
    return [
      new Field(this.okved, 'okved', { label: 'Основной вид деятельности (ОКВЭД)' }, 'FLInput'),
      new Field(this.okato, 'okato', { label: 'ОКАТО' }, 'FLInput'),
      new Field(this.oktmo, 'oktmo', { label: 'ОКТМО' }, 'FLInput'),
      new Field(this.okpo, 'okpo', { label: 'ОКПО' }, 'FLInput'),
    ]
  }
}