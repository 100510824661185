
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class CheckableButton extends Vue {
  @Prop({ type: String, required: true }) public title!: string
  @Prop({ type: Boolean, required: true }) public value!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public loading!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public disabled!: boolean
  @Prop({ type: String, required: false, default: 'default' }) public type!: 'primary' | 'ghost' | 'dashed' | 'danger' | 'link' | 'default'

  public changeChecked(): void {
    const newCheckedValue = !this.value
    this.$emit('input', newCheckedValue)
    this.$emit('checked', newCheckedValue)
  }
}
