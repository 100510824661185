import { Entity } from '@/global/models/Entity'

export class Person extends Entity {
  protected firstName: string
  protected lastName: string
  protected middleName: string
  public constructor()
  public constructor(other: Person)
  public constructor(id: number, firstName: string, lastName: string, middleName: string)
  public constructor(...args: unknown[]) {
    const id = (args[0] as Person)?.id ?? args[0] ?? 0
    const firstName = (args[0] as Person)?.firstName ?? args[1] ?? ''
    const lastName = (args[0] as Person)?.lastName ?? args[2] ?? ''
    const middleName = (args[0] as Person)?.middleName ?? args[3] ?? ''
    super(id)
    this.firstName = firstName
    this.lastName = lastName
    this.middleName = middleName
  }

  public get fullName(): string {
    return `${this.lastName} ${this.firstName} ${this.middleName}`
  }

  public toString(): string {
    return `${this.firstName} ${this.lastName}`
  }
}