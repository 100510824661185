import { ValueEntity } from '@/infrastucture/ValueEntity'
import { FacilityData } from '@/modules/FacilityData'

export class Facility extends ValueEntity {
  private destination: string
  private digitalCode: number
  private literalCode: string
  private note: string
  constructor()
  constructor(other: ValueEntity, destination: string, digitalCode: number, literalCode: string, note: string, isActive: boolean)
  constructor(other?: ValueEntity, destination?: string, digitalCode?: number, literalCode?: string, note?: string, isActive?: boolean) {
    super(other ?? new ValueEntity())
    this.destination = destination ?? ''
    this.digitalCode = digitalCode ?? 0
    this.literalCode = literalCode ?? ''
    this.note = note ?? ''
    this.isActive = isActive ?? false
  }

  public isActive: boolean

  public toData(): FacilityData {
    return {
      id: this.id,
      itemTitle: this.title,
      destination: this.destination,
      digitalCode: this.digitalCode,
      codeLiteral: this.literalCode,
      note: this.note,
      isActive: this.isActive,
    }
  }
}