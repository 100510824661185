
import { Spin } from 'ant-design-vue'
import locale from 'ant-design-vue/lib/locale-provider/ru_RU'
import { Component, Vue } from 'vue-property-decorator'

Spin.setDefaultIndicator({
  indicator: {
    render() {
      return <a-icon type="loading" class="text-2xl" spin />
    },
  },
})
@Component
export default class App extends Vue {
  private locale
  public constructor() {
    super()
    this.locale = locale
  }
}
