import { Employee } from '@/infrastucture/users/Employee'
import { Entity } from '@/global/models/Entity'
import { AbstractEntityVisitor } from '@/global/models/EntityVisitor'
import { ValueEntity } from '@/infrastucture/ValueEntity'
// import { AbstractRenderVisitor, ModelState } from '@/global/models/Visitor'
import { Person } from '@/infrastucture/users/Person'
import { UserData } from './UserData'

export class User extends Employee {
  private userName: string
  public constructor()
  public constructor(other: Employee)
  public constructor(other: Employee, userName: string)
  public constructor(other = new Employee(new Person(-1, '', '', ''), '', '', ''), userName = '') {
    super(other)
    this.userName = userName
  }

  public static createFromData(data: UserData): User {
    return new User(new Employee(new Person(data.id, data.firstName, data.lastName, data.middleName), data.email, data.workPhone, data.jobTitle), '')
  }

  public static get any(): User {
    return defaultUser
  }

  public accept(visitor: AbstractEntityVisitor): void {
    const media: UserData = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      middleName: this.middleName,
      jobTitle: this.jobTitle,
      email: this.email,
      workPhone: this.phone,
    }
    visitor.visitUser(media)
  }

  public toString(): string {
    return `${this.firstName} ${this.lastName}`
  }

  public toData(): ValueEntity {
		return new ValueEntity(new Entity(this.id), this.toString())
	}

  public toMedia(): UserData {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      middleName: this.middleName,
      jobTitle: this.jobTitle,
      email: this.email,
      workPhone: this.phone,
    }
  }
}

const defaultUser = new User()
