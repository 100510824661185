
import { Component, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import { IField, Field } from '@/global/models/Field'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import CardContent from '@/global/components/CardContent.vue'
import { ContentTab, ItemContentTab, ViewCardContent } from '@/global/viewModels/ViewCardContent'
import { ButtonSlot, IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { first, handleAxiosError } from '@/services/utils'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { CommonFields, PartiesFields, MembersFields } from '../IncomingDocumentData'
import { Users } from '@/infrastucture/users/Users'
import { Document } from '@/domain/Document'
import { Registration } from '@/domain/Registration'
import { Member } from '@/domain/Member'
import { User } from '@/infrastucture/users/User'
import { instanceToPlain } from 'class-transformer'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PutRequest } from '@/api/requests/PutRequest'
import { Request } from '@/api/requests/Request'
import { PostRequest } from '@/api/requests/PostRequest'
import Files from '@/files/Files.vue'
import CommentsTab from '@/modules/cards/CommentsTab.vue'
import ObjectivesTab from '@/modules/components/ObjectivesTab.vue'
import ObjectiveCreationCard from '@/modules/cards/ObjectiveCreationCard.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { DocumentObjectiveProcess } from '@/domain/ObjectiveProcess'
import { WorkflowSlot } from '@/workflow/WorkflowSlot'
import { IncomingDocumentProcessTitlesFactory } from '@/workflow/IncomingDocumentProcessTitlesFactory'
import { partnerByReporter, reportersByPartner, userDepartmentField } from '@/services/cardUtils'
import DocumentCard from './DocumentCard'
import { IncomingDocumentWorkflowProcess } from '@/domain/WorkflowProcess'
import { IncomingDocumentData } from '../IncomingDocumentData'
import { MemberTypeValue, StageTypeValue } from '@/infrastucture/LookupTypes'
import { PartnerCollection } from '@/partners/models/PartnerCollection'
import { Partner } from '@/partners/models/Partner'
import { DocumentDescription } from '@/domain/DocumentDescription'
import { Parties } from '@/domain/Parties'
import { IncomingDocument } from '@/domain/IncomingDocument'
import { DeserilazableRequest } from '@/api/requests/DeserilazableRequest'
import Stepper from '@/infrastucture/components/Stepper.vue'
@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    CardContent,
    FieldSet,
    Files,
    CommentsTab,
    ObjectivesTab,
    ObjectiveCreationCard,
    ItemDrawer,
    Stepper,
  },
})
/* В констукторе не стоит работать со своими элементами из this, a вот с служебными типо store можно */
export default class IncomingDocumentCard extends DocumentCard {
  @Prop({ type: Object }) public item!: IncomingDocumentWorkflowProcess
  @Prop({ type: Boolean, default: false }) public viewOnly!: boolean
  protected buttonPanel: ButtonPanelSource
  protected creation: ButtonSlot
  protected action: ButtonSlot
  protected workflow: IButtonSlot // TODO Реактивность при смене этапа
  protected cardContent: ViewCardContent
  protected loading = false
  private itemIsVisible = false
  private commonIsInvalid = true
  private partiesIsInvalid = true
  private membersIsInvalid = true
  private partner
  private reporter
  private partnerReporters

  public constructor() {
    super()
    this.buttonPanel = ButtonPanelSource.create()
    const creation = new ButtonSlot([
      new ViewDelegateButton('edit', 'edit', 'Редактировать', 'ant-office-btn-link', () => { this.$emit('to-edit') }, () => !this.viewOnly, () => this.viewOnly),
      new ViewDelegateButton('save', 'save', 'Сохранить', 'ant-office-btn-link', async () => await this.save(), () => this.viewOnly || this.invalid(), () => !this.viewOnly),
    ])
    this.creation = creation
    this.action = new ButtonSlot([
      new ViewDelegateButton('create-objective', 'solution', 'Создать задачу', 'ant-office-btn-link', () => { this.openCard() }, () => !this.viewOnly, () => this.viewOnly),
    ])
    this.workflow = new WorkflowSlot(this.item, new IncomingDocumentProcessTitlesFactory())
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('file', 'Файлы', this.item),
      new ItemContentTab('objectives', 'Задачи', this.item),
      new ItemContentTab('comments', 'Обсуждения', this.item),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
    const partners = this.$store.getters['manuals/partners'] as PartnerCollection
    const partnersData = partners.select(item => item.toData()).toArray()
    const partner = partnerByReporter(this.itemData.reporter.id) || new Partner()
    this.partnerReporters = reportersByPartner(partner)
    this.partner =  new Field(partner.toData(), 'partner', { label: 'Контрагент', valueEntities: partnersData, labelInValue: true }, 'FLSelect')
    this.reporter = new Field(this.itemData.reporter, 'reporter', { label: 'Контакт контрагента', valueEntities: this.partnerReporters, labelInValue: true }, 'FLSelect', ['required'])
  }

  public get document(): Document {
    return _.get(this.item, 'document')
  }

  public get itemData(): IncomingDocumentData {
    return this.item.toData()
  }

  public invalid(): boolean {
    return this.commonIsInvalid || this.partiesIsInvalid || this.membersIsInvalid
  }

  public get selectedPartnerId(): number {
    return this.partner.content().id
  }

  public get common(): CommonFields {
    return {
      registrationNumber: new Field(this.itemData.registrationNumber, 'registrationNumber', { label: 'Рег. номер', allowClear: true }, 'FLInput'),
      registrationDate: new Field(this.itemData.registrationDate, 'registrationDate', { label: 'Дата регистрации', allowClear: true }, 'FLDatePicker', ['required']),
      outgoingDate: new Field(this.itemData.outgoingDate, 'outgoingDate', { label: 'Исходящая дата', allowClear: true }, 'FLDatePicker', ['required']),
      outgoingNumber: new Field(this.itemData.outgoingNumber, 'outgoingNumber', { label: 'Исходящий номер', allowClear: true }, 'FLInput', ['required']),
      deliveryType: new Field(this.itemData.deliveryType, 'deliveryType', { label: 'Способ доставки', valueEntities: this.deliveryTypes, labelInValue: true }, 'FLSelect', ['required']),
      documentType: new Field(this.itemData.documentType, 'documentType', { label: 'Вид документа', valueEntities: this.documentTypes, labelInValue: true }, 'FLSelect', ['required']),
      storage: new Field(this.itemData.storage, 'storage', { label: 'Место хранения', allowClear: true }, 'FLInput'),
      description: new Field(this.itemData.description, 'description', { label: 'Описание', allowClear: true }, 'FLTextArea'),
      note: new Field(this.itemData.note, 'note', { label: 'Примечание', allowClear: true }, 'FLTextArea'),
      copiesCount: new Field(this.itemData.copiesCount, 'copiesCount', { label: 'Кол-во копий', }, 'FLInputNumber'),
      sheetCount: new Field(this.itemData.sheetCount, 'sheetCount', { label: 'Кол-во страниц', }, 'FLInputNumber'),
      supplementsCount: new Field(this.itemData.supplementsCount, 'supplementsCount', { label: 'Кол-во стр. приложений', }, 'FLInputNumber'),
    }
  }

  public get commonFields(): IField<string | number | Moment | null | ValueEntity>[] {
    const common = Object.values(this.common)
    return common
  }

  public get parties(): PartiesFields {
    return {
      partner: this.partner,
      reporter: this.reporter,
      enterprise: new Field(this.itemData.facility, 'enterprise', { label: 'Объект', valueEntities: this.facilities, labelInValue: true }, 'FLSelect', ['required']),
    }
  }

  public get partiesFields(): IField<string | Moment | null | ValueEntity>[] {
    return Object.values(this.parties)
  }

  public get members(): MembersFields {
    const initiator = new Field(this.itemData.initiator, 'initiator',
      { label: 'Инициатор', valueEntities: this.usersData, labelInValue: true, disabled: true }, 'FLSelect', ['required'])
    const members = {
      initiator: initiator,
      department: userDepartmentField(initiator.content()),
      recievers: new Field(this.itemData.recievers, 'recievers',
        { label: 'Адресовано', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.review) }, 'UserSelect'),
      assignedTo: new Field(this.itemData.assignedTo, 'assignedTo',
        { label: 'Кому назначено', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.review) }, 'UserSelect'),
    }
    return members
  }

  public get membersFields(): IField<string | ValueEntity | ValueEntity[] | boolean>[] {
    return Object.values(this.members)
  }

  @Watch('item', { immediate: false, deep: false })
  public onChange(): void {
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('file', 'Файлы', this.item),
      new ItemContentTab('objectives', 'Задачи', this.item),
      new ItemContentTab('comments', 'Обсуждения', this.item),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
    this.workflow = new WorkflowSlot(this.item, new IncomingDocumentProcessTitlesFactory())
    const partners = this.$store.getters['manuals/partners'] as PartnerCollection
    const partnersData = partners.select(item => item.toData()).toArray()
    const partner = partnerByReporter(this.itemData.reporter.id) || new Partner()
    this.partnerReporters = reportersByPartner(partner)
    this.partner =  new Field(partner.toData(), 'partner', { label: 'Контрагент', valueEntities: partnersData, labelInValue: true }, 'FLSelect')
    this.reporter = new Field(this.itemData.reporter, 'reporter', { label: 'Контакт контрагента', valueEntities: this.partnerReporters, labelInValue: true }, 'FLSelect', ['required'])
  }

  @Watch('selectedPartnerId', { immediate: false, deep: true })
  public onPartnerChange(value: number, oldValue: number): void {
    if (value === oldValue) return
    this.parties.reporter.update(new ValueEntity())
    this.partnerReporters = reportersByPartner(this.partners.byId(value) || null)
    this.reporter.props.valueEntities = this.partnerReporters
  }

  public membersList(): Member[] {
    const users: Users = this.$store.getters['manuals/users']
    const userById = (id: number) => {
      const user = users.byId(id) || new User()
      return user
    }
    const initiator = new Member(userById(this.members.initiator.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.initiator))
    const recievers = _.map(this.members.recievers.content(), item => new Member(userById(item.id), first(this.documentMembers, item => item.toString() === MemberTypeValue.receiver)))
    const assignedTo = _.map(this.members.assignedTo.content(), item => new Member(userById(item.id), first(this.documentMembers, item => item.toString() === MemberTypeValue.executor)))
    const members = [initiator, ...recievers, ...assignedTo]
    return _.filter(members, item => !item.isAny)
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
      const itemTitle = `${this.common.registrationNumber.content()} ${this.parties.partner.content().toString()}`
      const members: Member[] = this.membersList()
      const registration = new Registration(this.itemData.registrationDate.toDate(), this.common.registrationNumber.content())
      const document = this.document.updated(itemTitle, registration, this.common.storage.content(), members)
      const documentDescription = new DocumentDescription(this.common.copiesCount.content(), this.common.sheetCount.content(), this.common.supplementsCount.content(),
        this.common.description.content())
      const facility = _.find(this.facilities, item => item.equalsById(this.parties.enterprise.content()))
      const reporter = this.reporters.byId(this.parties.reporter.content().id)
      if (!reporter || !facility) throw new Error('Не заполнены обязательные поля')
      const parties = new Parties('', facility, reporter)
      const item = new IncomingDocument(document, this.common.deliveryType.content(), this.common.documentType.content(), documentDescription, this.common.note.content(),
        this.common.outgoingDate.content()?.toDate() || moment(0).toDate(), this.common.outgoingNumber.content(), parties)
      if (this.item.isAny) {
        const request = new DeserilazableRequest(new AuthorizationRequest(new PostRequest(
          new Request<IncomingDocumentWorkflowProcess>('/api/IncomingDocuments'), instanceToPlain(item))), IncomingDocumentWorkflowProcess)
        const response = await request.response()
        const process = response.data
        this.$emit('added', process)
      }
      else {
        const request = new AuthorizationRequest(new PutRequest(new Request<void>('/api/IncomingDocuments'), instanceToPlain(item)))
        await request.response()
        this.$emit('updated', item)
      }
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }

  public openCard(): void {
    this.itemIsVisible = true
  }

  public onAddedObjective(item: DocumentObjectiveProcess): void {
    const objectives = this.$refs.objectives as ObjectivesTab | undefined
    objectives?.addDocumentObjective(item)
    this.itemIsVisible = false
  }
}
