import { handleException } from '@/services/utils'
import { Manuals } from '@/store/models/Manuals'
import { Watch, Vue } from 'vue-property-decorator'

export default abstract class Module extends Vue {
  protected loading = false
  protected isInit = false
  public constructor() {
    super()
  }

  @Watch('isInit', { immediate: true })
  public async onCollectionChanged(): Promise<void> {
    if (this.isInit) return
    await this.loadCollection()
  }

  public async loadCollection(): Promise<void> {
    try {
      this.loading = true
      const manuals = new Manuals(this.$store)
      await manuals.load()
    } catch (ex) {
      handleException(ex as Error)
    } finally {
      this.loading = false
      this.isInit = true
    }
  }
}