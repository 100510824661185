import { Addresses } from '@/domain/addresses/Addresses'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { Department } from '@/domain/Department'
import { Facility } from '@/domain/Facility'
import { Collection } from '@/global/models/Collection'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { PartnerCollection } from '@/partners/models/PartnerCollection'
import { Reporters } from '@/partners/reporters/Reporters'
import { plainToInstance } from 'class-transformer'
import { Store } from 'vuex'
import store from '..'
import { RootState } from '../types'
import { Users } from '@/infrastucture/users/Users'

export const valueEntityByTitle: (collection: string, title: string) => ValueEntity = store.getters['manuals/valueEntityByTitle']

export async function createRequest<T>(uri: string): Promise<T> {
  const request = new AuthorizationRequest<T>(new Request(uri))
  const response = await request.response()
  return response.data
}

export class Manuals {
  private store: Store<RootState>
  public constructor(store: Store<RootState>) {
    this.store = store
  }

  public async load(): Promise<void> {
    const partners = await PartnerCollection.createAsync()
    this.store.dispatch('manuals/setPartners', partners)
    const reporters = await Reporters.createAsync()
    this.store.dispatch('manuals/setReporters', reporters)
    const addresses = await Addresses.createAsync()
    this.store.dispatch('manuals/setAddresses', addresses)
    // console.log(this.store.getters['manuals/isInit'])
    if (this.store.getters['manuals/isInit']) return
    const users = await Users.createAsync()
    this.store.dispatch('manuals/setUsers', users)
    const contractCategories = await createRequest<unknown[]>('/api/manuals/ContractCategories')
    const contractSubtypes = await createRequest<unknown[]>('/api/manuals/ContractSubtypes')
    const contractTypes = await createRequest<unknown[]>('/api/manuals/ContractTypes')
    const documentMembers = await createRequest<unknown[]>('/api/manuals/DocumentMembers')
    const priorities = await createRequest<unknown[]>('/api/manuals/priorities')
    const workflowTypes = await createRequest<unknown[]>('/api/manuals/workflowTypes')
    const statusList = await createRequest<unknown[]>('/api/manuals/StatusList')
    const objectiveMembers = await createRequest<unknown[]>('/api/manuals/ObjectiveMemberTypes')
    const addressTypes = await createRequest<unknown[]>('/api/manuals/AddressTypes')
    const addressTypesCollection = new Collection(plainToInstance(ValueEntity, addressTypes))
    const departments = await createRequest<unknown[]>('/administration/departments')
    const facilities = await createRequest<unknown[]>('/administration/facilities')
    const accountTypes = await createRequest<unknown[]>('/api/manuals/AccountTypes')
    const paymentTypes = await createRequest<unknown[]>('/api/manuals/PaymentTypes')
    const documentTypes = await createRequest<unknown[]>('/api/manuals/DocumentTypes')
    const deliveryTypes = await createRequest<unknown[]>('/api/manuals/DeliveryTypes')
    this.store.dispatch('manuals/setCollection', { name: 'addressTypes', collection: addressTypesCollection })
    this.store.dispatch('manuals/setCollection', { name: 'priorities', collection: plainToInstance(ValueEntity, priorities) })
    this.store.dispatch('manuals/setCollection', { name: 'workflowTypes', collection: plainToInstance(ValueEntity, workflowTypes) })
    this.store.dispatch('manuals/setCollection', { name: 'objectiveMembers', collection: plainToInstance(ValueEntity, objectiveMembers) })
    this.store.dispatch('manuals/setCollection', { name: 'contractCategories', collection: plainToInstance(ValueEntity, contractCategories) })
    this.store.dispatch('manuals/setCollection', { name: 'contractTypes', collection: plainToInstance(ValueEntity, contractTypes) })
    this.store.dispatch('manuals/setCollection', { name: 'contractSubtypes', collection: plainToInstance(ValueEntity, contractSubtypes) })
    this.store.dispatch('manuals/setCollection', { name: 'documentMembers', collection: plainToInstance(ValueEntity, documentMembers) })
    this.store.dispatch('manuals/setCollection', { name: 'statusList', collection: plainToInstance(ValueEntity, statusList) })
    this.store.dispatch('manuals/setCollection', { name: 'departments', collection: plainToInstance(Department, departments) })
    this.store.dispatch('manuals/setCollection', { name: 'facilities', collection: plainToInstance(Facility, facilities) })
    this.store.dispatch('manuals/setCollection', { name: 'accountTypes', collection: plainToInstance(ValueEntity, accountTypes) })
    this.store.dispatch('manuals/setCollection', { name: 'paymentTypes', collection: plainToInstance(ValueEntity, paymentTypes) })
    this.store.dispatch('manuals/setCollection', { name: 'documentTypes', collection: plainToInstance(ValueEntity, documentTypes) })
    this.store.dispatch('manuals/setCollection', { name: 'deliveryTypes', collection: plainToInstance(ValueEntity, deliveryTypes) })
    this.store.dispatch('manuals/setInit', true)
  }
}