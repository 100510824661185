import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { DeleteRequest } from '@/api/requests/DeleteRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Person } from '@/infrastucture/users/Person'
import { User } from '@/infrastucture/users/User'
import { Expose, instanceToPlain, Type } from 'class-transformer'
import moment from 'moment'
import { LinkData } from './types'

export class File extends ValueEntity {
  @Expose({ name: 'author' })
  @Type(() => User)
  private _author: Person
  @Expose({ name: 'modifier' })
  @Type(() => User)
  private _modifier: Person
  @Expose({ name: 'created' })
  @Type(() => Date)
  private _created: Date
  @Expose({ name: 'modified' })
  @Type(() => Date)
  private _modified: Date
  private webFullUrl: string
  private documentId: number
  constructor()
  constructor(other: File)
  constructor(other: ValueEntity, author: Person, modifier: Person, created: Date, modified: Date, webFullUrl: string, documentId: number)
  constructor(other?: ValueEntity, author?: Person, modifier?: Person, created?: Date, modified?: Date, webFullUrl?: string, documentId?: number) {
    const origin = other as File | undefined
    super(origin ?? new ValueEntity())
    this._author = origin?._author ?? author ?? new Person()
    this._modifier = origin?._modifier ?? modifier ?? new Person()
    this._created = origin?._created ?? created ?? moment(0).toDate()
    this._modified = origin?._modified ?? modified ?? moment(0).toDate()
    this.webFullUrl = origin?.webFullUrl ?? webFullUrl ?? ''
    this.documentId = origin?.documentId ?? documentId ?? 0
  }

  public get author(): string {
    return this._author.toString()
  }

  public get modifier(): string {
    return this._modifier.toString()
  }

  public get created(): string {
    return moment(this._created).format('DD.MM.YYYY')
  }

  public get modified(): string {
    return moment(this._modified).format('DD.MM.YYYY')
  }


  public async delete(): Promise<void> {
    const request = new AuthorizationRequest(new DeleteRequest(new Request<void>(`/files/${this.id}`), {}))
    await request.response()
  }

  public async download(): Promise<void> {
    const json = instanceToPlain(this)
    const request = new AuthorizationRequest(new PostRequest(new Request<LinkData>(`/files/link`), json))
    const response = await request.response()
    window.open(response.data.href)
  }
}
