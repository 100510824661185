export enum StageTypeValue {
  registration = 'Регистрация',
  development = 'Разработка',
  approve = 'Согласование',
  validation = 'Утверждение',
  execution = 'Исполнение',
  review = 'Рассмотрение',
}

export enum ObjectiveStageTypeValue {
  staging = 'Постановка',
  execution = 'Исполнение',
  review = 'Приёмка',
}

export enum MemberTypeValue {
  initiator = 'Инициатор',
  developer = 'Юрист',
  validator = 'Утверждающий',
  approvers = 'Согласующий',
  executor = 'Ответственный',
  financier = 'Казначей',
  receiver = 'Адресат',
}

export enum WorkflowTypeValue {
  contract = 'Договор - стандарт',
  bill = 'Счёт - стандарт',
  incomingDocument = 'Входящая корреспонденция - стандарт',
  outgoingDocument = 'Исходящая корреспонденция - стандарт',
}

export enum Status {
  staging = 'Проект',
  realise = 'Действующий',
  reject = 'Отклонён',
}
