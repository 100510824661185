import { map as _map } from 'lodash'
import { resourceConfig } from '@/api/requests/config'
import { Collection, ICollection } from '@/global/models/Collection'
import { AxiosError, AxiosRequestConfig } from 'axios'
import moment, { Moment } from 'moment'
import { Claim } from './Claim'
import { AccessEntity } from './types'
import { IAccessProvider } from './IAccessProvider'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { ActionResultErrorEntity } from './ActionResultError'
import { ISerilazable } from '@/global/models/Serilazable'

export class AuthorizationAccess implements IAccessProvider, ISerilazable<AccessEntity> {
  protected accessToken: string
  protected refreshToken: string
  protected expires: Moment
  protected claims: ICollection<Claim>
  public constructor()
  public constructor(other: AuthorizationAccess)
  public constructor(accessToken: string, refreshToken: string, expires: Moment, claims: ICollection<Claim>)
  public constructor(...args: unknown[]) {
    const other = args[0] as AuthorizationAccess | undefined
    const accessToken = other?.accessToken ?? args[0] as string ?? ''
    const refreshToken = other?.refreshToken ?? args[1] as string ?? ''
    const expires = other?.expires ?? args[2] as Moment ?? moment()
    const claims = other?.claims ?? args[3] as ICollection<Claim> ?? new Collection<Claim>([])
    this.accessToken = accessToken
    this.refreshToken = refreshToken
    this.expires = expires
    this.claims = claims
  }

  public static createFromData(data: AccessEntity): AuthorizationAccess {
    const claims = _map((data.claims || []), (entity) => new Claim(entity.type, entity.value))
    return new AuthorizationAccess(data.accessToken || '', data.refreshToken || '', moment(data.expires || 0), new Collection(claims))
  }

  public has(claim: Claim): boolean {
    return this.claims.any(item => item.equals(claim))
  }

  public entity(): AccessEntity {
    const claims = this.claims.select((claim) => claim.entity()).toArray()
    return { accessToken: this.accessToken, refreshToken: this.refreshToken, expires: this.expires.toISOString(), claims }
  }

  public requestConfig(): AxiosRequestConfig {
    const config: AxiosRequestConfig = { ...resourceConfig }
    config.headers = {
      Authorization: `Bearer ${this.accessToken}`
    }
    return config
  }

  public isExpired(): boolean {
    return this.expires.isSameOrBefore(moment().add(5, 'minutes'))
  }

  public isAny(): boolean {
    return !this.accessToken || !this.refreshToken
  }

  public async token(): Promise<AuthorizationAccess> {
    const data = new FormData()
    data.append('accessToken', this.accessToken)
    data.append('refreshToken', this.refreshToken)
    const request = new PostRequest<AccessEntity, FormData>(new Request('/oauth/token'), data)
    try {
      const response = await request.response()
      const access = AuthorizationAccess.createFromData(response.data)
      return access
    } catch (error) {
      const axiosError = error as AxiosError<ActionResultErrorEntity>
      throw axiosError
    }
  }
}