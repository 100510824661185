import { gt, lt } from 'lodash'
import moment, { Moment } from 'moment'

export const stringComparer = (prev: string, next: string): number => {
	if (gt(prev, next)) return 1
	else if (lt(prev, next)) return -1
	else return 0
}

export function dateComparer(prev: Moment | Date, next: Moment | Date): number {
	const momentA = moment(prev,)
	const momentB = moment(next)
	if (momentA > momentB) return 1
	else if (momentA < momentB) return -1
	else return 0
}