import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import { Axios } from 'axios'
import { resourceConfig } from './config'

export class Request<T> {
  protected client: Axios
  protected requestMessage: AxiosRequestConfig
  public constructor(other: Request<T>)
  public constructor(uri: string)
  public constructor(uri: string, headers: AxiosRequestHeaders,)
  public constructor(uri: string, headers: AxiosRequestHeaders, client: AxiosInstance)
  public constructor(...args: unknown[]) {
    const other = args[0] as Request<T> | undefined
    const headers = args[1] as AxiosRequestHeaders ?? {}
    const requestMessage = other?.requestMessage ?? { url: args[0] as string, method: 'get', headers: headers }
    const client = other?.client ?? args[2] as AxiosInstance ?? axios.create(resourceConfig)
    this.client = client
    this.requestMessage = requestMessage
  }

  public async response(): Promise<AxiosResponse<T>> {
    return this.client.request(this.requestMessage)
  }
}