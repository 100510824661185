import { Address } from './Address'
import { TerritorialAddress } from './TerritorialAddress'

export class Updatable extends Address {
  public constructor(origin: Address, postCode: string, type: string, area: string, settlement: string, street: string, house: string) {
    super(origin)
    this.postCode = postCode
    this.type = type
    this.territorial = new UpdatableTerritorial(this.territorial, area, settlement, street, house)
  }
}


export class UpdatableTerritorial extends TerritorialAddress {
  public constructor(origin: TerritorialAddress, area: string, settlement: string, street: string, house: string) {
    super(origin)
    this.country = this.country || 'Россия'
    this.area = area
    this.settlement = settlement
    this.street = street
    this.house = house
  }
}
