import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { Contract, ContractWorkflowProcess } from '@/domain/Contract'
import { Collection } from '@/global/models/Collection'
import { Entity } from '@/global/models/Entity'
import { Field, IField } from '@/global/models/Field'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Partner } from '@/partners/models/Partner'
import { PartnerCollection } from '@/partners/models/PartnerCollection'
import { Reporter } from '@/partners/reporters/Reporter'
import store from '@/store'
import { plainToInstance } from 'class-transformer'
import _ from 'lodash'
import { Dictionary } from 'lodash'

export function partnerByReporter(reporterId: number): Partner | undefined {
  const partners = (store.getters['manuals/partners'] as PartnerCollection)
  return partners.find(item => item.hasReporter(reporterId))
}

export function reportersByPartner(partner: Partner | null): ValueEntity[] {
  if (!partner || partner.isAny) return []
  const collection = (store.getters['manuals/reporters'] as Collection<Reporter>)
  const values = collection.where(item => partner.hasReporter(item)).select(item => item.toData()).toArray()
  return values
}

export async function contractsAsync(): Promise<Contract[]> {
  const request = new AuthorizationRequest(new Request<Dictionary<unknown>[]>('api/contracts'))
  const response = await request.response()
  const items = _.map(response.data, (data) => {
    const process = plainToInstance(ContractWorkflowProcess, data)
    const contract = _.get(process, 'document') as Contract
    return contract
  })
  return items
}

export function userDepartmentField(initiator: Entity): IField<string> {
  const departments = store.getters['manuals/departments']
  const department = _.find(departments, item => item.has(initiator))
  return new Field(department?.toString() || '', 'department', { label: 'Отдел', labelInValue: true, disabled: true }, 'FLInput')
}
