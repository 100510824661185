import { Location } from "vue-router"
import { Dictionary } from 'vue-router/types/router'
import { IRoutable } from '../models/IRoutable'

export interface IModuleRouteVM extends IRoutable {
  title: string
  mainIcon: string
  view: string
  getView(key: string): string
  changeView(view: string): void
}

export class ModuleRouteVM implements IModuleRouteVM {
  protected _title: string
  protected name: string
  protected _icon: string
  protected currentView: string
  public constructor()
  public constructor(other: ModuleRouteVM)
  public constructor(name: string, title: string, icon: string, currentView: string)
  public constructor(...args: unknown[]) { // left
    const other = args[0] as ModuleRouteVM | undefined
    const name = other?.name ?? args[0] as string ?? 'incomingDocuments'
    const title = other?._title ?? args[1] as string ?? ''
    const icon = other?._icon ?? args[2] as string ?? ''
    const currentView = other?.currentView ?? args[3] as string ?? 'left'
    this.name = name
    this._title = title
    this._icon = icon
    this.currentView = currentView
  }
  // eslint-disable-next-line
  public getView(key: string): string {
    return this.currentView
  }

  public static default(): ModuleRouteVM {
    return new ModuleRouteVM('objectives', 'Задачи', 'check-square', 'left')
  }

  public get title(): string {
    return this._title
  }

  public get mainIcon(): string {
    return this._icon
  }

  public get view(): string {
    return this.currentView
  }

  protected get params(): Dictionary<string> {
    return {}
  }

  public changeView(view: string): void {
    this.currentView = view
  }

  public asRoute(): Location {
    return { name: this.name, params: this.params }
  }
}

export class DecoratableRouteVM extends ModuleRouteVM {
  constructor(protected origin: ModuleRouteVM) {
    super(origin)
  }
}
