import { Dictionary } from 'lodash'
import { IProcessTitlesFactory, ProcessTitles } from './IProcessTitlesFactory'

export class IncomingDocumentProcessTitlesFactory implements IProcessTitlesFactory {
  private stageNext: Dictionary<string> = {
    Регистрация: 'На рассмотрение',
    Рассмотрение: 'Рассмотрено',
  }
  private stageComlete: Dictionary<string> = {
    Рассмотрение: 'Рассмотрено',
  }
  public titles(stage: string): ProcessTitles {
    return {
      start: 'Принять',
      complete: this.stageComlete[stage] || 'Выполнено',
      next: this.stageNext[stage] || 'На следующий этап',
      revert: 'На доработку',
      reject: 'Отклонить',
    }
  }
}
