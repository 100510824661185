import Vue from 'vue'
import { ButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { Emitter, ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { emptyAction } from '@/global/models/types'
import { Partner } from '../models/Partner'
import { AlterablePartner } from '../models/AlterablePartner'

export class PartnerCreation extends ButtonSlot {
  public static create(): PartnerCreation {
    return new PartnerCreation([
      new ViewDelegateButton('new-partner', 'plus-circle', 'Новый контрагент', 'ant-office-btn-link', () => emptyAction()),
    ])
  }

  public on(emitter: Emitter): void {
    const creation = this.btns[0]
    const observer = new ViewDelegateButton(creation.key, creation.icon, creation.title, creation.style, () => emitter('open-form', new AlterablePartner(Partner.any())))
    Vue.set(this.btns, 0, observer)
  }
}
