import { IEquatable } from '@/infrastucture/IEquatable'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { first } from '@/services/utils'
import { Type } from 'class-transformer'
import _ from 'lodash'
import { IProcessTemplate } from './IProcessTemplate'
import { Stage } from './Stage'

export class ProcessTemplate implements IProcessTemplate {
  @Type(() => Stage)
  private stages: Stage[]
  constructor(stages: Stage[] = []) {
    this.stages = _.orderBy(stages, stage => stage.id)
  }
  public next(current: ValueEntity): Stage {
    const next = first(_.drop(_.dropWhile(this.stages, stage => !stage.equals(current)), 1))
    return next
  }
  public previous(current: ValueEntity): Stage {
    if (current.toString() != "Приёмка")
      throw new Error("Отправить на доработку можно только на приёмке")
    // var previous = this.stages.ToArray().Reverse().SkipWhile(stage => !stage.Equals(current)).Skip(1).First()
    const previous = first(_.drop(_.dropWhile([...this.stages].reverse(), stage => !stage.equals(current)), 1))
    return previous
  }
  public stageType(id: number): Stage
  public stageType(stage: IEquatable<ValueEntity>): Stage
  public stageType(title: string): Stage
  public stageType(arg: string | number | IEquatable<ValueEntity>): Stage {
    if (typeof arg === 'string') return first(this.stages, stage => stage.toString() === arg)
    if (typeof arg === 'number') return first(this.stages, stage => stage.id === arg)
    return first(this.stages, stage => arg.equals(stage))
  }

  public canBeReverted(title: string): boolean
  public canBeReverted(stage: IEquatable<ValueEntity>): boolean
  public canBeReverted(arg: string | IEquatable<ValueEntity>): boolean {
    const stage = (typeof arg === 'string')
      ? first(this.stages, stage => stage.toString() === arg)
      : first(this.stages, stage => arg.equals(stage))
    return stage.toString() === 'Приёмка'
  }

  public canBeNext(title: string): boolean
  public canBeNext(stage: IEquatable<ValueEntity>): boolean
  public canBeNext(stage: string | IEquatable<ValueEntity>): boolean {
    // eslint-disable-next-line
    const stageType = this.stageType(stage as any)
    const i = _.findIndex(this.stages, item => item.equalsById(stageType))
    return i > -1 && i < this.stages.length - 1
  }
}