import { PartnersRouteVM } from '@/partners/viewModels/PartnersRouteVM'
import { ModuleRouteVM } from '../global/viewModels/ModuleRouteVM'

export const moduleNameRoute = new Map([
  ['incomingDocuments', () => new ModuleRouteVM('incomingDocuments', 'Корреспонденция', 'mail', 'left')],
  ['outgoingDocuments', () => new ModuleRouteVM('outgoingDocuments', 'Корреспонденция', 'mail', 'left')],
  ['contracts', () => new ModuleRouteVM('contracts', 'Договоры', 'file-text', 'left')],
  ['objectives', () => new ModuleRouteVM('objectives', 'Задачи', 'check-square', 'left')],
  ['bills', () => new ModuleRouteVM('bills', 'Финансы', 'RubIcon', 'left')],
  ['partners', () => new PartnersRouteVM(new ModuleRouteVM('partners', 'Контрагенты', 'idcard', 'left'))],
  ['settings', () => new ModuleRouteVM('settings', 'Настройки', 'setting', 'left')],
])
