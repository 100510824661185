import { IEntity } from '@/infrastucture/IEntity'
import { Collection, ICollection } from './Collection'
import { Entity } from './Entity'

export const entityComparer = (target: IEntity, source: IEntity): boolean => target.equalsById(source)

export interface IEntityCollection<T extends IEntity> extends ICollection<T> {
  byId(id: number): T | undefined
}

export class EntityCollection<T extends IEntity> extends Collection<T> implements IEntityCollection<T>/* , IAcceptor<AbstractEntityVisitor> */ {
  public byId(id: number): T | undefined {
    const found = new Entity(id)
    return this.find((entity) => entity.equalsById(found))
  }

  public hasById(entity: Entity): boolean {
    return this.any((current) => current.equalsById(entity))
  }

  public update(element: T): void {
    super.update(element, entityComparer)
  }

  public remove(element: T): void
  public remove(i: number): void
  public remove(...args: unknown[]): void {
    if (typeof args[0] === 'number') super.remove(args[0] as number)
    else super.remove(args[0] as T, entityComparer)
  }

/*   public accept(visitor: AbstractEntityVisitor): void {
    this.forEach((item) => {
      item.accept(visitor)
    })
  } */
}
