import { IModuleRouteVM, ModuleRouteVM } from '@/global/viewModels/ModuleRouteVM'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { IModuleRouteState, RootState } from './types'

const state: IModuleRouteState = {
  viewModel: ModuleRouteVM.default()
}
const getters: GetterTree<IModuleRouteState, RootState> = {
  viewModel(state: IModuleRouteState) {
    return state.viewModel
  }
}
const mutations: MutationTree<IModuleRouteState> = {
  init(state, viewModel: IModuleRouteVM) {
    state.viewModel = viewModel
  },
}
const actions: ActionTree<IModuleRouteState, RootState> = {
  init({ commit }, viewModel: IModuleRouteVM) {
    commit('init', viewModel)
  },
}

export const moduleOptions: Module<IModuleRouteState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
