import { Entity } from '@/global/models/Entity'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { DepartmentData } from '@/modules/DepartmentData'
import { User } from '@/infrastucture/users/User'
import { Type } from 'class-transformer'
import _ from 'lodash'

export class Department extends ValueEntity {
  private codeLiteral: string
  @Type(() => User)
  private users: User[]
  @Type(() => User)
  private manager: User
  constructor()
  constructor(entity: ValueEntity, codeLiteral: string, users: User[], manager: User)
  constructor(entity?: ValueEntity, codeLiteral?: string, users?: User[], manager?: User) {
    super(entity ?? new ValueEntity())
    this.codeLiteral = codeLiteral ?? ''
    this.users = users ?? []
    this.manager = manager ?? User.any
  }

  public get usersList(): ValueEntity[] {
    return _.map(this.users, item => item.toData())
  }

  public has(user: Entity): boolean
  public has(user: User): boolean
  public has(user: User | Entity): boolean {
    return _.some(this.users, item => item.equalsById(user))
  }

  public toData(): DepartmentData {
    return {
      id: this.id,
      itemTitle: this.title,
      codeLiteral: this.codeLiteral,
      manager: this.manager.toData(),
    }
  }
}
