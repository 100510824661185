
import { moduleNameRoute } from '@/router/modules'
import { IModuleRouteVM, ModuleRouteVM } from '@/global/viewModels/ModuleRouteVM'
import { Component, Vue } from 'vue-property-decorator'
import RubCircleIcon from '@/assets/icons/RubCircleIcon.vue'
import { AuthorizationAccess } from '@/oauth/models/AuthorizationAccess'
import { claims } from '@/infrastucture/claims'

@Component({
  components: {
    RubCircleIcon,
  },
})
export default class Main extends Vue {
  private financeIcon = RubCircleIcon
  public get routeVM(): IModuleRouteVM {
    return this.$store.getters['moduleRoute/viewModel']
  }

  public get access(): AuthorizationAccess {
    return this.$store.getters['oauth/access']
  }

  public get userIsAdmin(): boolean {
    return this.access.has(claims.admin)
  }

  public get currentModule(): string {
    return this.$route.name || ''
  }

  public set currentModule(module: string) {
    this.selectModule(module)
  }

  public selectModule(module: string): void {
    const routeVM = moduleNameRoute.get(module)?.() || ModuleRouteVM.default()
    this.$router.push(routeVM.asRoute())
  }
}
