import { Field } from '@/global/models/Field'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { dateComparer, stringComparer } from '@/services/comparers'
import { Moment } from 'moment'
import store from '@/store'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Users } from '@/infrastucture/users/Users'
import { BillData } from './BillData'
import { AbstractTableSource } from './AbstractTableSource'

export class BillTableSource extends AbstractTableSource<BillData> {
	private users: ValueEntity[]
	public constructor(array: BillData[]) {
		super(array)
		this.users = (store.getters['manuals/users'] as Users).select(user => user.toData()).toArray()
		this._headerColumns = [
			new Field('', 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput'),
			new Field('', 'registrationNumber', { label: 'Рег. номер', allowClear: true }, 'FLInput'),
			new Field<Moment | null>(null, 'registrationDate', { label: 'Дата регистрации', allowClear: true }, 'FLDatePicker'),
			new Field<ValueEntity>(new ValueEntity(), 'accountType',
			{ label: 'Вид расчёта', allowClear: true, valueEntities: this.accountTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'paymentType',
			{ label: 'Вид платежа', allowClear: true, valueEntities: this.paymentTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'partner',
			{ label: 'Контрагент', allowClear: true, valueEntities: this.partnersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'enterprise',
			{ label: 'Объект', allowClear: true, valueEntities: this.facilities, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'initiator',
			{ label: 'Инициатор', allowClear: true, valueEntities: this.users, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'financier',
			{ label: 'Казначей', allowClear: true, valueEntities: this.users, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
		]
	}

  public columns(): ITableColumn<BillData>[] {
		return [
			new TableColumn<BillData>('Наименование', 'itemTitle', '400px', false, (prev, next) => stringComparer(prev.itemTitle, next.itemTitle), { customRender: 'toString' }, { title: 'itemTitle' }),
			new TableColumn<BillData>('Рег. номер', 'registrationNumber', '250px', false, (prev, next) => stringComparer(prev.registrationNumber, next.registrationNumber), { customRender: 'toString' }, { title: 'registrationNumber' }),
			new TableColumn<BillData>('Дата регистрации', 'registrationDate', '250px', false, (prev, next) => dateComparer(prev.registrationData, next.registrationData), { customRender: 'dateFormat' }, { title: 'registrationDate' }),
			new TableColumn<BillData>('Сумма', 'summ', '150px', false, (prev, next) => stringComparer(prev.summ.toString(), next.summ.toString()), { customRender: 'toString' }),
			new TableColumn<BillData>('Код бюджета', 'codeBudget', '150px', false, (prev, next) => stringComparer(prev.codeBudget, next.codeBudget), { customRender: 'toString' }),
			new TableColumn<BillData>('Сумма НДС', 'ndsSumm', '150px', false, (prev, next) => stringComparer(prev.ndsSumm.toString(), next.ndsSumm.toString()), { customRender: 'toString' }),
			new TableColumn<BillData>('Место хранения', 'storage', '200px', false, (prev, next) => stringComparer(prev.storage, next.storage), { customRender: 'toString' }),
			new TableColumn<BillData>('Вид расчёта', 'accountType', '250px', false, (prev, next) => stringComparer(prev.accountType.toString(), next.accountType.toString()), { customRender: 'toString' }, { title: 'accountType' }),
			new TableColumn<BillData>('Вид платежа', 'paymentType', '250px', false, (prev, next) => stringComparer(prev.paymentType.toString(), next.paymentType.toString()), { customRender: 'toString' }, { title: 'paymentType' }),
			new TableColumn<BillData>('Контрагент', 'partner', '250px', false, (prev, next) => stringComparer(prev.partner.toString(), next.partner.toString()), { customRender: 'toString' }, { title: 'partner' }),
			new TableColumn<BillData>('Объект', 'enterprise', '250px', false, (prev, next) => stringComparer(prev.enterprise.toString(), next.enterprise.toString()), { customRender: 'toString' }, { title: 'enterprise' }),
			new TableColumn<BillData>('Инициатор', 'initiator', '250px', false, (prev, next) => stringComparer(prev.initiator.toString(), next.initiator.toString()), { customRender: 'toString' }, { title: 'initiator' }),
			new TableColumn<BillData>('Казначей', 'financier', '250px', false, (prev, next) => stringComparer(prev.financier.toString(), next.financier.toString()), { customRender: 'toString' }, { title: 'financier' }),	
		]
	}
}