import { MemberTypeValue } from '@/infrastucture/LookupTypes'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { BillData } from '@/modules/BillData'
import { partnerByReporter } from '@/services/cardUtils'
import store from '@/store'
import { valueEntityByTitle } from '@/store/models/Manuals'
import { Users } from '@/infrastucture/users/Users'
import { Type } from 'class-transformer'
import _ from 'lodash'
import moment from 'moment'
import { Contract } from './Contract'
import { Document } from './Document'
import { Member } from './Member'
import { Registration } from './Registration'
import { Summ } from './Summ'

export class Bill extends Document {
  @Type(() => ValueEntity)
  private accountType: ValueEntity
  @Type(() => Contract)
  private contract: Contract
  private expenseItem: string
  @Type(() => ValueEntity)
  private paymentType: ValueEntity
  @Type(() => Summ)
  private summ: Summ
  private paymentIsOverBudget: boolean
  private paymentIsUrgent: boolean
  private orderIsAfterPayment: boolean
  constructor()
  constructor(other: Bill)
  constructor(origin: Document, accountType: ValueEntity, contract: Contract, expenseItem: string, paymentType: ValueEntity, summ: Summ,
    paymentIsOverBudget: boolean, paymentIsUrgent: boolean, orderIsAfterPayment: boolean)
  constructor(origin?: Document, accountType?: ValueEntity, contract?: Contract, expenseItem?: string, paymentType?: ValueEntity, summ?: Summ,
    paymentIsOverBudget?: boolean, paymentIsUrgent?: boolean, orderIsAfterPayment?: boolean) {
    const other = origin as Bill | undefined
    super(other ?? new Document())
    this.accountType = other?.accountType ?? accountType ?? new ValueEntity()
    this.contract = other?.contract ?? contract ?? Contract.any
    this.expenseItem = other?.expenseItem ?? expenseItem ?? ''
    this.paymentType = other?.paymentType ?? paymentType ?? new ValueEntity()
    this.summ = other?.summ ?? summ ?? new Summ()
    this.paymentIsOverBudget = other?.paymentIsOverBudget ?? paymentIsOverBudget ?? false
    this.paymentIsUrgent = other?.paymentIsUrgent ?? paymentIsUrgent ?? false
    this.orderIsAfterPayment = other?.orderIsAfterPayment ?? orderIsAfterPayment ?? false
  }

  public static create(): Bill {
		const status = valueEntityByTitle('statusList', 'Проект')
		const users: Users = store.getters['manuals/users']
		const members = [new Member(users.currentUser, valueEntityByTitle('documentMembers', 'Инициатор'))]
		const document = new Document(new ValueEntity(), new Registration(), status, valueEntityByTitle('workflowTypes', 'Счёт - стандарт'), '', members)
		const item = new Bill(document, new ValueEntity(), Contract.any, '', new ValueEntity(), new Summ(), false, false, false)
		return item
	}

  public toData(): BillData {
    const contractData = this.contract.toData()
    return {
      id: this.id,
      itemTitle: this.title,
      storage: this.storage,
      registrationNumber: this.registration.registrationNumber,
      registrationDate: moment(this.registration.registrationDate),
      contract: this.contract,
      partner: partnerByReporter(contractData.reporter.id)?.toData() || new ValueEntity(),
      enterprise: contractData.enterprise,
      summ: this.summ.amount,
			codeBudget: this.summ.budgetCode,
			ndsSumm: this.summ.vatAmount,
      accountType: this.accountType,
      paymentType: this.paymentType,
      expenseItem: this.expenseItem,
      paymentIsOverBudget: this.paymentIsOverBudget,
      paymentIsUrgent: this.paymentIsUrgent,
      orderIsAfterPayment: this.orderIsAfterPayment,
      initiator: this.member(MemberTypeValue.initiator).toData(),
      departmentTitle: 'ОИТ',
      approvers: _.map(this.membersByType(MemberTypeValue.approvers), member => member.toData()),
      financier: this.member(MemberTypeValue.financier).toData(),
    }
  }
}
