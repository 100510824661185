
import { Component, Watch } from 'vue-property-decorator'
import Table from '@/global/table/components/Table.vue'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { Manuals } from '@/store/models/Manuals'
import { handleAxiosError } from '@/services/utils'
import ModuleWithRoute from '@/infrastucture/components/ModuleWithRoute'
import { Contract, ContractWorkflowProcess } from '@/domain/Contract'
import { Section } from '@/global/buttonPanel/viewModels/Section'
import { EntityCollection } from '@/global/models/EntityCollection'
import { ContractTableSource } from '../ContractTableSource'
import { ContractData } from '../ContractData'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { ButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Dictionary } from 'vue-router/types/router'
import { Request } from '@/api/requests/Request'
import _ from 'lodash'
import { plainToClass } from 'class-transformer'
@Component({
  components: {
    Table,
    ButtonPanel,
    ButtonSection,
    ItemDrawer,
  },
  
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
    const self = this as Contracts
    self.beforeRouteUpdate(to, from, next)
  },
})
// Всегда присваивай значения полям в date хотябы null
export default class Contracts extends ModuleWithRoute<ContractWorkflowProcess, ContractData> {
  protected sections: Section[]
  public items: EntityCollection<ContractWorkflowProcess>
  public dataCollection: ContractTableSource
  protected buttonPanel: ButtonPanelSource
  protected creation: ButtonSlot
  protected loading = false
  protected selectedItem: ContractWorkflowProcess | null
  public constructor() {
    super('contracts', 'ContractDetail')
    this.items = new EntityCollection()
    this.dataCollection = new ContractTableSource([])
    this.sections = [
      new Section('creation', 'Создание')
    ]
    this.buttonPanel = new ButtonPanelSource(this.sections)
    this.creation = new ButtonSlot([
      new ViewDelegateButton('new-item', 'plus-circle', 'Новый договор', 'ant-office-btn-link', () => this.openCard(-1)),
    ])
    this.selectedItem = null
  }

  public get isInit(): boolean {
    return this.items.count > 0
  }

  @Watch('isInit', { immediate: true })
  public async onChange(): Promise<void> {
    if (this.isInit) return
    await this.loadCollection()
    await super.onChange()
  }

  public async loadCollection(): Promise<void> {
    try {
      this.loading = true
      const manuals = new Manuals(this.$store)
      await manuals.load()
      const request = new AuthorizationRequest(new Request<Dictionary<unknown>[]>('api/contracts'))
      const response = await request.response()
      const items = _.map(response.data, (data) => plainToClass(ContractWorkflowProcess, data))
      this.items = new EntityCollection(items)
      this.dataCollection = new ContractTableSource(this.items.select(item => item.toData()).toArray())
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }

  public anyItem(): ContractWorkflowProcess {
    return ContractWorkflowProcess.create()
  }

  public openForm(item: ContractData): void {
    this.openCard(item.id)
  }

  public openCard(key: number): void {
    super.openCard(key)
  }

  public async onUpdated(item: Contract): Promise<void> {
    if (!this.items) return
    const oldItem = this.items.byId(item.id)
    if (oldItem) {
      const newItem = new ContractWorkflowProcess(oldItem, item)
      this.items.update(newItem)
      this.dataCollection.update(newItem.toData(), (target, source) => target.id === source.id)
      this.selectedItem = newItem
    }
    if (!this.viewOnly) this.$router.back()
  }

  public async onAdded(item: ContractWorkflowProcess): Promise<void> {
    if (!this.items) return
    this.items.insert(item, 0)
    this.dataCollection.insert(item.toData(), 0)
    this.selectedItem = item
    this.$router.replace(this.viewRoute(this.selectedItem.id))
  }
}
