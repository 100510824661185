
import { Entity } from '@/global/models/Entity'
import { IField } from '@/global/models/Field'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { ClassConstructor, instanceToPlain } from 'class-transformer'
import { handleAxiosError } from '@/services/utils'
import { Request } from '@/api/requests/Request'
import { PutRequest } from '@/api/requests/PutRequest'
import { DeserilazableRequest } from '@/api/requests/DeserilazableRequest'
import FieldSet from '@/infrastucture/components/FieldSet.vue'

@Component({
  components: {
    FieldSet,
  }
})
export default class SettingsCard<T extends Entity> extends Vue {
  @Prop({ type: Object, required: true }) public item!: T
  @Prop({ type: String, required: true }) public uri!: string
  @Prop({ type: Array, required: true }) public fieldsList!: IField<unknown>[]
  @Prop({ type: Function, required: true }) public createItem!: () => T
  @Prop({ type: Function, required: true }) public deserializeType!: ClassConstructor<T>
  private loading = false
  private invalid: boolean
  public constructor() {
    super()
    this.invalid = true
  }


  public async save(): Promise<void> {
    try {
      const item = this.createItem()
      this.loading = true
      // eslint-disable-next-line
      const plain: any = instanceToPlain(item)
      let request: Request<unknown>
      if (item.isAny) {
        request = new DeserilazableRequest<T>(new AuthorizationRequest(new PostRequest(new Request(this.uri), plain)), this.deserializeType)
        const response = await request.response()
        this.$emit('added', response.data)
      } else {
        request = new AuthorizationRequest(new PutRequest(new Request(this.uri), plain))
        await request.response()
        this.$emit('updated', item)
      }      
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }
}
