<script>
export default {
  // eslint-disable-next-line
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        version="1.1"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 50 50"
      >
        <g id="Слой_x0020_1">
          <path
            id="_1"
            fill="currentColor"
            d="M25 0c13.81,0 25,11.19 25,25 0,13.81 -11.19,25 -25,25 -13.81,0 -25,-11.19 -25,-25 0,-13.81 11.19,-25 25,-25zm-2.89 15.06l3.8 0c4.33,0 6.49,1.83 6.49,5.49 0,1.86 -0.58,3.31 -1.77,4.34 -1.17,1.01 -2.89,1.53 -5.12,1.53l-2.67 0 0 -0.02 -0.73 0 0 -11.34 0 0zm-3.24 11.34l-3.89 0 0 3.05 3.89 0 0 2.03 -3.89 0 0 3.05 3.89 0 0 5.3 3.24 0 0 -5.3 5.05 0 0 -3.05 -5.05 0 0 -2.03 0.73 0 0 -0.09 2.92 0c3.07,0 5.52,-0.84 7.33,-2.51 1.81,-1.68 2.72,-3.83 2.72,-6.45 0,-2.63 -0.82,-4.66 -2.44,-6.11 -1.64,-1.44 -3.94,-2.16 -6.89,-2.16l-7.61 0 0 14.27z"
          />
        </g>
      </svg>
    )
  },
}
</script>