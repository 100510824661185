import { Employee } from '@/infrastucture/users/Employee'
import { IField, AnyField } from '@/global/models/Field'
import { Fieldset } from '@/global/models/Fieldset'
import { Person } from '@/infrastucture/users/Person'
import { Reporter } from './Reporter'
import { Reporters } from './Reporters'
import { RecordReporter as Record } from './RecordReporter'
import { Partner } from '../models/Partner'
import { InsertableReporter } from './InsertableReporter'
import { InsertableRecord, RemovableRecord } from '@/global/models/Record'
import { RemoveRemoteRecord } from '@/global/models/RemoteRecord'

export class ReporterForm extends Fieldset {
  private _name: IField<string>[]
  private _jobTitle: IField<string>
  private _email: IField<string>
  private _phone: IField<string>
  private _hasEdit: boolean
  private reporters: Reporters
  private partner: Partner
  public constructor(origin: Fieldset, reporters: Reporters, partner: Partner, hasEdit = false) {
    super(origin)
    this._name = this.rangeFields(['lastName', 'firstName', 'middleName'], this.textFields)
    this._jobTitle = this.textFields.get('jobTitle') || new AnyField()
    this._email = this.textFields.get('email') || new AnyField()
    this._phone = this.textFields.get('phone') || new AnyField()
    this._hasEdit = hasEdit
    this.reporters = reporters
    this.partner = partner
  }

  public equals(other: ReporterForm): boolean {
    return this.key === other.key
  }

  public get name(): IField<string>[] {
    return this._name
  }

  public get fullName(): string {
    return `${this.name[0].viewContent} ${this.name[1].viewContent} ${this.name[2].viewContent}`
  }

  public get jobTitle(): IField<string> {
    return this._jobTitle
  }

  public get email(): IField<string> {
    return this._email
  }

  public get phone(): IField<string> {
    return this._phone
  }

  public get hasEdit(): boolean {
    return this._hasEdit
  }

  public edit(): void {
    this._hasEdit = true
  }

  public cancel(): void {
    this._hasEdit = false
  }

  public get isAny(): boolean {
    return this.key <= 0
  }

  protected asReporter(): Reporter {
    const person = new Person(this.key, this.name[1].content(), this.name[0].content(), this.name[2].content())
    return new Reporter(new Employee(person, this._email.content(), this.phone.content(), this.jobTitle.content()), false)
  }

  public async update(): Promise<Reporter> {
    if (this.isAny) throw new Error('Нельзя обновить элемент. Элемент не существует.')
    const reporter = this.asReporter()
    const record = new Record(reporter, this.reporters)
    await record.transfer()
    return new Reporter(record)
  }

  public async add(): Promise<Reporter> {
    if (!this.isAny) throw new Error('Элемент уже существует.')
    const reporter = new InsertableReporter(this.asReporter(), this.partner)
    await reporter.transfer()
    const record = new InsertableRecord(reporter, this.reporters)
    await record.transfer()
    return new Reporter(reporter)
  }

  public async save(): Promise<Reporter> {
    if (this.isAny) return this.add()
    return this.update()
  }

  public async remove(): Promise<Reporter> {
    const reporter = this.asReporter()
    const remoteRecord = new RemoveRemoteRecord(reporter, '/api/reporters')
    await remoteRecord.transfer()
    const record = new RemovableRecord(reporter, this.reporters)
    await record.transfer()
    return new Reporter(reporter)
  }
}
