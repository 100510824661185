import store from '@/store'
import Vue from 'vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { AuthorizationAccess } from './AuthorizationAccess'

export function oauthRedirect<V extends Vue = Vue>(to: Route, from: Route, next: NavigationGuardNext<V>): boolean {
  const access = store.getters['oauth/access'] as AuthorizationAccess
  if (access.isAny()) {
    const location = { path: '/oauth', query: { returnUrl: to.fullPath } }
    next(location)
    return true
  }
  return false
}

export function oauthFirstRedirect<V extends Vue = Vue>(to: Route, from: Route, next: NavigationGuardNext<V>): boolean {
  const access = store.getters['oauth/access'] as AuthorizationAccess
  if (access.isAny() || access.isExpired()) {
    const location = { path: '/oauth', query: { returnUrl: to.fullPath } }
    next(location)
    return true
  }
  return false
}
