
import { ValueEntity } from '@/infrastucture/ValueEntity'
import _ from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { KeyLabel } from "@/global/models/ui"
import FloatLabel from '@/global/components/FloatLabel.vue'
import { Department } from '@/domain/Department'
@Component({
  components: {
    FloatLabel,
  },
})
export default class UserSelect extends Vue {
  @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: Boolean, default: false }) public allowClear!: boolean
  // eslint-disable-next-line
  @Prop({ type: [String, Number, Object, Array] }) public value!: any // Refactor
  // eslint-disable-next-line
  @Prop({ type: [String, Number, Object, Array] }) public defaultValue!: any // Refactor
  @Prop({ type: Array, default: () => [] }) public valueEntities!: ValueEntity[]
  // @Prop({ type: String, default: 'default' }) public mode!: string
  @Prop({ type: String, default: '' }) public dropdownClassName!: string
  @Prop({ type: [Boolean, Function], default: true }) public filterOption!: boolean
  @Prop({ type: Boolean, default: false }) public loading!: boolean
  @Prop({ type: Boolean, default: false }) public dropdownMatchSelectWidth!: boolean
  @Prop({ type: Boolean, default: false }) public labelInValue!: boolean
  @Prop({ type: Boolean, default: false }) public showSearch!: boolean
  protected hasFocus = false

  public get hasValue(): boolean {
    if (this.value instanceof ValueEntity) return this.value.id > 0
    if (this.value instanceof Array) return this.value.length > 0
    return !!this.value &&
      ((typeof (this.defaultValue) !== 'object' && this.value !== this.defaultValue) || (this.value?.key !== this.defaultValue?.key && this.value?.id !== 0))
  }

  public get departments(): Department[] {
    return this.$store.getters['manuals/departments']
  }

  public usersDataByDepartment(department: Department): KeyLabel[] {
    const filtered = _.filter(this.valueEntities, userData => department.has(userData))
    return _.map(filtered, entity => ({ key: entity.id, label: entity.toString() }))
  }

  public get mode(): string {
    return this.valueIsArray ? 'multiple' : 'default'
  }

  public get valueIsArray(): boolean {
    return this.value instanceof Array
  }

  public get validValue(): KeyLabel | KeyLabel[] {
    return this.valueIsArray ? _.map(this.value || [], item => this.modelToPlain(item)) : this.modelToPlain(this.value)
  }

  public get options(): KeyLabel[] {
    if (this.valueEntities && this.valueEntities.length) return _.map(this.valueEntities, entity => ({ key: entity.id, label: entity.toString() }))
    return []
  }

  public onFocus(): void {
    this.hasFocus = true
  }

  public onBlur(): void {
    this.hasFocus = false
  }

  private modelToPlain(value: number | string | ValueEntity): KeyLabel {
    if (typeof value === 'string' || typeof value === 'number') return { key: value.toString() || '', label: value.toString() || '' }
    return { key: value?.id || 0, label: value?.toString() || '' }
  }

  private plainToModel(value: KeyLabel): number | string | ValueEntity {
    const isValueEntity = this.valueEntities.length > 0
    if (isValueEntity) return _.find(this.valueEntities, (item) => item.id === value.key) || new ValueEntity() // Так надо, чтобы вернулся подтип ValueEntity
    throw new TypeError(`Unknown type detected: ${typeof this.value}`)
  }

  public onChange(value: KeyLabel | KeyLabel[]): void {
    const valueIsArray = value instanceof Array
    const input = valueIsArray
      ? _.map((value || []), item => this.plainToModel(item))
      : this.plainToModel(value || { key: 0, label: '' })
    this.$emit('input', input)
  }
}
