import { Collection } from '@/global/models/Collection'
import { ITableSource } from '@/global/table/models/TableCollection'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { UserData } from './UserData'

export class UsersTableCollection extends Collection<UserData> implements ITableSource<UserData> {
  public columns(): ITableColumn<UserData>[] {
    return [
      new TableColumn('addButton', 'empty', 20, false, false, undefined, { title: 'addButton' }),
      new TableColumn<UserData>('ФИО', 'fullName', '170px', false, false, { customRender: 'fullName' }),
      new TableColumn<UserData>('Email', 'email', '150px', false, false, { customRender: 'toString' }),
      new TableColumn<UserData>('Телефон', 'workPhone', '150px', false, false, { customRender: 'toString' }),
      new TableColumn<UserData>('', 'access', '50px', false, false, { customRender: 'accessIcon' }),
    ]
  }
  public source(): UserData[] {
    return this.toArray()
  }
}
