import { Reporter } from '@/partners/reporters/Reporter'
import { Type } from 'class-transformer'
import { Facility } from './Facility'

export class Parties {
  constructor()
  constructor(partnerVerification: string, facility: Facility, reporter: Reporter)
  constructor(partnerVerification?: string, facility?: Facility, reporter?: Reporter) {
    this.partnerVerification = partnerVerification ?? ''
    this.facility = facility ?? new Facility()
    this.reporter = reporter ?? Reporter.any()
  }

  public partnerVerification: string
  @Type(() => Facility)
  public facility: Facility
  @Type(() => Reporter)
  public reporter: Reporter
}