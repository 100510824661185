import { set as _set } from 'lodash'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { Partner } from '../models/Partner'
import { PartnerEntity, ReporterEntity } from '../models/PartnerJson'
import { DecoratableReporter } from './DecoratableReporter'
import { Reporter } from './Reporter'

type InsertReporterData = {
  Reporter: ReporterEntity,
  Partner: PartnerEntity,
}

export class InsertableReporter extends DecoratableReporter {
  private partner: Partner
  public constructor(origin: Reporter, partner: Partner) {
    super(origin)
    this.partner = partner
  }

  public async transfer(): Promise<void> {
    const data: InsertReporterData = { Reporter: this.entity(), Partner: this.partner.entity() }
    const request = new AuthorizationRequest(new PostRequest<ReporterEntity, InsertReporterData>(new Request<ReporterEntity>('/api/reporters/'), data))
    const response = await request.response()
    this.addIdentity(response.data.id)
    _set(this.origin, 'id', this.id)
  }
}