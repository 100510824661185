
import { IField, Field } from '@/global/models/Field'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import CardContent from '@/global/components/CardContent.vue'
import { ContentTab, ItemContentTab, ViewCardContent } from '@/global/viewModels/ViewCardContent'
import { Moment } from 'moment'
import { ButtonSlot, IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { first, handleAxiosError } from '@/services/utils'
import { Contract } from '@/domain/Contract'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { CommonFields, MembersFields } from '../BillData'
import { Users } from '@/infrastucture/users/Users'
import { Document } from '@/domain/Document'
import _ from 'lodash'
import { Registration } from '@/domain/Registration'
import { Member } from '@/domain/Member'
import { Summ } from '@/domain/Summ'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PutRequest } from '@/api/requests/PutRequest'
import { Request } from '@/api/requests/Request'
import { PostRequest } from '@/api/requests/PostRequest'
import Files from '@/files/Files.vue'
import CommentsTab from '@/modules/cards/CommentsTab.vue'
import ObjectivesTab from '@/modules/components/ObjectivesTab.vue'
import ObjectiveCreationCard from '@/modules/cards/ObjectiveCreationCard.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { DocumentObjectiveProcess } from '@/domain/ObjectiveProcess'
import { WorkflowSlot } from '@/workflow/WorkflowSlot'
import { BillProcessTitlesFactory } from '@/workflow/BillProcessTitlesFactory'
import { contractsAsync, partnerByReporter, userDepartmentField } from '@/services/cardUtils'
import DocumentCard from './DocumentCard'
import { BillWorkflowProcess } from '@/domain/WorkflowProcess'
import { BillData } from '../BillData'
import { Bill } from '@/domain/Bill'
import { MemberTypeValue, StageTypeValue } from '@/infrastucture/LookupTypes'
import { User } from '@/infrastucture/users/User'
import Stepper from '@/infrastucture/components/Stepper.vue'
@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    CardContent,
    FieldSet,
    Files,
    CommentsTab,
    ObjectivesTab,
    ObjectiveCreationCard,
    ItemDrawer,
    Stepper,
  },
})
/* В констукторе не стоит работать со своими элементами из this, a вот с служебными типо store можно */
export default class BillCard extends DocumentCard {
  @Prop({ type: Object }) public item!: BillWorkflowProcess
  @Prop({ type: Boolean, default: false }) public viewOnly!: boolean
  protected buttonPanel: ButtonPanelSource
  protected creation: ButtonSlot
  protected action: ButtonSlot
  protected workflow: IButtonSlot // TODO Реактивность при смене этапа
  protected cardContent: ViewCardContent
  protected loading = false
  private itemIsVisible = false
  private commonIsInvalid = true
  private membersIsInvalid = true
  private contracts: Contract[]
  private partner
  private enterprise
  private contract
  protected ndsSumm
  protected summ

  public constructor() {
    super()
    this.buttonPanel = ButtonPanelSource.create()
    const creation = new ButtonSlot([
      new ViewDelegateButton('edit', 'edit', 'Редактировать', 'ant-office-btn-link', () => { this.$emit('to-edit') }, () => !this.viewOnly, () => this.viewOnly),
      new ViewDelegateButton('save', 'save', 'Сохранить', 'ant-office-btn-link', async () => await this.save(), () => this.viewOnly || this.invalid(), () => !this.viewOnly),
    ])
    this.creation = creation
    this.action = new ButtonSlot([
      new ViewDelegateButton('create-objective', 'solution', 'Создать задачу', 'ant-office-btn-link', () => { this.openCard() }, () => !this.viewOnly, () => this.viewOnly),
    ])
    this.workflow = new WorkflowSlot(this.item, new BillProcessTitlesFactory())
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('file', 'Файлы', this.item),
      new ItemContentTab('objectives', 'Задачи', this.item),
      new ItemContentTab('comments', 'Обсуждения', this.item),
    ]
    this.contracts = []
    this.cardContent = new ViewCardContent(tabs, 'common')
    this.summ = new Field(this.itemData.summ, 'summ', { label: 'Сумма' }, 'FLInputNumber')
    this.ndsSumm = new Field(this.itemData.ndsSumm, 'ndsSumm', { label: 'Сумма НДС', outerField: this.summ }, 'VatAmountDropdown')
    this.partner =  new Field(this.itemData.partner, 'partner', { label: 'Контрагент', disabled: true, labelInValue: true }, 'FLSelect')
    this.enterprise = new Field(this.itemData.enterprise, 'enterprise', { label: 'Объект', disabled: true, labelInValue: true }, 'FLSelect')
    this.contract = new Field(this.itemData.contract, 'contract', { label: 'Договор', valueEntities: this.contracts, labelInValue: true }, 'FLSelect', ['required']),
    this.$nextTick(() => {
      this.loadContracts()
    })
  }

  public get document(): Document {
    return _.get(this.item, 'document')
  }

  public get itemData(): BillData {
    return this.item.toData()
  }

  public invalid(): boolean {
    return this.commonIsInvalid || this.membersIsInvalid
  }

  public get common(): CommonFields {
    return {
      registrationNumber: new Field(this.itemData.registrationNumber, 'registrationNumber', { label: '№ счёта', allowClear: true }, 'FLInput', ['required']),
      registrationDate: new Field(this.itemData.registrationDate, 'registrationDate', { label: 'Дата счёта', allowClear: true }, 'FLDatePicker', ['required']),
      contract: this.contract,
      partner: this.partner,
      enterprise: this.enterprise,
      storage: new Field(this.itemData.storage, 'storage', { label: 'Место хранения', allowClear: true }, 'FLInput'),
      codeBudget: new Field(this.itemData.codeBudget, 'codeBudget', { label: 'Код бюджета', }, 'FLInput'),
      summ: this.summ,
      ndsSumm: this.ndsSumm,
      accountType: new Field(this.itemData.accountType, 'accountType', { label: 'Вид расчёта', valueEntities: this.accountTypes, labelInValue: true }, 'FLSelect', ['required']),
      paymentType: new Field(this.itemData.paymentType, 'paymentType', { label: 'Вид платежа', valueEntities: this.paymentTypes, labelInValue: true }, 'FLSelect', ['required']),
      expenseItem: new Field(this.itemData.expenseItem, 'expenseItem', { label: 'Цель расхода', allowClear: true }, 'FLInput', ['required']),
      paymentIsOverBudget: new Field(this.itemData.paymentIsOverBudget, 'paymentIsOverBudget', { label: 'Оплата сверхбюджета' }, 'FLCheckbox'),
      paymentIsUrgent: new Field(this.itemData.paymentIsUrgent, 'paymentIsUrgent', { label: 'Срочный платеж' }, 'FLCheckbox'),
      orderIsAfterPayment: new Field(this.itemData.orderIsAfterPayment, 'orderIsAfterPayment', { label: 'Запрос п/п после оплаты' }, 'FLCheckbox'),
    }
  }

  public get commonFields(): IField<string | number | Moment | null | ValueEntity | boolean>[] {
    return Object.values(this.common)
  }

  public get members(): MembersFields {
    const initiator = new Field(this.itemData.initiator, 'initiator', { label: 'Инициатор', valueEntities: this.usersData, labelInValue: true, disabled: true }, 'FLSelect', ['required'])
    return {
      initiator: initiator,
      department: userDepartmentField(initiator.content()),
      approvers: new Field(this.itemData.approvers, 'approvers',
      { label: 'Согласующие', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.approve) }, 'UserSelect'),
      financier: new Field(this.itemData.financier, 'financier',
      { label: 'Казначей', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.review) }, 'UserSelect'),
    }
  }

  public get membersFields(): IField<string | ValueEntity | ValueEntity[] | boolean>[] {
    return Object.values(this.members)
  }

  public get selectedContractId(): number {
    return this.contract.content().id
  }

  public onChange(): void {
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('file', 'Файлы', this.item),
      new ItemContentTab('objectives', 'Задачи', this.item),
      new ItemContentTab('comments', 'Обсуждения', this.item),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
    this.workflow = new WorkflowSlot(this.item, new BillProcessTitlesFactory())
    this.summ = new Field(this.itemData.summ, 'summ', { label: 'Сумма' }, 'FLInputNumber')
    this.ndsSumm = new Field(this.itemData.ndsSumm, 'ndsSumm', { label: 'Сумма НДС', outerField: this.summ }, 'VatAmountDropdown')
    this.partner =  new Field(this.itemData.partner, 'partner', { label: 'Контрагент', disabled: true, labelInValue: true }, 'FLSelect')
    this.enterprise = new Field(this.itemData.enterprise, 'enterprise', { label: 'Объект', disabled: true, labelInValue: true }, 'FLSelect')
  }

  @Watch('item', { immediate: false, deep: false })
  public onItemChanged(): void {
    this.onChange()
  }

  @Watch('selectedContractId', { immediate: false })
  public onContractChanged(value: number, oldValue: number): void {
    if (value === oldValue) return
    const contract = first(this.contracts, item => item.id === value)
    const data = contract.toData()
    const partner = partnerByReporter(data.reporter.id)
    const enterprise = first(this.facilities, item => item.equalsById(data.enterprise))
    this.partner.update(partner?.toData() || new ValueEntity())
    this.enterprise.update(enterprise)
  }

  public async loadContracts(): Promise<void> {
    this.contract.props.loading = true
    this.contracts = await contractsAsync()
    this.contract.props.valueEntities = this.contracts
    this.contract.props.loading = false
  }

  public membersList(): Member[] {
    const users: Users = this.$store.getters['manuals/users']
    const userById = (id: number) => {
      const user = users.byId(id) || new User()
      return user
    }
    const initiator = new Member(userById(this.members.initiator.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.initiator))
    const approvers = _.map(this.members.approvers.content(), item => new Member(userById(item.id), first(this.documentMembers, item => item.toString() === MemberTypeValue.approvers)))
    const financier = new Member(userById(this.members.financier.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.financier))
    const members = [initiator, ...approvers, financier]
    return _.filter(members, item => !item.isAny)
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
      const itemTitle = `${this.common.registrationNumber.content().toString()}-${this.common.registrationDate.content().format('DD-MM-YYYY')}`
      const members: Member[] = this.membersList()
      const registration = new Registration(this.itemData.registrationDate.toDate(), this.common.registrationNumber.content())
      const document = this.document.updated(itemTitle, registration, this.common.storage.content(), members)
      const summ = new Summ(this.common.summ.content(), this.common.codeBudget.content(), 'рубли', this.common.ndsSumm.content())
      const contract = first(this.contracts, item => item.equalsById(this.contract.content()))
      const item = new Bill(document, this.common.accountType.content(), contract, this.common.expenseItem.content(), this.common.paymentType.content(),
        summ, this.common.paymentIsOverBudget.content(), this.common.paymentIsUrgent.content(), this.common.orderIsAfterPayment.content())
      if (this.item.isAny) {
        const request = new AuthorizationRequest(new PostRequest(new Request<unknown>('/api/bills'), instanceToPlain(item)))
        const response = await request.response()
        const process = plainToInstance(BillWorkflowProcess, response.data)
        this.$emit('added', process)
      }
      else {
        const request = new AuthorizationRequest(new PutRequest(new Request<void>('/api/bills'), instanceToPlain(item)))
        await request.response()
        this.$emit('updated', item)
      }
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }

  public openCard(): void {
    this.itemIsVisible = true
  }

  public onAddedObjective(item: DocumentObjectiveProcess): void {
    const objectives = this.$refs.objectives as ObjectivesTab | undefined
    objectives?.addDocumentObjective(item)
    this.itemIsVisible = false
  }
}
