import _, { filter as _filter } from 'lodash'
import { Emitter, IViewButton } from './ViewButton'

export type ButtonFilter = (btn: IViewButton) => boolean

export interface IButtonSlot {
  buttons: Array<IViewButton>
  on(emitter: Emitter): void
}

export class ButtonSlot implements IButtonSlot {
  protected btns: IViewButton[]
  protected emitter: Emitter | undefined
  public constructor (btns: IViewButton[]) {
    this.btns = btns
  }

  public get buttons(): IViewButton[] {
    return _.filter(this.btns, btn => btn.visible)
  }

  public on(emitter: Emitter): void {
    this.emitter = (event, ...args) => emitter(event, args)
  }
}

export class FlexibleButtonSlot extends ButtonSlot {
  protected filter: ButtonFilter
  public constructor (btns: IViewButton[], filter: ButtonFilter) {
    super(btns)
    this.filter = filter
  }

  public setFilter(filter: ButtonFilter): void {
    this.filter = filter
  }

  public get buttons(): IViewButton[] {
    return _filter(this.btns, (btn) => btn.visible && this.filter(btn))
  }
}