export interface ISection {
  key: string
  title: string
}

export class Section implements ISection {
  public key: string
  public title: string
  public constructor(key: string, title: string) {
    this.key = key
    this.title = title
  }
}
