import Vue from 'vue'
import { IProcess } from '@/domain/IProcess'
import { IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { AsyncDelegateButton, Emitter, IViewButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { ProcessTitlesFactory } from './ProcessTitlesFactory'
import { LoadingButton } from '@/infrastucture/Buttons'

export class ProcessSlot implements IButtonSlot {
  private process: IProcess
  private titlesFactory: ProcessTitlesFactory
  private emitter: Emitter | null

  constructor($process: IProcess, $titlesFactory: ProcessTitlesFactory) {
    this.process = $process
    this.titlesFactory = $titlesFactory
    this.emitter = null
  }

  public get buttons(): IViewButton[] {
    // В hasAccess проверить также и статус отклонения
    if (this.process.isAny || !this.process.hasAccess()) return []
    const titles = this.titlesFactory.titles(this.process.current().toString())
    const btns = [
      new LoadingButton(new AsyncDelegateButton('revert', 'rollback', titles.revert, 'ant-office-btn-link', async () => { await this.process.revert() },
        () => !this.process.canBeRevert(), () => true)),
      new LoadingButton(new AsyncDelegateButton('next', 'logout', titles.next, 'ant-office-btn-link', async () => { await this.process.next() },
        () => false, () => true)),
      new LoadingButton(new AsyncDelegateButton('reject', 'close-circle', titles.reject, 'ant-office-btn-link', async () => { await this.process.reject() },
        () => !this.process.canBeReject(), () => true)),
      /* new LoadingButton(new AsyncDelegateButton('complete', 'check-circle', titles.complete, 'ant-office-btn-link', async () => { await this.process.completed(this.currentMember) },
        () => this.process.isCompleted(this.currentMember), () => true)), */
    ]
    Vue.observable(btns)
    return btns
  }

  public on(emitter: Emitter): void {
    this.emitter = emitter
  }
}