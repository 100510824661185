import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { ITableSource } from '@/global/table/models/TableCollection'
import { Collection } from '@/global/models/Collection'
import { FacilityData } from './FacilityData'

export class FacilityTableSource extends Collection<FacilityData> implements ITableSource<FacilityData> {
	public constructor(array: FacilityData[]) {
		super(array)
	}

  public columns(): ITableColumn<FacilityData>[] {
		return [
			new TableColumn('addButton', 'empty', 20, false, false, undefined, { title: 'addButton' }),
			new TableColumn<FacilityData>('Наименование', 'itemTitle', '250px', false, false, { customRender: 'toString' }),
			new TableColumn<FacilityData>('Буквенный код', 'codeLiteral', '250px', false, false, { customRender: 'toString' }),
      new TableColumn<FacilityData>('Цифровой код', 'digitalCode', '250px', false, false, { customRender: 'toString' }),
      new TableColumn<FacilityData>('Местонахождение', 'destination', '250px', false, false, { customRender: 'toString' }),
		]
	}

  public source(): FacilityData[] {
    return this.toArray()
  }
}