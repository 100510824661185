import { Location } from 'vue-router'
import { DecoratablePartner } from './DecoratablePartner'
import { Partner } from './Partner'

export class AlterablePartner extends DecoratablePartner {
  public constructor(origin: Partner) {
    super(origin)
    this.origin = origin
  }
  public asRoute(): Location {
    const route = this.origin.asRoute()
    if (route.params) route.params.mode = 'edit'
    return route
  }
}