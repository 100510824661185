
import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { Comment } from '../Comment'
import { Users } from '@/infrastucture/users/Users'
import { Document } from '@/domain/Document'
import { CommentData } from '../types'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { handleAxiosError } from '@/services/utils'
import { PostRequest } from '@/api/requests/PostRequest'

@Component
export default class CommentEditor extends Vue {
  @Prop({ type: Object, required: true }) public document!: Document
  @Prop({ type: Object, default: () => null }) public parent!: Comment | null
  private loading = false
  private note: string
	constructor() {
    super()
    this.note = ''
	}

  public get users(): Users {
    return this.$store.getters['manuals/users']
  }

  public async save(): Promise<void> {
    const comment = new Comment(0, this.users.currentUser, moment().toDate(), this.users.currentUser, this.note, this.document.notifiers())
    const data: CommentData = { current: comment, parent: this.parent, document: this.document }
    try {
      const request = new AuthorizationRequest(new PostRequest(new Request('/api/comments'), instanceToPlain(data)))
      const response = await request.response()
      const reponseComment = plainToInstance(Comment, response.data)
      this.$emit('comment-added', reponseComment)
      this.note = ''
    } catch (ex) {
      handleAxiosError(ex as Error)
    }
  }
}
