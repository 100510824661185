import Vue from 'vue'
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router'
import store from '@/store'
import { moduleNameRoute } from '@/router/modules'
import { ModuleRouteVM } from '@/global/viewModels/ModuleRouteVM'
import { oauthFirstRedirect, oauthRedirect } from '@/oauth/models/hooks'
import Home from '../views/Home.vue'
import Login from '@/oauth/components/Login.vue'
import PartnersMain from '@/partners/components/PartnersMain.vue'
import ParterCard from '@/partners/components/ItemCard.vue'
import Settings from '@/settings/Settings.vue'
import Contracts from '@/modules/components/Contracts.vue'
import Bills from '@/modules/components/Bills.vue'
import Objectives from '@/modules/components/Objectives.vue'
import BillCard from '@/modules/cards/BillCard.vue'
import ContractCard from '@/modules/cards/ContractCard.vue'
import ObjectiveCardWrapper from '@/modules/components/ObjectiveCardWrapper.vue'
import IncomingDocuments from '@/modules/components/IncomingDocuments.vue'
import IncomingDocumentCard from '@/modules/cards/IncomingDocumentCard.vue'
import OutgoingDocuments from '@/modules/components/OutgoingDocuments.vue'
import OutgoingDocumentCard from '@/modules/cards/OutgoingDocumentCard.vue'

Vue.use(VueRouter)

const enterDefault: NavigationGuard = (to, from, next) => {
  if (oauthRedirect(to, from, next)) return
  next()
}
const homeEnter: NavigationGuard = (to, from, next) => {
  if (oauthFirstRedirect(to, from, next)) return
  next()
  if (to.name === 'home') next({ name: 'objectives', params: { view: 'table' } })
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: homeEnter,
    children: [
      {
        path: '/incomingDocuments',
        name: 'incomingDocuments',
        component: IncomingDocuments,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('incomingDocuments')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
        children: [
          {
            path: '/incomingDocuments/detail/:mode/:itemId',
            name: 'IncomingDocumentDetail',
            component: IncomingDocumentCard,
            beforeEnter: enterDefault,
          },
        ],
      },
      {
        path: '/outgoingDocuments',
        name: 'outgoingDocuments',
        component: OutgoingDocuments,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('outgoingDocuments')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
        children: [
          {
            path: '/outgoingDocuments/detail/:mode/:itemId',
            name: 'OutgoingDocumentDetail',
            component: OutgoingDocumentCard,
            beforeEnter: enterDefault,
          },
        ],
      },
      {
        path: '/contracts',
        name: 'contracts',
        component: Contracts,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('contracts')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
        children: [
          {
            path: '/contracts/detail/:mode/:itemId',
            name: 'ContractDetail',
            component: ContractCard,
            beforeEnter: enterDefault,
          },
        ],
      },
      {
        path: '/bills',
        name: 'bills',
        component: Bills,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('bills')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
        children: [
          {
            path: '/bills/detail/:mode/:itemId',
            name: 'BillDetail',
            component: BillCard,
            beforeEnter: enterDefault,
          },
        ],
      },
      {
        path: '/objectives',
        name: 'objectives',
        component: Objectives,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('objectives')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
        children: [
          {
            path: '/objectives/detail/:itemId',
            name: 'ObjectiveDetail',
            component: ObjectiveCardWrapper,
            beforeEnter: enterDefault,
          },
        ],
      },
      {
        path: '/partners/:view',
        name: 'partners',
        component: PartnersMain,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('partners')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
        children: [
          {
            path: '/partners/detail/:mode/:itemId',
            name: 'Detail',
            component: ParterCard,
            beforeEnter: enterDefault,
          },
        ],
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        beforeEnter: (to, from, next) => {
          if (oauthRedirect(to, from, next)) return
          const viewModel = moduleNameRoute.get('settings')?.() || ModuleRouteVM.default()
          store.dispatch('moduleRoute/init', viewModel)
          next()
        },
      },
    ],
  },
  {
    path: '/oauth/',
    name: 'login',
    component: Login,
    props: true,
  },
  {
    path: '/about',
    name: 'about',
    beforeEnter: enterDefault,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
