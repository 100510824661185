
import { ReporterForm } from '@/partners/reporters/ReporterForm'
import { Reporters } from '@/partners/reporters/Reporters'
import { ReporterForms } from '@/partners/reporters/RepoterForms'
import { TableReporter } from '@/partners/reporters/TableReporter'
import { RecordPartner } from '@/partners/models/RecordPartner'
import { UpdatablePartner } from '@/partners/models/UpdatablePartner'
import { handleAxiosError } from '@/services/utils'
import { Reporter } from '@/partners/reporters/Reporter'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CardTable from './CardTable.vue'

@Component({
  components: {
    CardTable,
  },
})
export default class ReportersTab extends Vue {
  @Prop({ type: Object, required: true }) public item!: RecordPartner
  protected forms: ReporterForms
  private loading = false
  public constructor() {
    super()
    const forms = this.tableReporters.select((reporter) => new ReporterForm(reporter.asForm(), this.repoters, this.item))
    this.forms = new ReporterForms(forms.toArray())
  }

  public get repoters(): Reporters {
    return this.$store.getters['manuals/reporters']
  }

  public get partnerReporters(): Reporters {
    const reporters = this.repoters.where((reporter) => this.item.hasReporter(reporter))
    return new Reporters(reporters.toArray())
  }

  public get tableReporters(): Reporters {
    const reporters = this.partnerReporters.select((reporter) => new TableReporter(reporter))
    return new Reporters(reporters.toArray())
  }

  public async save(form: ReporterForm): Promise<void> {
    try {
      this.loading = true
      const reporter = await form.save()
      const partnerReporters = new Reporters(this.partnerReporters.toArray())
      form.cancel()
      if (!partnerReporters.hasById(reporter)) {
        partnerReporters.push(reporter)
        this.forms.remove(this.forms.count - 1)
        this.forms.push(new ReporterForm(reporter.asForm(), this.repoters, this.item))
      }
      const partner = new UpdatablePartner(this.item, partnerReporters)
      this.$emit('updated', partner)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }

  public push(): void {
    if (this.forms.count > 0 && this.forms.at(this.forms.count - 1).isAny) return
    this.forms.push(new ReporterForm(Reporter.any().asForm(), this.repoters, this.item, true))
  }

  public edit(form: ReporterForm): void {
    form.edit()
  }

  public cancel(form: ReporterForm): void {
    if (form.isAny) this.forms.remove(form, (target, source) => target.equals(source))
    else {
      const reporter = this.tableReporters.byId(form.key)
      if (reporter) this.forms.update(new ReporterForm(reporter.asForm(), this.repoters, this.item), (target, source) => target.equals(source))
      form.cancel()
    }
  }

  public async remove(form: ReporterForm): Promise<void> {
    try {
      this.loading = true
      await form.remove()
      const partnerReporters = new Reporters(this.partnerReporters.toArray())
      const partner = new UpdatablePartner(this.item, partnerReporters)
      this.$emit('updated', partner)
      this.forms.remove(form, (target, source) => target.equals(source))
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }    
  }
}
