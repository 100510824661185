import { Type } from 'class-transformer'
import { Stage } from './Stage'

export class WorkflowStageType extends Stage {
  constructor()
  constructor(other: Stage, backStage: Stage)
  constructor(other?: Stage, backStage?: Stage) {
    super(other ?? new Stage())
    this.backStage = backStage ?? null
  }

  @Type(() => Stage)
  public backStage: Stage | null

  public get canBeReverted(): boolean {
    return this.backStage != null
  }
}
