
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ViewMenu extends Vue {
  @Prop({ type: String, default: 'left' }) public value!: string
  public get routerNavClass(): string {
    return `active-${this.currentView}`
  }
  public get currentView(): string {
    return this.value
  }

  public set currentView(value: string) {
    this.$emit('input', value)
  }
}
