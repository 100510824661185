
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommentTreeNode } from '../CommentTreeNode'
import { Comment as CommentEntity } from '../Comment'
import CommentEditor from '@/infrastucture/components/CommentEditor.vue'
import { Document } from '@/domain/Document'
import { handleAxiosError } from '@/services/utils'

@Component({
  components: {
    CommentEditor,
    CommentSelf: () => import('@/infrastucture/components/Comment.vue')
  },
})
export default class Comment extends Vue {
  @Prop({ type: Object, required: true }) public document!: Document
  @Prop({ type: Object, required: true }) public node!: CommentTreeNode
  @Prop({ type: Boolean, default: false }) public hasShift!: boolean
  private showEditor = false
  private showChildren = false
  private loading = false
	constructor() {
    super()
	}

  public get comment(): CommentEntity {
    return this.node.entity
  }

  public swithShowChildren(): void {
    this.showChildren = !this.showChildren
  }

  public onCommentAddedFromChild(item: CommentEntity, node: CommentTreeNode): void {
    this.$emit('comment-added', item, node)
  }

  public onCommentAdded(item: CommentEntity): void {
    this.$emit('comment-added', item, this.node)
    this.showEditor = false
  }

  public async read(): Promise<void> {
    try {
      this.loading = true
      await this.comment.read()
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }
}
