
import { Component, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import Table from '@/global/table/components/Table.vue'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { Manuals } from '@/store/models/Manuals'
import { handleAxiosError } from '@/services/utils'
import ModuleWithRoute from '@/infrastucture/components/ModuleWithRoute'
import { Section } from '@/global/buttonPanel/viewModels/Section'
import { EntityCollection } from '@/global/models/EntityCollection'
import { IncomingDocumentTableSource } from '../IncomingDocumentTableSource'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { ButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Dictionary } from 'vue-router/types/router'
import { Request } from '@/api/requests/Request'
import { plainToClass } from 'class-transformer'
import { IncomingDocument } from '@/domain/IncomingDocument'
import { IncomingDocumentWorkflowProcess } from '@/domain/WorkflowProcess'
import { IncomingDocumentData } from '../IncomingDocumentData'
import { Entity } from '@/global/models/Entity'
@Component({
  components: {
    Table,
    ButtonPanel,
    ButtonSection,
    ItemDrawer,
  },
  
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
    const self = this as IncomingDocuments
    self.beforeRouteUpdate(to, from, next)
  },
})

export default class IncomingDocuments extends ModuleWithRoute<IncomingDocumentWorkflowProcess, IncomingDocumentData> {
  protected sections: Section[]
  public items: EntityCollection<IncomingDocumentWorkflowProcess>
  public dataCollection: IncomingDocumentTableSource
  protected buttonPanel: ButtonPanelSource
  protected creation: ButtonSlot
  protected loading = false
  protected selectedItem: IncomingDocumentWorkflowProcess | null
  public constructor() {
    super('incomingDocuments', 'IncomingDocumentDetail')
    this.items = new EntityCollection()
    this.dataCollection = new IncomingDocumentTableSource([])
    this.sections = [
      new Section('creation', 'Создание')
    ]
    this.buttonPanel = new ButtonPanelSource(this.sections)
    this.creation = new ButtonSlot([
      new ViewDelegateButton('new-item', 'plus-circle', 'Новый документ', 'ant-office-btn-link', () => this.openCard(-1)),
    ])
    this.selectedItem = null
  }

  public get isInit(): boolean {
    return this.items.count > 0
  }

  @Watch('isInit', { immediate: true })
  public async onChange(): Promise<void> {
    if (this.isInit) return
    await this.loadCollection()
    await super.onChange()
  }

  public async loadCollection(): Promise<void> {
    try {
      this.loading = true
      const manuals = new Manuals(this.$store)
      await manuals.load()
      const request = new AuthorizationRequest(new Request<Dictionary<unknown>[]>('api/incomingDocuments'))
      const response = await request.response()
      const items = _.map(response.data, (data) => plainToClass(IncomingDocumentWorkflowProcess, data))
      this.items = new EntityCollection(items)
      this.dataCollection = new IncomingDocumentTableSource(this.items.select(item => item.toData()).toArray())
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }

  public anyItem(): IncomingDocumentWorkflowProcess {
    return IncomingDocumentWorkflowProcess.create()
  }

  public openForm(item: Entity): void {
    this.openCard(item.id)
  }

  public openCard(key: number): void {
    super.openCard(key)
  }

  public onUpdated(item: IncomingDocument): void {
    if (!this.items) return
    const oldItem = this.items.byId(item.id)
    if (oldItem) {
      const newItem = new IncomingDocumentWorkflowProcess(oldItem, item)
      this.items.update(newItem)
      this.dataCollection.update(newItem.toData(), (target, source) => target.id === source.id)
      this.selectedItem = newItem
    }
    if (!this.viewOnly) this.$router.back()
  }

  public onAdded(item: IncomingDocumentWorkflowProcess): void {
    if (!this.items) return
    this.items.insert(item, 0)
    this.dataCollection.insert(item.toData(), 0)
    this.selectedItem = item
    this.$router.replace(this.viewRoute(this.selectedItem.id))
  }
}
