import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Person } from '@/infrastucture/users/Person'
import store from '@/store'
import { Users } from '@/infrastucture/users/Users'
import { Type } from 'class-transformer'
import _ from 'lodash'
import { Member } from './Member'
import { Registration } from './Registration'
import { Stage } from './Stage'

export class Document extends ValueEntity {
  @Type(() => Registration)
  protected registration: Registration
  @Type(() => ValueEntity)
  protected status: ValueEntity
  @Type(() => ValueEntity)
  protected workflowType: ValueEntity
  protected storage: string
  @Type(() => Member)
  protected members: Member[]
  constructor()
  constructor(other: Document)
  constructor(entity: ValueEntity, registration: Registration, status: ValueEntity, workflowType: ValueEntity, storage: string, members: Member[])
  constructor(entity?: ValueEntity, registration?: Registration, status?: ValueEntity, workflowType?: ValueEntity, storage?: string, members?: Member[]) {
    const origin = entity as Document | undefined
    super(origin ?? new ValueEntity())
    this.registration = origin?.registration ?? registration ?? new Registration()
    this.workflowType = origin?.workflowType ?? workflowType ?? new ValueEntity()
    this.status = origin?.status ?? status ?? new ValueEntity()
    this.storage = origin?.storage ?? storage ?? ''
    this.members = origin?.members ?? members ?? []
  }

  public updated(title: string, registration: Registration, storage: string, members: Member[]): Document {
    const document = new Document(new ValueEntity(this.id, title), registration, this.status, this.workflowType, storage, members)
    return document
  }

  public isRejected(): boolean {
    return this.status.toString() === 'Отклонён'
  }

  public member(type: string): Member {
    return _.find(this.members, member => member.equals(type)) ?? defaultMember
  }

  public membersByCurrentUser(): Member[] {
    const users = store.getters['manuals/users'] as Users
    const user = users.currentUser
    return _.filter(this.members, member => member.equalsById(user))
  }

  public membersByType(stageType: Stage): Member[]
  public membersByType(memberType: string): Member[]
  public membersByType(arg: string | Stage): Member[] {
    // eslint-disable-next-line
    return _.filter(this.members, member => member.equals(arg as any))
  }

  public notifiers(): Person[] {
    return _.uniqBy(this.members, 'id')
  }

  public changeStatus(status: ValueEntity): void {
    this.status = status
  }
}

const defaultMember = new Member()