import { IEquatable } from '@/infrastucture/IEquatable'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { first } from '@/services/utils'
import { Type } from 'class-transformer'
import _ from 'lodash'
import { IProcessTemplate } from './IProcessTemplate'
import { Stage } from './Stage'
import { WorkflowStageType } from './WorkflowStageType'

export class WorkflowTemplate implements IProcessTemplate {
  @Type(() => WorkflowStageType)
  private stages: WorkflowStageType[]
  constructor(stages: WorkflowStageType[] = []) {
    this.stages = _.orderBy(stages, stage => stage.id)
  }
  public next(current: ValueEntity): Stage {
    const next = first(_.drop(_.dropWhile(this.stages, stage => !stage.equals(current)), 1))
    return next
  }

  public previous(current: ValueEntity): Stage {
    const stageType = first(this.stages, stage => stage.id === current.id)
    if (!stageType.canBeReverted || !stageType.backStage) {
      throw new Error("Невозможно отправить на доработку на данном этапе")
    }
    return stageType.backStage
  }

  public stageType(id: number): Stage
  public stageType(stage: IEquatable<ValueEntity>): Stage
  public stageType(title: string): Stage
  public stageType(arg: string | number | IEquatable<ValueEntity>): Stage {
    if (typeof arg === 'string') return first(this.stages, stage => stage.toString() === arg)
    if (typeof arg === 'number') return first(this.stages, stage => stage.id === arg)
    return first(this.stages, stage => arg.equals(stage))
  }

  public canBeReverted(title: string): boolean
  public canBeReverted(stage: IEquatable<ValueEntity>): boolean
  public canBeReverted(arg: string | IEquatable<ValueEntity>): boolean {
    const stage = (typeof arg === 'string')
      ? first(this.stages, stage => stage.toString() === arg)
      : first(this.stages, stage => arg.equals(stage))
    return stage.canBeReverted
  }

  public canBeNext(title: string): boolean
  public canBeNext(stage: IEquatable<ValueEntity>): boolean
  public canBeNext(stage: string | IEquatable<ValueEntity>): boolean {
    // eslint-disable-next-line
    const stageType = this.stageType(stage as any)
    const i = _.findIndex(this.stages, item => item.equalsById(stageType))
    return i > -1 && i < this.stages.length - 1
  }
}