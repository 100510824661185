import { Employee } from '@/infrastucture/users/Employee'
import { ITransferable } from '@/global/models/AsyncCollection'
import { Entity } from '@/global/models/Entity'
import { Field, IField } from '@/global/models/Field'
import { Fieldset } from '@/global/models/Fieldset'
import { RemoteRecord } from '@/global/models/RemoteRecord'
import { ISerilazable } from '@/global/models/Serilazable'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Person } from '@/infrastucture/users/Person'
import { ReporterEntity } from '../models/PartnerJson'

export class Reporter extends Employee implements ITransferable, ISerilazable<ReporterEntity> {
  protected hasSigningAuthority: boolean
  public constructor()
  public constructor(other: Reporter)
  public constructor(other: Employee, hasSigningAuthority: boolean)
  public constructor(...args: unknown[]) {
    const other = args[0] as Reporter | undefined
    const hasSigningAuthority = other?.hasSigningAuthority ?? args[1] as boolean ?? false
    super(other ?? new Employee())
    this.hasSigningAuthority = hasSigningAuthority
  }

  public static any(): Reporter {
    const person = new Person(0, '', '', '')
    return new Reporter(new Employee(person, '', '', ''), false)
  }

  public static createFromData(entity: ReporterEntity): Reporter {
    const person = new Person(entity.id, entity.firstName, entity.lastName, entity.middleName)
    return new Reporter(new Employee(person, entity.email, entity.workPhone, entity.jobTitle), entity.hasSigningAuthority)
  }

  public asFields(): IField<string>[] {
    return [
      new Field(this.lastName, 'lastName', { label: 'Фамилия' }, 'FLInput', ['required']),
      new Field(this.firstName, 'firstName', { label: 'Имя' }, 'FLInput', ['required']),
      new Field(this.middleName, 'middleName', { label: 'Отчество' }, 'FLInput'),
      new Field(this.jobTitle, 'jobTitle', { label: 'Должность' }, 'FLInput'),
      new Field(this.email, 'email', { label: 'Email' }, 'FLInput'),
      new Field(this.phone, 'phone', { label: 'Телефон' }, 'FLInput', ['required']),
    ]
  }

  public asForm(): Fieldset {
    return new Fieldset(this.id, this.asFields(), [])
  }

  public toData(): ValueEntity {
    return new ValueEntity(new Entity(this.id), this.toString())
  }

  public entity(): ReporterEntity {
    return {
      id: this.id,
      hasSigningAuthority: this.hasSigningAuthority,
      email: this.email,
      workPhone: this.phone,
      jobTitle: this.jobTitle,
      firstName: this.firstName,
      lastName: this.lastName,
      middleName: this.middleName,
    }
  }

  public async transfer(): Promise<void> {
    const updatable = new RemoteRecord(this, '/api/reporters')
    await updatable.transfer()
  }
}
