import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { moduleOptions as moduleRoute } from './moduleRoute'
import { oauthOptions as oauth } from './oauth'
import { manuals } from './manuals'

Vue.use(Vuex)

export const oauthModule = oauth

const options: StoreOptions<RootState> = {
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    moduleRoute,
    manuals,
    oauth,
  }
}


export default new Vuex.Store(options)
