import { Validator } from 'vee-validate'
import { IFieldSet } from './IFieldSet'
import { RecordForm } from './RecordForm'
import { EntityData, IRemoteRecord } from './RemoteRecord'

export abstract class TableRecordForm<TEntity extends IRemoteRecord<TData> & IFieldSet, TData extends EntityData> extends RecordForm<TEntity, TData> {
  private _hasEdit: boolean
  public constructor(other: TableRecordForm<TEntity, TData>)
  public constructor(record: TEntity, validator: Validator, uri: string)
  public constructor(record: TEntity, validator: Validator, uri: string, hasEdit: boolean)
  public constructor(...args: unknown[]) {
    const other = args[0] as TableRecordForm<TEntity, TData>
    const resultEntity = other.record ?? args[0] as TEntity
    const resultValidator = other.validator ?? args[1] as Validator
    const resutlUri = other.uri ?? args[2] as string
    const hasEdit = other._hasEdit ?? args[3] as boolean ?? false
    super(resultEntity, resultValidator, resutlUri)
    this.validator = resultValidator
    this._hasEdit = hasEdit
  }

  public get hasEdit(): boolean {
    return this._hasEdit
  }

  public edit(): void {
    this._hasEdit = true
  }

  public cancel(): void {
    this._hasEdit = false
  }
}
