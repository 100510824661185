import { join as _join } from 'lodash'
import { DynamicProps } from './types'

export interface IField<T> {
  content(): T
  update(content: T): void
  key: string
  props: DynamicProps<T> // TODO подумать
  component: string
  viewContent: T
  validation(): string
  equals(other: IField<T>): boolean
}
export interface IChoiceField<T> extends IField<T> {
  list(): T[] // МБ вместо листа использовать params/props
}

export class Field<T> implements IField<T> {
  private _content: T
  private _key: string
  private _props: DynamicProps<T>
  private componentName: string
  private validators: string[]
  public constructor(other: Field<T>)
  public constructor(content: T, key: string, props: DynamicProps<T>, component: string)
  public constructor(content: T, key: string, props: DynamicProps<T>, component: string, validators: string[])
  public constructor(...args: unknown[]) {
    const content = (args[0] as Field<T>)?._content || args[0] as T
    const key = (args[0] as Field<T>)?._key || args[1] as string
    const props = (args[0] as Field<T>)?._props || args[2] as DynamicProps<T>
    const component = (args[0] as Field<T>)?.componentName || args[3] as string
    const validators = (args[0] as Field<T>)?.validators || args[4] as string[] || []
    this._content = content
    this._key = key
    this.validators = validators
    this._props = props
    this.componentName = component
  }

  public get component(): string {
    return this.componentName
  }

  public validation(): string {
    const validation = _join(this.validators, '|') || ''
    return validation
  }

  public get key(): string {
    return this._key
  }

  public get props(): DynamicProps<T> {
    return this._props
  }

  public content(): T {
    return this._content
  }
 
  public update(content: T): void {
    this._content = content
  }

  public get viewContent(): T {
    return this.content()
  }

  public set viewContent(value: T) {
    this.update(value)
  }

  public toString(): string {
    return this.content() + ''
  }

  public equals(other: IField<T>): boolean {
    return this.key === other.key && this.content() === other.content()
  }
}

export class ChoiceField<T> extends Field<T> implements IChoiceField<T> {
  private collection: T[]
  public constructor(collection: T[], primary: Field<T>) {
    super(primary)
    this.collection = collection
  }

  public validation(): string {
    return ''
  }

  public list(): T[] {
    return this.collection
  }
}

export class AnyField extends Field<string> {
  public constructor() {
    super('', '', { label: '' }, 'FLInput')
  }
}