import { ISection, Section } from './Section'

export interface IButtonPanel {
  sections: ISection[]
}

export class ButtonPanel implements IButtonPanel {
  private _sections: ISection[]
  public constructor(sections: ISection[]) {
    this._sections = sections
  }

  public static create(): ButtonPanel {
    return new ButtonPanel([
      new Section('creation', 'Создание'),
      new Section('action', 'Быстрые действия'),
      new Section('workflow', 'Рабочий процесс'),
      new Section('stepper', 'Состояние'),
    ])
  }

  public static documentObjectiveCreate(): ButtonPanel {
    return new ButtonPanel([
      new Section('creation', 'Действия'),
      new Section('stepper', 'Состояние'),
    ])
  }

  public get sections(): ISection[] {
    return this._sections
  }
}