export type ScopedSlots = {
  customRender: string,
}

export type Slots = {
  title?: string
}

export type TableSorter<T> = (a: T, b: T) => number

export interface ITableColumn<T> {
  title?: string,
  dataIndex: string,
  width: string | number,
  fixed: string | boolean,
  slots?: Slots,
  scopedSlots?: ScopedSlots,
  sorter: TableSorter<T> | boolean,
}

export class TableColumn<T> implements ITableColumn<T> {
  protected _title: string
  public title: string | undefined
  public dataIndex
  public width
  public fixed
  public slots
  public scopedSlots
  public sorter
  public constructor(title: string, dataIndex: string, width: string | number, fixed: string | boolean, comparer: TableSorter<T> | boolean, scopedSlots?: ScopedSlots, slots?: Slots) {
    this._title = title
    this.dataIndex = dataIndex
    this.width = width
    this.fixed = fixed
    this.scopedSlots = scopedSlots
    this.sorter = comparer
    this.slots = slots
    this.slots?.title ? this.title = undefined : this.title = title
  }
}
