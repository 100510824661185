
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { Field } from '@/global/models/Field'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { handleAxiosError, itemById } from '@/services/utils'
import { Request } from '@/api/requests/Request'
import { PutRequest } from '@/api/requests/PutRequest'
import { Department } from '@/domain/Department'
import _ from 'lodash'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { User } from '@/infrastucture/users/User'
import { RegisterData, UserData, UserEntity, UserFields } from '@/infrastucture/users/UserData'
import { Person } from '@/infrastucture/users/Person'
import { Employee } from '@/infrastucture/users/Employee'
@Component({
  components: {
    FieldSet,
  },
})
export default class UserCard extends Vue {
  @Prop({ type: Object, required: true }) public item!: User
  private itemData!: UserData
  private loading = false
  private passwordField: Field<string>
  private confirmPasswordField: Field<string>
  private invalid: boolean
  public constructor() {
    super()
    this.itemData = this.item.toMedia()
    this.passwordField = new Field('', 'password', { label: 'Пароль', allowClear: true, secret: true }, 'FLInput', ['required'])
    this.confirmPasswordField = new Field('', 'confirmPassword', { label: 'Повторите пароль', allowClear: true, secret: true }, 'FLInput', ['required'])
    this.invalid = true
  }

  public get departments(): Department[] {
    return this.$store.getters['manuals/departments']
  }

  public get userDepartment(): ValueEntity {
    return _.find(this.departments, item => item.has(this.item)) || new ValueEntity()
  }

  public get fieldsMap(): UserFields {
    const map = {
      lastName: new Field(this.itemData.lastName, 'lastName', { label: 'Фамилия', allowClear: true }, 'FLInput', ['required']),
      firstName: new Field(this.itemData.firstName, 'firstName', { label: 'Имя', allowClear: true }, 'FLInput', ['required']),
      middleName: new Field(this.itemData.middleName, 'middleName', { label: 'Отчество', allowClear: true }, 'FLInput'),
      email: new Field(this.itemData.email, 'email', { label: 'Email', allowClear: true }, 'FLInput', ['required', 'email']),
      jobTitle: new Field(this.itemData.jobTitle, 'jobTitle', { label: 'Должность', allowClear: true }, 'FLInput'),
      department: new Field(this.userDepartment, 'department', { label: 'Отдел', allowClear: true, valueEntities: this.departments, labelInValue: true }, 'FLSelect', ['required']),
      workPhone: new Field(this.itemData.workPhone, 'workPhone', { label: 'Телефон', allowClear: true }, 'FLInput'),
    }
    return map
  }

  public get fieldsList(): Field<unknown>[] {
    const list = Object.values(this.fieldsMap)
    if (this.item.isAny) list.push(this.passwordField, this.confirmPasswordField)
    return list
  }

  @Watch('item', { deep: false })
  protected onItemChanged(): void {
    this.itemData = this.item.toMedia()
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
      if (this.passwordField.content() !== this.confirmPasswordField.content()) throw new Error('Пароли не совпадают')
      const item = new User(new Employee(new Person(this.itemData.id, this.fieldsMap.firstName.content(), this.fieldsMap.lastName.content(), this.fieldsMap.middleName.content()),
        this.fieldsMap.email.content(), this.fieldsMap.workPhone.content(), this.fieldsMap.jobTitle.content()),
        this.fieldsMap.email.content())
      const department = itemById(this.departments, this.fieldsMap.department.content().id)
      // eslint-disable-next-line
      let request: Request<unknown>
      if (item.isAny) {
        const data: RegisterData = { user: instanceToPlain(item), department: instanceToPlain(department), password: this.passwordField.content() }
        request = new AuthorizationRequest(new PostRequest(new Request('/administration/users'), data))
        const response = await request.response()
        const responseItem = plainToInstance(User, response.data)
        await this.$store.dispatch('manuals/reload', { collection: 'departments', uri: 'administration/departments', deserializationType: Department })
        this.$emit('added', responseItem)
      } else {
        const data: UserEntity = { user: instanceToPlain(item), department: instanceToPlain(department) }
        request = new AuthorizationRequest(new PutRequest(new Request('/administration/users'), data))
        await request.response()
        await this.$store.dispatch('manuals/reload', { collection: 'departments', uri: 'administration/departments', deserializationType: Department })
        this.$emit('updated', item)
      }
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }
}
