
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IButtonPanel } from '../viewModels/ButtonPanel'
import { ISection } from '../viewModels/Section'
@Component
export default class ButtonPanel extends Vue {
  @Prop({ type: Object, required: true }) protected viewModel!: IButtonPanel
  public constructor() {
    super()
  }

  public get sections(): ISection[] {
    return this.viewModel.sections
  }
}
