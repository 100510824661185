import { map as _map } from 'lodash'
import { Address } from '@/domain/addresses/Address'
import { Collection, ICollection } from '@/global/models/Collection'
import { Entity } from '@/global/models/Entity'
import { Activity } from './Activity'
import { Contacts } from '../contacts/Contacts'
import { PartnerEntity } from './PartnerJson'
import { Registration } from './Registration'
import { Field, IField } from '@/global/models/Field'
import { Location } from 'vue-router'
import { Fieldset } from '@/global/models/Fieldset'
import { Reporter } from '../reporters/Reporter'
import { ITransferable } from '@/global/models/AsyncCollection'
import { PutRequest } from '@/api/requests/PutRequest'
import { Request } from '@/api/requests/Request'
import { ISerilazable } from '@/global/models/Serilazable'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { ValueEntity } from '@/infrastucture/ValueEntity'

export class Partner extends Entity implements ITransferable, ISerilazable<PartnerEntity> {
  protected title: string
  protected registration: Registration
  protected contacts: Contacts
  protected activity: Activity
  protected addresses: ICollection<Address>
  public constructor()
  public constructor (other: Partner)
  public constructor (id: number, title: string, registration: Registration, contacts: Contacts, activity: Activity, addresses: ICollection<Address>)
  public constructor (...args: unknown[]) {
    const partner = args[0] as Partner | undefined
    const id = (partner?.id != undefined ? partner.id : args[0] as number) ?? 0
    const title = partner?.title || args[1] as string || ''
    const registration = partner?.registration ?? args[2] as Registration ?? new Registration()
    const contacts = partner?.contacts ?? args[3] as Contacts ?? Contacts.any()
    const activity = partner?.activity ?? args[4] as Activity ?? Activity.any()
    const addresses = partner?.addresses ?? args[5] as ICollection<Address> ?? new Collection()
    super(id)
    this.title = title
    this.registration = registration
    this.contacts = contacts
    this.activity = activity
    this.addresses = addresses
  }

  public static any(): Partner {
    const registration = Registration.any()
    const contacts = Contacts.any()
    const activity = Activity.any()
    return new Partner(0, '', registration, contacts, activity, new Collection<Address>([]))
  }

  public static createFromData(entity: PartnerEntity): Partner {
    const addresses = _map(entity.address, (addressEntity) => Address.createFromData(addressEntity))
    const registration = Registration.createFromData(entity.registration)
    const contacts = Contacts.createFromData(entity.contacts)
    const activity = Activity.createFromData(entity.activityInfo)
    return new Partner(entity.id, entity.title, registration, contacts, activity, new Collection(addresses))
  }

  public toString(): string {
    return this.title
  }

  public asFields(): IField<string>[] {
    return [new Field(this.title, 'itemTitle', { label: 'Наименование'}, 'FLInput', ['required'])]
  }

  public asForm(): Fieldset {
    const myFields = this.asFields()
    const registrationFields = this.registration.asFields()
    const dateRegistrationFields = this.registration.asDateFields()
    const activityFields = this.activity.asFields()
    const contactFields = this.contacts.asFields()
    return new Fieldset(this.id, [...myFields, ...registrationFields, ...activityFields, ...contactFields], dateRegistrationFields)
  }

  public asRoute(): Location {
    return { name: 'Detail', params: { mode: 'view', itemId: this.id + '' } }
  }

  public hasReporter(id: number): boolean
  public hasReporter(reporter: Reporter): boolean
  public hasReporter(reporter: Reporter | number): boolean {
    // eslint-disable-next-line
    return this.contacts.hasReporter(reporter as any)
  }

  public hasAddress(address: Address): boolean {
    return this.addresses.any((currentAddress) => currentAddress.equalsById(address))
  }

  public entity(): PartnerEntity {
    return {
      id: this.id,
      title: this.title,
      registration: this.registration.entity(),
      contacts: this.contacts.entity(),
      activityInfo: this.activity.entity(),
      address: [],
    }
  }

  public toData(): ValueEntity {
    return new ValueEntity(new Entity(this.id), this.title)
  }

  public async transfer(): Promise<void> {
    const request = new AuthorizationRequest(new PutRequest<void, PartnerEntity>(new Request('/api/partners'), this.entity()))
    await request.response()
  }
}