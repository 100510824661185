export class Summ {
  constructor()
  constructor(amount: number, budgetCode: string, currency: string, vatAmount: number)
  constructor(amount?: number, budgetCode?: string, currency?: string, vatAmount?: number) {
    this.amount = amount ?? 0
    this.budgetCode = budgetCode ?? ""
    this.currency = currency ?? ""
    this.vatAmount = vatAmount ?? 0
  }
  public amount: number
  public budgetCode: string
  public currency: string
  public vatAmount: number
}