import { ValueEntity } from '@/infrastucture/ValueEntity'
import { DocumentDescription } from './DocumentDescription'
import { Parties } from './Parties'
import { Document } from './Document'
import { IncomingDocumentData } from '@/modules/IncomingDocumentData'
import moment from 'moment'
import { Transform, Type } from 'class-transformer'
import store from '@/store'
import { Users } from '@/infrastucture/users/Users'
import { Member } from './Member'
import { valueEntityByTitle } from '@/store/models/Manuals'
import { Registration } from './Registration'
import { MemberTypeValue, Status, WorkflowTypeValue } from '@/infrastucture/LookupTypes'
import _ from 'lodash'
import { dateToString, stringToDate } from '@/services/utils'
import { partnerByReporter } from '@/services/cardUtils'

export class IncomingDocument extends Document {
	@Type(() => ValueEntity)
	private deliveryType: ValueEntity
	@Type(() => ValueEntity)
	private documentType: ValueEntity
	@Type(() => DocumentDescription)
	private description: DocumentDescription
	private note: string
	@Transform(stringToDate, { toClassOnly: true })
  @Transform(dateToString, { toPlainOnly: true })
	private outgoingDate: Date
	private outgoingNumber: string
	@Type(() => Parties)
	private parties: Parties
	constructor()
	constructor(other: IncomingDocument)
	constructor(origin: Document, deliveryType: ValueEntity, documentType: ValueEntity, description: DocumentDescription, note: string,
		outgoingDate: Date, outgoingNumber: string, parties: Parties)
	constructor(origin?: Document, deliveryType?: ValueEntity, documentType?: ValueEntity, description?: DocumentDescription, note?: string,
		outgoingDate?: Date, outgoingNumber?: string, parties?: Parties) {
		const other = origin as IncomingDocument | undefined
		super(other ?? new Document())
		this.deliveryType = other?.deliveryType ?? deliveryType ?? new ValueEntity()
		this.documentType = other?.documentType ?? documentType ?? new ValueEntity()
		this.description = other?.description ?? description ?? new DocumentDescription()
		this.note = other?.note ?? note ?? ''
		this.outgoingDate = other?.outgoingDate ?? outgoingDate ?? moment(0).toDate()
		this.outgoingNumber = other?.outgoingNumber ?? outgoingNumber ?? ''
		this.parties = other?.parties ?? parties ?? new Parties()
	}

	public static create(): IncomingDocument {
		const status = valueEntityByTitle('statusList', Status.staging)
		const users: Users = store.getters['manuals/users']
		const members = [new Member(users.currentUser, valueEntityByTitle('documentMembers', MemberTypeValue.initiator))]
		const document = new Document(new ValueEntity(), new Registration(), status, valueEntityByTitle('workflowTypes', WorkflowTypeValue.incomingDocument), '', members)
		const item = new IncomingDocument(document, new ValueEntity(), new ValueEntity(), new DocumentDescription(), '', moment(0).toDate(), '', new Parties())
		return item
	}

	public toData(): IncomingDocumentData {
		const partner = partnerByReporter(this.parties.reporter.id)
		const title = this.isAny || !partner ? this.title : `${this.registration.registrationNumber} ${partner.toString()}`
		return {
			id: this.id,
			itemTitle: title,
			deliveryType: this.deliveryType,
			documentType: this.documentType,
			registrationDate: moment(this.registration.registrationDate),
			registrationNumber: this.registration.registrationNumber,
			note: this.note,
			outgoingDate: moment(this.outgoingDate).isSameOrBefore(moment(0)) ? null : moment(this.outgoingDate),
			outgoingNumber: this.outgoingNumber,
			copiesCount: this.description.copiesCount,
			sheetCount: this.description.sheetCount,
			supplementsCount: this.description.supplementsCount,
			description: this.description.description,
			facility: this.parties.facility,
			reporter: this.parties.reporter.toData(),
			storage: this.storage,
			initiator: this.member(MemberTypeValue.initiator).toData(),
			recievers: _.map(this.membersByType(MemberTypeValue.receiver), member => member.toData()),
			assignedTo: _.map(this.membersByType(MemberTypeValue.executor), member => member.toData()),
		}
	}
}