
import { Component, Vue } from 'vue-property-decorator'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import Main from '@/views/Main.vue'
import TopMenu from './TopMenu.vue'
import ViewMenu from '@/views/ViewMenu.vue'
import { IModuleRouteVM, ModuleRouteVM } from '@/global/viewModels/ModuleRouteVM'
import RubIcon from '@/assets/icons/RubIcon.vue'
import { AccessEntity } from '@/oauth/models/types'
import { AuthorizationAccess } from '@/oauth/models/AuthorizationAccess'
import { moduleNameRoute } from '@/router/modules'
import { AxiosError } from 'axios'
@Component({
  components: {
    Main,
    TopMenu,
    ViewMenu,
    RubIcon,
  },
  beforeMount() {
    const self = this as Home
    self.validateClaims()
  }
})
export default class Home extends Vue {
  private loading = false
  private customIcons = {
    RubIcon
  }

  private routeTitle = {
    incomingDocuments: 'Входящая',
    outgoingDocuments: 'Исходящая',
  }

  public get routeMeta(): IModuleRouteVM {
    const viewModel = this.$store.getters['moduleRoute/viewModel']
    return viewModel
  }

  /* public get currentView(): string {
    const routeView = this.$route.params?.view
    return this.routeMeta.getView(routeView)
  }

  public set currentView(value: string) {
    this.routeMeta.changeView(value)
  } */

  public get currentModule(): string {
    return this.$route.name || ''
  }

  public set currentModule(module: string) {
    this.selectModule(module)
  }

  public selectModule(module: string): void {
    const routeVM = moduleNameRoute.get(module)?.() || ModuleRouteVM.default()
    this.$router.push(routeVM.asRoute())
  }

  public async validateClaims(): Promise<void> {
    this.loading = true
    try {
      const access = this.$store.getters['oauth/access'] as AuthorizationAccess
      const request = new AuthorizationRequest(new PostRequest(new Request<AccessEntity>('/administration/oclaims/ValidateClaims'), access.entity()))
      const response = await request.response()
      this.$store.dispatch('oauth/setAccess', AuthorizationAccess.createFromData(response.data))
    } catch (error) {
      console.warn(error)
      const axiosEx = error as AxiosError
      if (axiosEx.response?.status === 401) this.$router.replace({ path: '/oauth', query: { returnUrl: this.$route.fullPath } })
    } finally {
      this.loading = false
    }
  }
}
