
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Field, IField } from '@/global/models/Field'
import SettingsCard from './SettingsCard.vue'
import { User } from '@/infrastucture/users/User'
import { UserAccess } from '@/infrastucture/UserAccess'
import _, { Dictionary } from 'lodash'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'

@Component({
  components: {
    SettingsCard,
  },
})
export default class AccessCard extends Vue {
  @Prop({ type: Object, required: true }) public item!: User
  private deserializeType = UserAccess
  private uri = '/administration/oclaims/ModuleClaims'
  private userAccess: UserAccess | null = null
  private accessValues = ['Нет', 'Стандарт', 'Полный']
  private moduleTitle: Dictionary<string> = {
    Contracts: 'Договоры',
    Documents: 'Корреспонденция',
    Bills: 'Счета',
    Tasks: 'Задачи',
  }
  private fieldsMap: Dictionary<Field<string>> = {}

  public get fieldsList(): IField<unknown>[] {
    const list = Object.values(this.fieldsMap)
    return list
  }

  @Watch('item', { immediate: true, deep: false })
  public async onChange(): Promise<void> {
    const request = new AuthorizationRequest(new Request<Dictionary<string>>(`/administration/oclaims/claims/${this.item.id}`))
    const response = await request.response()
    this.userAccess = new UserAccess(response.data, this.item)
    this.fieldsMap = _.mapValues(this.userAccess.claims,
      (value, key) => new Field(value, key, { label: this.moduleTitle[key], labels: this.accessValues, labelInValue: true }, 'FLSelectExtra', ['required']))
  }

  public createItem(): UserAccess {
    const dict = _.mapValues(this.fieldsMap, fieldValue => fieldValue.content())
    return new UserAccess(dict, this.item)
  }
}
