import { Field, IField } from '@/global/models/Field'
import { ISerilazable } from '@/global/models/Serilazable'
import { momentOrDefault } from '@/services/utils'
import moment, { Moment } from 'moment'
import { RegistrationEntity } from './PartnerJson'

export class Registration implements ISerilazable<RegistrationEntity> {
  protected inn: string
  protected registrationDate: Moment
  protected kpp: string
  protected ogrn: string
  private codeLiteral: string
  private fullTitle: string
  public constructor()
  public constructor(other: Registration)
  public constructor(inn: string, registrationDate: Moment, kpp: string, ogrn: string, codeLiteral: string, fullTitle: string)
  public constructor(...args: unknown[]) {
    const registration = args[0] as Registration | undefined
    const inn = registration?.inn ?? args[0] as string ?? ''
    const registrationDate = registration?.registrationDate ?? momentOrDefault((args[1] as Moment))
    const kpp = registration?.kpp || args[2] as string || ''
    const ogrn = registration?.ogrn || args[3] as string || ''
    const codeLiteral = registration?.codeLiteral || args[4] as string || ''
    const fullTitle = registration?.fullTitle || args[5] as string || ''
    this.inn = inn
    this.registrationDate = registrationDate
    this.kpp = kpp
    this.ogrn = ogrn
    this.codeLiteral = codeLiteral
    this.fullTitle = fullTitle
  }

  public static any(): Registration {
    return new Registration('', moment.utc(null), '', '', '', '')
  }

  public static createFromData(entity: RegistrationEntity): Registration {
    return new Registration(entity.inn, moment.utc(entity.registrationDate), entity.kpp, entity.ogrn, entity.codeLiteral, entity.fullTitle)
  }

  public entity(): RegistrationEntity {
    const registrationDateISO = this.registrationDate.toISOString() || moment(0).toISOString()
    return {
      inn: this.inn,
      registrationDate: registrationDateISO,
      kpp: this.kpp,
      ogrn: this.ogrn,
      codeLiteral: this.codeLiteral,
      fullTitle: this.fullTitle,
      registrationSertificate: { title: '', registrationDate: registrationDateISO, issuingAuthority: '' },
    }
  }

  public asFields(): IField<string>[] {
    return [
      new Field(this.inn, 'inn', { label: 'ИНН' }, 'FLInput', ['required', 'length:10']),
      new Field(this.kpp, 'kpp', { label: 'КПП' }, 'FLInput'),
      new Field(this.ogrn, 'ogrn', { label: 'ОГРН' }, 'FLInput'),
      new Field(this.codeLiteral, 'code', { label: 'Буквенный код' }, 'FLInput', ['required']),
      new Field(this.fullTitle, 'fullTitle', { label: 'Полное наименование' }, 'FLInput'),
    ]
  }

  public asDateFields(): IField<Moment | null>[] {
    const regDate = this.registrationDate.isValid() ? this.registrationDate : null
    return [new Field<Moment | null>(regDate, 'registrationDate', { label: 'Дата регистрации' }, 'FLDatePicker')]
  }
}