import { Expose, Type } from 'class-transformer'
import _ from 'lodash'
import { Comment } from './Comment'

export class CommentTreeNode {
  @Expose({ name: 'current' })
  @Type(() => Comment)
  private _current: Comment

  @Expose({ name: 'childrens' })
  @Type(() => CommentTreeNode)
  private _childrens: CommentTreeNode[]

  constructor()
  constructor(current: Comment, childrens: CommentTreeNode[])
  constructor(current?: Comment, childrens?: CommentTreeNode[]) {
    this._current = current ?? new Comment()
    this._childrens = childrens ?? []
  }

  public get isAny(): boolean {
    return this.entity.isAny
  }

  public get isRoot(): boolean {
    return this.entity.id === -1 || parent == null
  }

  public get isLeaf(): boolean {
    return this._childrens.length == 0
  }

  public get entity(): Comment {
    return this._current
  }

  public get key(): number {
    return this.entity.id
  }

  public get children(): CommentTreeNode[] {
    return this._childrens
  }

  public get unreadedCount(): number {
    return _.filter(this._childrens, item => item.entity.isUnreaded).length
  }

  public unreadedCountDeep(): number {
    let count = this.unreadedCount
    _.forEach(this._childrens, child => {
      if (child.isLeaf) return
      count += child.unreadedCountDeep()
    })
    return count
  }
  
  public equals(other: CommentTreeNode): boolean {
    return other.key === this.key
  }

  public toString(): string {
    return this._current?.toString() ?? ''
  }
}