import { Validator } from 'vee-validate'
import { Fieldset } from './Fieldset'

export abstract class AbstractForm<TEntity, T = number> extends Fieldset<T> {
  public constructor(other: AbstractForm<TEntity, T>)
  public constructor(origin: Fieldset<T>)
  public constructor(origin: Fieldset<T> | AbstractForm<TEntity, T>) {
    super(origin)
  }

  public abstract asEntity(): TEntity

  public abstract add(): Promise<TEntity>

  public abstract update(): Promise<TEntity>

  public abstract remove(): Promise<TEntity>
}

export abstract class AbstractValidatableForm<TEntity, T = number> extends AbstractForm<TEntity, T> {
  protected validator: Validator
  public constructor(other: AbstractValidatableForm<TEntity, T>)
  public constructor(origin: Fieldset<T>, validator: Validator)
  public constructor(origin: AbstractValidatableForm<TEntity, T> | Fieldset<T>, validator?: Validator) {
    const resultValidator = validator ?? (origin as AbstractValidatableForm<TEntity, T>).validator
    super(origin)
    this.validator = resultValidator
  }

  public invalid(): boolean {
    return this.validator.fields.items.some((field) => field.flags.invalid || false)
  }
}
