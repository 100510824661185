import { Type } from 'class-transformer'
import { Registration } from './Registration'

export class WeaklyRegistration {
  @Type(() => Registration)
  private origin: Registration
  constructor()
  constructor(origin: Registration, validRegistrationNumber: string)
  constructor(origin?: Registration, validRegistrationNumber?: string) {
    this.origin = origin ?? new Registration()
    this.validRegistrationNumber = validRegistrationNumber ?? ''
  }
  public validRegistrationNumber: string
}