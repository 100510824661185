
import _ from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DocumentObjectiveProcess, WorkflowObjectiveProcess } from '@/domain/ObjectiveProcess'
import DocumentObjectiveCard from '@/modules/cards/DocumentObjectiveCard.vue'
import WorkflowObjectiveCard from '@/modules/cards/WorkflowObjectiveCard.vue'
import { WorkflowObjective } from '@/domain/WorkflowObjective'

@Component({
  components: {
    DocumentObjectiveCard,
    WorkflowObjectiveCard,
  },
})
export default class ObjectiveCardWrapper extends Vue {
  @Prop({ type: Object }) public item!: DocumentObjectiveProcess | WorkflowObjectiveProcess
  private loading = false
	constructor() {
    super()
	}

  public get isDocumentObjective(): boolean {
    var genericEntity = _.get(this.item, 'entity')
    return !(genericEntity instanceof WorkflowObjective)
  }
}
