
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Document } from '@/domain/Document'
import { WorkflowProcess } from '@/domain/WorkflowProcess'
import { WorkflowObjectiveProcess } from '@/domain/ObjectiveProcess'
import WorkflowObjectiveCard from '@/modules/cards/WorkflowObjectiveCard.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { StageView } from '@/infrastucture/types'
import _ from 'lodash'

@Component({
  components: {
    WorkflowObjectiveCard,
    ItemDrawer,
  },
})
export default class WorkflowProcessTimeline extends Vue {
  @Prop({ type: Object }) public process!: WorkflowProcess<Document>
  private loading = false
  private itemIsVisible = false
  private selectedItem: WorkflowObjectiveProcess | null

	constructor() {
    super()
    this.selectedItem = null
	}

  public get documentId(): number {
    return this.process.id
  }

  public get stagesView(): StageView[] {
    return this.process.toProcessData()
  }

  public get currentStage(): StageView | null {
    const current = this.process.current
    return _.find(this.stagesView, stage => current.id === stage.id) || { id: 0, title: '', info: [], status: 'wait' }
  }

  public get currentStageIndex(): number {
    const current = this.process.current
    return _.findIndex(this.stagesView, stage => current.id === stage.id) || 0
  }

  /* @Watch('documentId', { immediate: true })
  public onChange(): void {
  } */

  public openCard(stageId: number, objectiveId: number): void {
    this.selectedItem = this.process.workflowObjective(stageId, objectiveId)
    if (this.selectedItem) this.itemIsVisible = true
  }

  public closeCard(): void {
    this.itemIsVisible = false
    this.selectedItem = null
  }
}
