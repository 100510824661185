
import { Component, Prop, Vue } from 'vue-property-decorator'
import { KeyLabel } from '@/global/models/ui'
import FLInputNumber from '@/infrastucture/components/FLInputNumber.vue'
import { IField } from '@/global/models/Field'
import _ from 'lodash'

interface DropdownEvent extends PointerEvent {
  key: number
}

type VatAmountKeyLabel = KeyLabel & {
  value: number
} 

@Component({
  components: {
    FLInputNumber,
  },
})
export default class VatAmountDropdown extends Vue {
  // @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: Number, default: 0 }) public value!: number
  @Prop({ type: Number, default: 1 }) public step!: number
  @Prop({ type: Number, default: 0 }) public defaultValue!: number
  @Prop({ type: String, default: '.' }) public decimalSeparator!: string
  @Prop({ type: Number }) public min!: number
  @Prop({ type: Number }) public max!: number
  @Prop({ type: Object, required: true }) public outerField!: IField<number>

  protected options: VatAmountKeyLabel[] = [
    { key: 1, label: 'Без НДС', value: 0 },
    { key: 2, label: '10%', value: 10 },
    { key: 3, label: '18%', value: 18 },
    { key: 4, label: '20%', value: 20 },
  ]

  public changeAmount(event: DropdownEvent): void {
    const option = this.options.find(item => item.key === event.key)
    if (option) {
      const summ = option.value ? _.ceil((this.outerField.content() / (100 + option.value) * option.value), 2) : 0
      this.$emit('input', summ)
    }
  }
}
