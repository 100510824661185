
import { Collection, ICollection } from '@/global/models/Collection'
import { ITableSource } from '@/global/table/models/TableCollection'
import { Component, Watch } from 'vue-property-decorator'
import ViewList from '@/global/models/ViewList'
import SettingsTable from '@/settings/SettingsTable.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import UserCard from '@/settings/UserCard.vue'
import AccessCard from '@/settings/AccessCard.vue'
import { User } from '@/infrastucture/users/User'
import { UserData } from '@/infrastucture/users/UserData'
import { Users } from '@/infrastucture/users/Users'
import { UsersTableCollection } from '@/infrastucture/users/UsersTableCollection'
@Component({
  components: {
    SettingsTable,
    ItemDrawer,
    UserCard,
    AccessCard,
  },
})
export default class UsersTab extends ViewList<User, UserData> {
  private usersData: ICollection<UserData>
  protected selectedItem: User
  private accessIsVisible = false
  private selectedItemForAccess: User | null = null
  public constructor() {
    super()
    this.selectedItem = this.anyItem
    this.usersData = new Collection<UserData>()
  }

  protected get anyItem(): User {
    return User.any
  }

  public get items(): Users {
    return this.$store.getters['manuals/users'] as Users
  }

  public get dataCollection(): ITableSource<UserData> {
    return new UsersTableCollection(this.usersData.toArray())
  }

  @Watch('items', { immediate: true, deep: false })
  protected onItemsChanged(): void {
    this.usersData = this.items.select(item => item.toMedia())
  }

  public openRecord(item: UserData): void {
    this.openCard(item.id)
  }

  public onAdded(item: User): void {
    this.items.push(item)
    this.closeCard()
  }

  public onUpdated(item: User): void {
    this.items.update(item)
    this.closeCard()
  }

  public onEditAccess(item: UserData): void {
    this.selectedItemForAccess = this.items.byId(item.id) || null
    if (this.selectedItemForAccess) this.accessIsVisible = true
  }
}
