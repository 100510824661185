import store from '@/store'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PutRequest } from '@/api/requests/PutRequest'
import { Request } from '@/api/requests/Request'
import { ITransferable } from '@/global/models/AsyncCollection'
import { ICollection } from '@/global/models/Collection'
import { Entity } from '@/global/models/Entity'
import { Field, IField } from '@/global/models/Field'
import { Fieldset } from '@/global/models/Fieldset'
import { ISerilazable } from '@/global/models/Serilazable'
import { AddressEntity } from '@/partners/models/PartnerJson'
import { AddressType } from './AddressType'
import { TerritorialAddress } from './TerritorialAddress'

export class Address extends Entity implements ISerilazable<AddressEntity>, ITransferable {
  protected territorial: TerritorialAddress
  protected postCode: string
  protected type: string
  public constructor()
  public constructor(other: Address)
  public constructor(id: number, territorial: TerritorialAddress, postCode: string, type: string)
  public constructor(...args: unknown[]) {
    const other = args[0] as Address | undefined
    const id = other?.id ?? args[0] as number ?? 0
    const territorial = other?.territorial ?? args[1] as TerritorialAddress ?? new TerritorialAddress()
    const postCode = other?.postCode ?? args[2] as string ?? ''
    const type = other?.type ?? args[3] as string ?? ''
    super(id)
    this.territorial = territorial
    this.postCode = postCode
    this.type = type
  }

  public static any(): Address {
    return new Address()
  }

  public static createFromData(entity: AddressEntity): Address {
    const territorialEntity = entity.territorial
    const territorial = new TerritorialAddress(territorialEntity.country, territorialEntity.region, territorialEntity.area, territorialEntity.settlement, territorialEntity.street, territorialEntity.house, territorialEntity.office)
    return new Address(entity.id, territorial, entity.postCode, entity.type)
  }

  public entity(): AddressEntity {
    return {
      territorial: this.territorial.entity(),
      postCode: this.postCode,
      type: this.type,
      id: this.id,
    }
  }

  public asFields(): IField<string>[] {
    const addressTypes = store.getters['manuals/addressTypes'] as ICollection<AddressType>
    const labels = addressTypes.select((type) => type.toString()).toArray()
    return [
      new Field(this.type, 'type', { label: 'Тип', labels, labelInValue: true }, 'FLSelect', ['required']),
      new Field(this.postCode, 'postCode', { label: 'Индекс' }, 'FLInput', ['required', 'length:6']),
      ...this.territorial.asFields(),
    ]
  }

  public asFieldSet(): Fieldset {
    return new Fieldset(this.id, this.asFields(), [])
  }

  public async transfer(): Promise<void> {
    const request = new AuthorizationRequest(new PutRequest(new Request<void>('api/partneraddresses'), this.entity()))
    await request.response()
  }
}
