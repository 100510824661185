import { map as _map } from 'lodash'
import { Request } from '@/api/requests/Request'
import { Partner } from './Partner'
import { PartnerEntity } from './PartnerJson'
import { EntityCollection } from '@/global/models/EntityCollection'
import { PartnerRows } from './PartnerRows'
import { PartnerRow } from './PartnerRow'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'

export class PartnerCollection extends EntityCollection<Partner> {
  private rows: PartnerRows
  public constructor(collection: Partner[]) {
    super(collection)
    this.rows = new PartnerRows(this.select((partner) => new PartnerRow(partner.asForm())).toArray())
  }

  public static async createAsync(): Promise<PartnerCollection> {
    const request = new AuthorizationRequest(new Request<PartnerEntity[]>('api/partners'))
    const response = await request.response()
    const partners = _map(response.data, (partnerEntity) => Partner.createFromData(partnerEntity))
    return new PartnerCollection(partners)
  }

  public update(element: Partner): void {
    super.update(element)
    this.rows.update(new PartnerRow(element.asForm()), (prev, next) => prev.Id === next.Id)
  }

  public insert(element: Partner, i: number): void {
    super.insert(element, i)
    this.rows.insert(new PartnerRow(element.asForm()), i)
  }

  public toRows(): PartnerRows {
    return this.rows
  }
}