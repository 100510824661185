
import { ITableSource } from '@/global/table/models/TableCollection'
import { Component, Vue } from 'vue-property-decorator'
import SettingsTable from '@/settings/SettingsTable.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { UserData } from '@/users/models/UserData'
import { Department } from '@/domain/Department'
import { DepartmentData } from '@/modules/DepartmentData'
import { DepartmentTableSource } from '@/modules/DepartmentTableSource'
import { EntityCollection, IEntityCollection } from '@/global/models/EntityCollection'
import ViewList from '@/global/models/ViewList'
import DepartmentCard from './DepartmentCard.vue'
import { updateEntity } from '@/services/utils'
@Component({
  components: {
    SettingsTable,
    ItemDrawer,
    DepartmentCard,
  },
})
export default class Departments extends ViewList<Department, DepartmentData> {
  protected selectedItem: Department
  public constructor() {
    super()
    this.selectedItem = this.anyItem
    Vue.observable(this.departments)
  }

  protected get anyItem(): Department {
    return new Department()
  }

  public get departments(): Department[] {
    return this.$store.getters['manuals/departments']
  }

  public get items(): IEntityCollection<Department> {
    return new EntityCollection(this.departments)
  }

  public get dataCollection(): ITableSource<DepartmentData> {
    return new DepartmentTableSource(this.items.select(item => item.toData()).toArray())
  }

  /* @Watch('items', { immediate: true, deep: false })
  protected onItemsChanged(): void {
    this.usersData = this.items.select(item => item.toMedia())
  } */

  public openRecord(item: UserData): void {
    this.openCard(item.id)
  }

  public onAdded(item: Department): void {
    this.departments.splice(0, 0, item)
    this.closeCard()
  }

  public onUpdated(item: Department): void {
    updateEntity(this.departments, item)
    this.closeCard()
  }
}
