import { MemberTypeValue, Status, WorkflowTypeValue } from '@/infrastucture/LookupTypes'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { OutgoingDocumentData } from '@/modules/OutgoingDocumentData'
import { partnerByReporter } from '@/services/cardUtils'
import store from '@/store'
import { valueEntityByTitle } from '@/store/models/Manuals'
import { Users } from '@/infrastucture/users/Users'
import { Type } from 'class-transformer'
import _ from 'lodash'
import moment from 'moment'
import { Document } from './Document'
import { DocumentDescription } from './DocumentDescription'
import { Member } from './Member'
import { Parties } from './Parties'
import { Registration } from './Registration'

export class OutgoingDocument extends Document {
	@Type(() => ValueEntity)
	private deliveryType: ValueEntity
	@Type(() => ValueEntity)
	private documentType: ValueEntity
	@Type(() => DocumentDescription)
	private description: DocumentDescription
	private subject: string
	@Type(() => Parties)
	private parties: Parties
	constructor()
	constructor(other: Document, deliveryType: ValueEntity, documentType: ValueEntity, description: DocumentDescription, subject: string, parties: Parties)
	constructor(other?: Document, deliveryType?: ValueEntity, documentType?: ValueEntity, description?: DocumentDescription, subject?: string, parties?: Parties) {
		super(other ?? new Document())
		this.deliveryType = deliveryType ?? new ValueEntity()
		this.documentType = documentType ?? new ValueEntity()
		this.description = description ?? new DocumentDescription()
		this.subject = subject ?? ''
		this.parties = parties ?? new Parties()
	}

	public static create(): OutgoingDocument {
		const status = valueEntityByTitle('statusList', Status.staging)
		const users: Users = store.getters['manuals/users']
		const members = [new Member(users.currentUser, valueEntityByTitle('documentMembers', MemberTypeValue.initiator))]
		const document = new Document(new ValueEntity(), new Registration(), status, valueEntityByTitle('workflowTypes', WorkflowTypeValue.outgoingDocument), '', members)
		const item = new OutgoingDocument(document, new ValueEntity(), new ValueEntity(), new DocumentDescription(), '', new Parties())
		return item
	}

	public toData(): OutgoingDocumentData {
		const partner = partnerByReporter(this.parties.reporter.id)
		const title = this.isAny || !partner ? this.title : `${this.registration.registrationNumber} ${partner.toString()}`
		return {
			id: this.id,
			itemTitle: title,
			subject: this.subject,
			deliveryType: this.deliveryType,
			documentType: this.documentType,
			registrationDate: moment(this.registration.registrationDate),
			registrationNumber: this.registration.registrationNumber,
			copiesCount: this.description.copiesCount,
			sheetCount: this.description.sheetCount,
			supplementsCount: this.description.supplementsCount,
			description: this.description.description,
			facility: this.parties.facility,
			reporter: this.parties.reporter.toData(),
			storage: this.storage,
			initiator: this.member(MemberTypeValue.initiator).toData(),
			approvers: _.map(this.membersByType(MemberTypeValue.approvers), member => member.toData()),
			validator: this.member(MemberTypeValue.validator).toData(),
			executor: this.member(MemberTypeValue.executor).toData(),
		}
	}
}