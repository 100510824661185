
import { Component, Vue } from 'vue-property-decorator'
import FLInput from '@/global/components/FLInput.vue'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { AxiosError } from 'axios'
import { ActionResultError, ActionResultErrorEntity } from '../models/ActionResultError'
import { AccessEntity } from '../models/types'
import { AuthorizationAccess } from '../models/AuthorizationAccess'
import { RawLocation } from 'vue-router'
@Component({
  components: {
    FLInput,
  },
})
export default class Login extends Vue {
  protected loading = false
  protected userName: string
  protected password: string
  public constructor() {
    super()
    this.userName = ''
    this.password = ''
  }

  public async signIn(): Promise<void> {
    this.loading = true
    const data = new FormData()
    data.append('userName', this.userName)
    data.append('password', this.password)
    const request = new PostRequest<AccessEntity, FormData>(new Request('/oauth/login'), data)
    try {
      const response = await request.response()
      const access = AuthorizationAccess.createFromData(response.data)
      this.$store.dispatch('oauth/setAccess', access)
      const returnUrl = this.$route.query['returnUrl'] as string | undefined
      const to = !returnUrl || returnUrl === '/' ? ({ name: 'objectives', params: { view: 'table' } } as RawLocation) : returnUrl
      this.$router.push(to)
    } catch (error) {
      const axiosError = error as AxiosError<ActionResultErrorEntity>
      const actionResultEx = ActionResultError.create(axiosError.response?.data || { UserMessage: 'Ошибка', Status: 405, ExMessage: 'Неизвестная ошибка' })
      actionResultEx.notify()
      throw actionResultEx
    } finally {
      this.loading = false
    }
  }
}
