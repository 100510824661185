import { entityComparer, IEntityCollection } from '@/global/models/EntityCollection'
import { Partner } from './Partner'
import { DecoratablePartner } from './DecoratablePartner'

class BaseRecordPartner extends DecoratablePartner {
  protected collection: IEntityCollection<Partner>
  public constructor(other: BaseRecordPartner)
  public constructor(origin: Partner, collection: IEntityCollection<Partner>)
  public constructor(...args: unknown[]) {
    const other = args[0] as BaseRecordPartner
    super(other)
    const collection = other.collection ?? args[1] as IEntityCollection<Partner>
    this.collection = collection
  }
}

export class RecordPartner extends BaseRecordPartner {
  public async transfer(): Promise<void> {
    this.collection.update(new Partner(this), entityComparer)
  }
}

export class InsertableRecordPartner extends BaseRecordPartner {
  public async transfer(): Promise<void> {
    this.collection.insert(new Partner(this), 0)
  }
}
