import { Validator } from 'vee-validate'
import { Fieldset } from './Fieldset'

export class ValidatableForm extends Fieldset {
  protected validator: Validator
  public constructor(other: ValidatableForm)
  public constructor(origin: Fieldset, validator: Validator)
  public constructor(...args: unknown[]) {
    const other = args[0] as ValidatableForm
    const origin = other
    const validator = other.validator ?? args[1] as Validator
    super(origin)
    this.validator = validator
  }

  public invalid(): boolean {
    return this.validator.fields.items.some((field) => field.flags.invalid || false)
  }
}
