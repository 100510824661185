import { IRoutable } from '@/global/models/IRoutable'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { RawLocation } from 'vue-router'

export type ActionResultErrorEntity = {
  ExMessage: string
  Status: number
  UserMessage: string
}

export class ActionResultError extends Error implements IRoutable {
  protected status: number
  protected exMessage: string
  public constructor(message: string, status: number, exMessage: string) {
    super(message)
    this.status = status
    this.exMessage = exMessage
  }

  public static create(data: ActionResultErrorEntity): ActionResultError {
    return new ActionResultError(data.UserMessage, data.Status, data.ExMessage)
  }

  public notify(): void {
    notification.error({ message: this.message, description: this.exMessage })
    console.warn(`StatusCode: ${this.status}. Message: ${this.exMessage}.`)
  }

  public asRoute(): RawLocation {
    if (this.status === 401) return { name: 'login', query: { returnUrl: router.currentRoute.fullPath } }
    return {}
  }
}
