import { ActionResultError, ActionResultErrorEntity } from '@/oauth/models/ActionResultError'
import router from '@/router'
import { notification } from 'ant-design-vue'
import { AxiosError } from 'axios'
import _, { Dictionary, List, ListIterateeCustom } from 'lodash'
import moment, { Moment } from 'moment'
import { forEach as _forEach, ArrayIterator } from 'lodash'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import store from '@/store'
import { Member } from '@/domain/Member'
import { User } from '@/infrastucture/users/User'
import { Field } from '@/global/models/Field'
import { Entity } from '@/global/models/Entity'
import { Users } from '@/infrastucture/users/Users'

export const delay = async (milliseconds: number): Promise<void> => await new Promise((resolve) => setTimeout(resolve, milliseconds))
export function momentOrDefault(date: Moment | null | undefined): Moment {
  if (!date || date.isSame(moment(0))) return moment(null)
  return date
}

const defaultStatusMessage: Dictionary<ActionResultErrorEntity> = {
  403: { UserMessage: 'Ошибка', Status: 403, ExMessage: 'Доступ к запрашиваемому ресурсу закрыт' },
  405: { UserMessage: 'Ошибка', Status: 405, ExMessage: 'Неизвестная ошибка' },
}

export function handleAxiosError(ex: Error): void {
  if (ex instanceof AxiosError) {
    const axiosError = ex as AxiosError<ActionResultErrorEntity>
    const status = parseInt(axiosError.status || '0') || axiosError.response?.status || 405
    const exResult = ActionResultError.create(axiosError.response?.data || defaultStatusMessage[status])
    handleException(exResult)
  } else handleException(ex)
}

export function handleException(ex: Error): void {
  if (ex instanceof ActionResultError) {
    const actionResultEx = ex as ActionResultError
    actionResultEx.notify()
    router.push(actionResultEx.asRoute())
  } else notification.error({ message: 'Ошибка', description: (ex as Error).message })
}

export function first<T>(collection: List<T> | null | undefined, predicate?: ListIterateeCustom<T, boolean>, fromIndex?: number): T {
  const founded = _.find(collection, predicate, fromIndex)
  if (!founded) throw new Error('item not founded')
  return founded as T
}

export function activator<T>(type: { new(): T }): T {
  return new type()
}

export function mapIf<T, TResult>(array: T[], selector: ArrayIterator<T, TResult>, predicate: (value: TResult) => boolean = (value) => !!value): TResult[] {
  const newArray: TResult[] = []
  _forEach(array, (value, i, collection) => {
    const mapped = selector(value, i, collection)
    if (predicate(mapped) && mapped) newArray.push(mapped)
  })
  return newArray
}

// eslint-disable-next-line
export const dateToString = ({ value }: any) => moment(value).format('YYYY-MM-DD')
// eslint-disable-next-line
export const stringToDate = ({ value }: any) => moment(value).toDate()

export function objectiveMemberFromEntity(entity: ValueEntity, type: string): Member {
  const users = store.getters['manuals/users'] as Users
  const user = users.byId(entity.id)
  const memberTypes = store.getters['manuals/objectiveMembers'] as ValueEntity[]
  const memberType = first(memberTypes, item => item.toString() === type)
  return new Member(user || new User(), memberType)
}

export function documentMemberFromEntity(entity: ValueEntity, type: string): Member {
  const users = store.getters['manuals/users'] as Users
  const user = users.byId(entity.id)
  const memberTypes = store.getters['manuals/documentMembers'] as ValueEntity[]
  const memberType = first(memberTypes, item => item.toString() === type)
  return new Member(user || new User(), memberType)
}

export function defaultFilter(value: unknown, field: Field<unknown>): boolean {
  if (typeof field.content() === 'string' && value) return (value as string).toLocaleLowerCase().includes((field.content() as string).toLowerCase())
  if (moment.isMoment(field.content()) && value) return (field.content() as Moment).isSame((value as Moment), 'date')
  if (field.content() instanceof ValueEntity && !(field as Field<ValueEntity>).content().isAny && value) return (field as Field<ValueEntity>).content().equalsById(value as ValueEntity)
  else return true
}

export function updateEntity(array: Entity[], item: Entity): void {
  const i = _.findIndex(array, (current) => current.equalsById(item))
  if (i >= 0) array.splice(i, 1, item)
}

export function itemOrDefaultById<T extends Entity>(array: T[], itemId: number): T | undefined {
  const founded = _.find(array, item => item.id === itemId)
  return founded
}

export function itemById<T extends Entity>(array: T[], itemId: number): T {
  const founded = itemOrDefaultById(array, itemId)
  if (!founded) throw new Error('item not founded')
  return founded
}

export function hasById(array: Entity[], itemId: number): boolean {
  return _.some(array, item => item.id === itemId)
}
