
import { Component, Watch, Vue } from 'vue-property-decorator'
import { PartnerButtonPanel } from '@/partners/viewModels/PartnerButtonPanel'
import { PartnerCreation } from '@/partners/viewModels/PartnerCreation'
import Table from '@/global/table/components/Table.vue'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import { PartnerCollection } from '../models/PartnerCollection'
import { Partner } from '../models/Partner'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import ItemCard from './ItemCard.vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { AlterablePartner } from '../models/AlterablePartner'
import { InsertableRecordPartner, RecordPartner } from '../models/RecordPartner'
import { Manuals } from '@/store/models/Manuals'
import { PartnerRows } from '../models/PartnerRows'
import { PartnerRow } from '../models/PartnerRow'
import { handleException } from '@/services/utils'
@Component({
  components: {
    Table,
    ButtonPanel,
    ButtonSection,
    ItemDrawer,
    ItemCard,
  },
  
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
    const self = this as PartnersMain
    if (to.name === 'partners') self.itemIsVisible = false
    if (to.name === 'Detail') self.itemIsVisible = true
    next()
  },
})
export default class PartnersMain extends Vue {
  protected buttonPanel = PartnerButtonPanel.create()
  protected creation = PartnerCreation.create()
  protected collection: PartnerCollection
  protected itemIsVisible = false
  protected loading = false
  protected selectedItem = Partner.any()
  public constructor() {
    super()
    this.collection = new PartnerCollection([])
  }

  public get isInit(): boolean {
    return this.collection.count > 0
  }

  public get viewOnly(): boolean {
    return this.$route.params.mode === 'view'
  }

  public get rows(): PartnerRows {
    return this.collection.toRows()
  }

  @Watch('isInit', { immediate: true })
  public async onCollectionChanged(): Promise<void> {
    if (this.isInit) return
    await this.loadCollection()
  }

  public async loadCollection(): Promise<void> {
    try {
      this.loading = true
      const manuals = new Manuals(this.$store)
      await manuals.load()
      this.collection = await PartnerCollection.createAsync()
    } catch (ex) {
      handleException(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }
  // eslint-disable-next-line
  public openForm(item: PartnerRow): void
  // eslint-disable-next-line
  public openForm(item: Partner): void
  // eslint-disable-next-line
  public openForm(arg: unknown): void {
    const item = arg instanceof Partner ? arg as Partner : this.collection.byId((arg as PartnerRow).Id)
    if (!item) throw new Error('item был undefined.')
    this.selectedItem = new RecordPartner(item, this.collection)
    this.$router.push(this.selectedItem.asRoute())
  }

  public toEdit(): void {
    if (this.collection) this.openForm(new AlterablePartner(this.selectedItem))
  }

  public async onUpdated(item: Partner): Promise<void> {
    if (!this.collection) return
    const record = new RecordPartner(item, this.collection)
    await record.transfer()
    this.selectedItem = new RecordPartner(new Partner(record), this.collection)
    if (!this.viewOnly) this.$router.back()
  }

  public async onAdded(item: Partner): Promise<void> {
    if (!this.collection) return
    const record = new InsertableRecordPartner(item, this.collection)
    await record.transfer()
    this.selectedItem = new RecordPartner(new Partner(record), this.collection)
    this.$router.replace(this.selectedItem.asRoute())
  }

  public onClose(): void {
    const goValue = !this.viewOnly && !this.selectedItem.isAny ? -2 : -1
    this.$router.go(goValue)
    this.selectedItem = Partner.any()
  }
}
