
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Field, IField } from '@/global/models/Field'
import { Facility } from '@/domain/Facility'
import { FacilityData } from '@/modules/FacilityData'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Users } from '@/infrastucture/users/Users'
import SettingsCard from './SettingsCard.vue'

type FacilityFields = {
  itemTitle: IField<string>
  codeLiteral: IField<string>
  destination: IField<string>
  digitalCode: IField<number>
  note: IField<string>
  isActive: IField<boolean>
}

@Component({
  components: {
    SettingsCard,
  },
})
export default class FacilityCard extends Vue {
  @Prop({ type: Object, required: true }) public item!: Facility
  private deserializeType = Facility
  private uri = '/administration/facilities'

  public get itemData(): FacilityData {
    return this.item.toData()
  }

  public get users(): Users {
    return this.$store.getters['manuals/users'] as Users
  }

  public get usersData(): ValueEntity[] {
    return this.users.select(item => item.toData()).toArray()
  }

  public get fieldsMap(): FacilityFields {
    const map = {
      itemTitle: new Field(this.itemData.itemTitle, 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput', ['required']),
      codeLiteral: new Field(this.itemData.codeLiteral, 'codeLiteral', { label: 'Буквенный код', allowClear: true }, 'FLInput', ['required']),
      destination: new Field(this.itemData.destination, 'destination', { label: 'Местонахождение', allowClear: true }, 'FLInput', ['required']),
      digitalCode:new Field(this.itemData.digitalCode, 'digitalCode', { label: 'Цифровой код', allowClear: true }, 'FLInputNumber', ['required']),
      note: new Field(this.itemData.note, 'note', { label: 'Примечание', allowClear: true }, 'FLInput'),
      isActive: new Field(this.itemData.isActive, 'isActive', { label: 'Активный', allowClear: true }, 'FLCheckbox'),
    }
    return map
  }

  public get fieldsList(): IField<unknown>[] {
    const list = Object.values(this.fieldsMap)
    return list
  }

  public createItem(): Facility {
    return new Facility(new ValueEntity(this.item.id, this.fieldsMap.itemTitle.content()), this.fieldsMap.destination.content(),
        this.fieldsMap.digitalCode.content(), this.fieldsMap.codeLiteral.content(), this.fieldsMap.note.content(), this.fieldsMap.isActive.content())
  }
}
