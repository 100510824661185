import { IField } from '@/global/models/Field'
import { Fieldset } from '@/global/models/Fieldset'
import { Location } from 'vue-router'
import { Partner } from './Partner'

export class DecoratablePartner extends Partner {
  protected origin: Partner
  public constructor(origin: Partner) {
    super(origin)
    this.origin = origin
  }
  public asRoute(): Location {
    return this.origin.asRoute()
  }

  public asFields(): IField<string>[] {
    return this.origin.asFields()
  }

  public asForm(): Fieldset {
    return this.origin.asForm()
  }

  public async transfer(): Promise<void> {
    await this.origin.transfer()
  }
}