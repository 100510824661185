
import { UpdatablePartner } from '@/partners/models/UpdatablePartner'
import { Partner } from '@/partners/models/Partner'
import { handleAxiosError } from '@/services/utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICollection } from '@/global/models/Collection'
import { TableRecordForm } from '@/global/models/TableRecordForm'
import { entityComparer } from '@/global/models/EntityCollection'
import { AddressEntity } from '@/partners/models/PartnerJson'
import { Entity } from '@/global/models/Entity'
import CardTable from './CardTable.vue'
import { AddressForms } from '@/domain/addresses/AddressForms'
import { Address } from '@/domain/addresses/Address'
import { PartnerAddressForm } from '@/domain/addresses/PartnerAddressForm'

@Component({
  components: {
    CardTable,
  },
})
export default class AddressesTab extends Vue {
  @Prop({ type: Object, required: true }) public item!: Partner
  protected forms: AddressForms // ITableCollection<TableRecordForm<Address, AddressEntity>>
  private loading = false
  protected fieldList = Address.any().asFields()
  public constructor() {
    super()
    const forms = this.collection.select((item) => new PartnerAddressForm(item, this.item, this.$validator))
    this.forms = new AddressForms(forms.toArray())
  }

  public manualCollection(): ICollection<Address> {
    return this.$store.getters['manuals/addresses']
  }

  public get collection(): ICollection<Address> {
    const items = this.manualCollection().where((item) => this.item.hasAddress(item))
    return items
  }

  public async save(form: PartnerAddressForm): Promise<void> {
    if (form.isAny) await this.add(form)
    else await this.update(form)
  }

  public async add(form: PartnerAddressForm): Promise<void> {
    try {
      this.loading = true
      const item = await form.add()
      this.manualCollection().push(item)
      this.forms.remove(form, (target, source) => target.equals(source))
      this.forms.push(new PartnerAddressForm(item, this.item, this.$validator))
      const collection = this.collection
      collection.push(item)
      const partner = new UpdatablePartner(this.item, collection)
      this.$emit('updated', partner)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }

  public async update(form: PartnerAddressForm): Promise<void> {
    try {
      this.loading = true
      const item = await form.update()
      this.manualCollection().update(item, entityComparer)
      this.cancel(form)
      const partner = new UpdatablePartner(this.item, this.collection)
      this.$emit('updated', partner)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }

  public push(): void {
    if (this.forms.count > 0 && this.forms.at(this.forms.count - 1).isAny) return
    const form = new PartnerAddressForm(Address.any(), this.item, this.$validator)
    form.edit()
    this.forms.push(form)
  }

  public edit(form: TableRecordForm<Address, AddressEntity>): void {
    form.edit()
  }

  public cancel(form: PartnerAddressForm): void {
    if (form.isAny) this.forms.remove(form, (target, source) => target.equals(source))
    else {
      const entity = new Entity(form.key)
      const foundedItem = this.collection.find((item) => item.equalsById(entity))
      if (foundedItem) this.forms.update(new PartnerAddressForm(foundedItem, this.item, this.$validator), (target, source) => target.equals(source))
      form.cancel()
    }
  }

  public async remove(form: PartnerAddressForm): Promise<void> {
    try {
      this.loading = true
      const item = await form.remove()
      this.manualCollection().remove(item, entityComparer)
      const partner = new UpdatablePartner(this.item, this.collection)
      this.$emit('updated', partner)
      this.forms.remove(form, (target, source) => target.equals(source))
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }    
  }
}
