import { Field } from '@/global/models/Field'

export class FieldWithSelectDelegate<T> extends Field<T> {

  private _updateDelegate: (value: T) => T;

	constructor(origin: Field<T>, updateDelegate: (value: T) => T) {
    super(origin)
    this._updateDelegate = updateDelegate
	}

  public update(content: T): void {
    const selected = this._updateDelegate(content)
    super.update(selected)
  }
}
