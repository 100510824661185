import { Dictionary } from 'lodash'
import { IProcessTitlesFactory, ProcessTitles } from './IProcessTitlesFactory'

export class OutgoingDocumentProcessTitlesFactory implements IProcessTitlesFactory {
  private stageNext: Dictionary<string> = {
    Регистрация: 'На согласование',
    Согласование: 'На утверждение',
    Утверждение: 'На исполнение',
    Исполнение: 'Исполнено',
  }
  private stageComlete: Dictionary<string> = {
    Исполнение: 'Исполнено',
  }
  public titles(stage: string): ProcessTitles {
    return {
      start: 'Принять',
      complete: this.stageComlete[stage] || 'Выполнено',
      next: this.stageNext[stage] || 'На следующий этап',
      revert: 'На доработку',
      reject: 'Отклонить',
    }
  }
}
