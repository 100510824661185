
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { plainToInstance } from 'class-transformer'
import { handleAxiosError } from '@/services/utils'
import { CommentTreeNode } from '../CommentTreeNode'
import { Document } from '@/domain/Document'
import Comment from '@/infrastucture/components/Comment.vue'
import CommentEditor from '@/infrastucture/components/CommentEditor.vue'
import { Comment as CommentEntity } from '@/infrastucture/Comment'

@Component({
  components: {
    Comment,
    CommentEditor,
  },
})
export default class Comments extends Vue {
  @Prop({ type: Object }) public document!: Document
  private loading = false
  private tree: CommentTreeNode
	constructor() {
    super()
    this.tree = new CommentTreeNode()
	}

  public get rootNodes(): CommentTreeNode[] {
    return this.tree.children
  }

  public get unreadedTitle(): string {
    return `Непрочитанных (${this.tree.unreadedCountDeep()})`
  }

  @Watch('documentId', { immediate: true })
  public async onDocumentChange(): Promise<void> {
    this.tree = await this.loadComments()
  }

  public async loadComments(): Promise<CommentTreeNode> {
    try {
      this.loading = true
      const request = new AuthorizationRequest(new Request(`/api/comments/${this.document.id}`))
      const response = await request.response()
      const node = plainToInstance(CommentTreeNode, response.data)
      return node
    } catch (ex) {
      handleAxiosError(ex as Error)
      return new CommentTreeNode()
    } finally {
      this.loading = false
    }
  }

  public onCommentAdded(comment: CommentEntity, node: CommentTreeNode = this.tree): void {
    const addedNode = new CommentTreeNode(comment, [])
    node.children.push(addedNode)
  }
}
