
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import { Moment } from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import FloatLabel from './FloatLabel.vue'
@Component({
  components: {
    FloatLabel,
  },
})
export default class FLDatePicker extends Vue {
  @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: Boolean, default: true }) public allowClear!: boolean
  @Prop({ type: Object, default: null }) public value!: Moment | null
  @Prop({ type: Object, default: null }) public defaultValue!: Moment | null
  @Prop({ type: String, default: 'date' }) public mode!: string
  @Prop({ type: String, default: 'DD.MM.YYYY' }) public format!: string
  // eslint-disable-next-line
  @Prop({ type: Object, default: () => locale }) public locale!: any
  @Prop({ type: [Object, Boolean], default: false }) public showTime!: boolean
  @Prop({ type: String, default: null }) public placeholder!: string | null
  protected hasFocus = false

  public get hasValue(): boolean {
    return !!this.value && this.value !== this.defaultValue
  }

  public onFocus(): void {
    this.hasFocus = true
  }

  public onBlur(): void {
    this.hasFocus = false
  }

  public onChange(value: Moment | null): void {
    this.$emit('input', value)
  }
}
