import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Action } from '../buttonPanel/viewModels/ViewButton'
import { IField } from './Field'

export const emptyAction: Action = () => {
  throw new Error('Method not implemented')
}

export type Option<T = number, V = string> = { key: T, label: V }

export type DynamicProps<T> = {
  class?: string,
  secret?: boolean,
  loading?: boolean,
  label: string,
  options?: unknown[],
  defaultValue?: unknown,
  labels?: string[]
  valueEntities?: ValueEntity[],
  labelInValue?: boolean
  disabled?: boolean,
  list?: T[]
  validation?: string,
  hasSearch?: boolean,
  allowClear?: boolean,
  outerField?: IField<unknown>,
  min?: number,
  max?: number,
  maxLength?: number,
}