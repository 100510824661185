import { Dictionary } from 'lodash'
import { IProcessTitlesFactory, ProcessTitles } from './IProcessTitlesFactory'

export class ProcessTitlesFactory implements IProcessTitlesFactory {
  private stageNext: Dictionary<string> = {
    Регистрация: 'В разработку',
    Разработка: 'На согласование',
    Согласование: 'На утверждение',
    Утверждение: 'На подписание',
    Подписание: 'На исполнение',
    Постановка: 'Принять к исполнению',
    Исполнение: 'Исполнено',
    Приёмка: 'Принято',
  }
  private stageComlete: Dictionary<string> = {
    Согласование: 'Согласовать',
    Утверждение: 'Утвердить',
  }
  public titles(stage: string): ProcessTitles {
    return {
      start: 'Принять',
      complete: this.stageComlete[stage] || 'Выполнено',
      next: this.stageNext[stage] || 'На следующий этап',
      revert: 'На доработку',
      reject: 'Отклонить',
    }
  }
}
