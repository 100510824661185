import { Address } from '@/domain/addresses/Address'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PostRequest } from '@/api/requests/PostRequest'
import { Request } from '@/api/requests/Request'
import { Collection, ICollection } from '@/global/models/Collection'
import { Updatable } from '../contacts/Updatable'
import { Reporters } from '../reporters/Reporters'
import { Activity } from './Activity'
import { Partner } from './Partner'
import { PartnerEntity } from './PartnerJson'
import { Registration } from './Registration'

export class UpdatablePartner extends Partner {
  public constructor(origin: Partner, registration: Registration, activity: Activity, title: string, webSite: string, workPhone: string, email: string)
  public constructor(origin: Partner, reporters: Reporters)
  public constructor(origin: Partner, addresses: ICollection<Address>)
  public constructor(...args: unknown[]) {
    const other = args[0] as Partner
    super(other)
    if (args[1] instanceof Reporters) {
      this.contacts = new Updatable(this.contacts, args[1] as Reporters)
    } else if(args[1] instanceof Collection && args[1].at(0) instanceof Address) {
      this.addresses = args[1] as Collection<Address>
    } else {
      const registration = args[1] as Registration
      const activity = args[2] as Activity
      const title = args[3] as string
      const webSite = args[4] as string
      const workPhone = args[5] as string
      const email = args[6] as string
      this.registration = registration
      this.activity = activity
      this.title = title
      this.contacts = new Updatable(this.contacts, webSite, workPhone, email)
    }
  }
}

export class InsertablePartner extends Partner {
  public async transfer(): Promise<void> {
    const request = new AuthorizationRequest(new PostRequest<PartnerEntity, PartnerEntity>(new Request('/api/partners'), this.entity()))
    const response = await request.response()
    this.addIdentity(response.data.id)
  }
}
