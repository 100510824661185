import { Field } from '@/global/models/Field'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { dateComparer, stringComparer } from '@/services/comparers'
import { Moment } from 'moment'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { AbstractTableSource } from './AbstractTableSource'
import { IncomingDocumentData } from './IncomingDocumentData'
import moment from 'moment'

export class IncomingDocumentTableSource extends AbstractTableSource<IncomingDocumentData> {
	public constructor(array: IncomingDocumentData[]) {
		super(array)
		this._headerColumns = [
			new Field('', 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput'),
			new Field('', 'registrationNumber', { label: 'Рег. номер', allowClear: true }, 'FLInput'),
			new Field<Moment | null>(null, 'registrationDate', { label: 'Дата регистрации', allowClear: true }, 'FLDatePicker'),
			new Field<ValueEntity>(new ValueEntity(), 'deliveryType',
			{ label: 'Способ доставки', allowClear: true, valueEntities: this.deliveryTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'documentType',
			{ label: 'Вид документа', allowClear: true, valueEntities: this.documentTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
      new Field('', 'outgoingNumber', { label: 'Исходящий номер', allowClear: true }, 'FLInput'),
      new Field<Moment | null>(null, 'outgoingDate', { label: 'Исходящая дата', allowClear: true }, 'FLDatePicker'),
			new Field<ValueEntity>(new ValueEntity(), 'reporter',
			{ label: 'Контакт контрагента', allowClear: true, valueEntities: this.reportersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'facility',
			{ label: 'Объект', allowClear: true, valueEntities: this.facilities, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'initiator',
			{ label: 'Инициатор', allowClear: true, valueEntities: this.usersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
		]
	}

  public columns(): ITableColumn<IncomingDocumentData>[] {
		return [
			new TableColumn<IncomingDocumentData>('Наименование', 'itemTitle', '400px', false, (prev, next) => stringComparer(prev.itemTitle, next.itemTitle), { customRender: 'toString' }, { title: 'itemTitle' }),
			new TableColumn<IncomingDocumentData>('Рег. номер', 'registrationNumber', '250px', false, (prev, next) => stringComparer(prev.registrationNumber, next.registrationNumber), { customRender: 'toString' }, { title: 'registrationNumber' }),
			new TableColumn<IncomingDocumentData>('Дата регистрации', 'registrationDate', '250px', false, (prev, next) => dateComparer(prev.registrationDate, next.registrationDate), { customRender: 'dateFormat' }, { title: 'registrationDate' }),
			new TableColumn<IncomingDocumentData>('Способ доставки', 'deliveryType', '250px', false, (prev, next) => stringComparer(prev.deliveryType.toString(), next.deliveryType.toString()), { customRender: 'toString' }, { title: 'deliveryType' }),
			new TableColumn<IncomingDocumentData>('Вид документа', 'documentType', '250px', false, (prev, next) => stringComparer(prev.documentType.toString(), next.documentType.toString()), { customRender: 'toString' }, { title: 'documentType' }),
			new TableColumn<IncomingDocumentData>('Описание', 'description', '250px', false, (prev, next) => stringComparer(prev.description, next.description), { customRender: 'toString' }),
      new TableColumn<IncomingDocumentData>('Примечание', 'note', '250px', false, (prev, next) => stringComparer(prev.note, next.note), { customRender: 'toString' }),
			new TableColumn<IncomingDocumentData>('Место хранения', 'storage', '200px', false, (prev, next) => stringComparer(prev.storage, next.storage), { customRender: 'toString' }),
			new TableColumn<IncomingDocumentData>('Исходящий номер', 'outgoingNumber', '250px', false, (prev, next) => stringComparer(prev.outgoingNumber.toString(), next.outgoingNumber.toString()), { customRender: 'toString' }, { title: 'outgoingNumber' }),
			new TableColumn<IncomingDocumentData>('Исходящая дата', 'outgoingDate', '250px', false, (prev, next) => dateComparer(moment(prev.outgoingDate), moment(next.outgoingDate)), { customRender: 'toString' }, { title: 'outgoingDate' }),
			new TableColumn<IncomingDocumentData>('Контакт контрагента', 'reporter', '250px', false, (prev, next) => stringComparer(prev.reporter.toString(), next.reporter.toString()), { customRender: 'toString' }, { title: 'reporter' }),
			new TableColumn<IncomingDocumentData>('Объект', 'facility', '250px', false, (prev, next) => stringComparer(prev.facility.toString(), next.facility.toString()), { customRender: 'toString' }, { title: 'facility' }),
			new TableColumn<IncomingDocumentData>('Инициатор', 'initiator', '250px', false, (prev, next) => stringComparer(prev.initiator.toString(), next.initiator.toString()), { customRender: 'toString' }, { title: 'initiator' }),
			new TableColumn<IncomingDocumentData>('Адресовано', 'recievers', '250px', false, false, { customRender: 'toString' }),
      new TableColumn<IncomingDocumentData>('На ознакомление', 'assignedTo', '250px', false, false, { customRender: 'toString' }),	
		]
	}
}