
import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { Field } from '@/global/models/Field'
import { Department } from '@/domain/Department'
import { DepartmentData } from '@/modules/DepartmentData'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Users } from '@/infrastucture/users/Users'
import SettingsCard from './SettingsCard.vue'

type DepartmentFields = {
  itemTitle: Field<string>
  codeLiteral: Field<string>
  manager: Field<ValueEntity>
}

@Component({
  components: {
    SettingsCard,
  },
})
export default class DepartmentCard extends Vue {
  @Prop({ type: Object, required: true }) public item!: Department
  private uri = '/administration/departments'
  private deserializeType = Department

  public get itemData(): DepartmentData {
    return this.item.toData()
  }

  public get users(): Users {
    return this.$store.getters['manuals/users'] as Users
  }

  public get usersData(): ValueEntity[] {
    return this.users.select(item => item.toData()).toArray()
  }

  public get fieldsMap(): DepartmentFields {
    const map = {
      itemTitle: new Field(this.itemData.itemTitle, 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput', ['required']),
      codeLiteral: new Field(this.itemData.codeLiteral, 'codeLiteral', { label: 'Буквенный код', allowClear: true }, 'FLInput', ['required']),
      manager: new Field(this.itemData.manager, 'manager', { label: 'Руководитель', valueEntities: this.usersData, labelInValue: true }, 'FLSelect', ['required']),
    }
    return map
  }

  public get fieldsList(): Field<unknown>[] {
    const list = Object.values(this.fieldsMap)
    return list
  }

  public createItem(): Department {
    const departmentUsers = _.get(this.item, 'users')
      const manager = this.users.byId(this.fieldsMap.manager.content().id)
      if (!manager) throw new Error('Руководитель отдела не найден')
      const item = new Department(new ValueEntity(this.item.id, this.fieldsMap.itemTitle.content()), this.fieldsMap.codeLiteral.content(), departmentUsers, manager)
    return item
  }
}
