import { Field, IField } from '@/global/models/Field'
import { Reporter } from './Reporter'

export class TableReporter extends Reporter {
  public asFields(): IField<string>[] {
    return [
      new Field(this.lastName, 'lastName', { label: '' }, 'FLInput', ['required']),
      new Field(this.firstName, 'firstName', { label: '' }, 'FLInput', ['required']),
      new Field(this.middleName, 'middleName', { label: '' }, 'FLInput'),
      new Field(this.jobTitle, 'jobTitle', { label: '' }, 'FLInput'),
      new Field(this.email, 'email', { label: '' }, 'FLInput', ['email']),
      new Field(this.phone, 'phone', { label: '' }, 'FLInput', ['required']),
    ]
  }
}