import { Field } from '@/global/models/Field'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { dateComparer, stringComparer } from '@/services/comparers'
import moment, { Moment } from 'moment'
import { ContractData } from './ContractData'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { AbstractTableSource } from './AbstractTableSource'

export class ContractTableSource extends AbstractTableSource<ContractData> {
	protected _headerColumns: Field<unknown>[]
	public constructor(array: ContractData[]) {
		super(array)
		const contracts = this.select(item => new ValueEntity(item.id, item.itemTitle)).toArray()
		this._headerColumns = [
			new Field('', 'itemTitle', { label: 'Наименование', allowClear: true }, 'FLInput'),
			new Field('', 'registrationNumber', { label: 'Рег. номер', allowClear: true }, 'FLInput'),
			new Field<Moment | null>(null, 'registrationData', { label: 'Дата регистрации', allowClear: true }, 'FLDatePicker'),
			new Field<ValueEntity>(new ValueEntity(), 'category',
			{ label: 'Категория договора', allowClear: true, valueEntities: this.contractCategories, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'type',
			{ label: 'Вид договора', allowClear: true, valueEntities: this.contractTypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'subtype',
			{ label: 'Подвид договора', allowClear: true, valueEntities: this.contractSubtypes, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'baseContract',
			{ label: 'Основной договор', allowClear: true, valueEntities: contracts, labelInValue: true, class: 'w-250-deep', defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<Moment | null>(null, 'startDate', { label: 'Дата начала', allowClear: true }, 'FLDatePicker'),
			new Field<Moment | null>(null, 'finishDate', { label: 'Дата окончания', allowClear: true }, 'FLDatePicker'),
			new Field<ValueEntity>(new ValueEntity(), 'enterprise',
			{ label: 'Объект', allowClear: true, valueEntities: this.facilities, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'reporter',
			{ label: 'Контакт контрагента', allowClear: true, valueEntities: this.reportersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'initiator',
			{ label: 'Инициатор', allowClear: true, valueEntities: this.usersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'developer',
			{ label: 'Юрист', allowClear: true, valueEntities: this.usersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
			new Field<ValueEntity>(new ValueEntity(), 'executor',
			{ label: 'Ответственный', allowClear: true, valueEntities: this.usersData, labelInValue: true, defaultValue: { key: 0, label: '' } }, 'FLSelect'),
		]
	}

  public columns(): ITableColumn<ContractData>[] {
		return [
			new TableColumn<ContractData>('Наименование', 'itemTitle', '400px', false, (prev, next) => stringComparer(prev.itemTitle, next.itemTitle), { customRender: 'toString' }, { title: 'itemTitle' }),
			new TableColumn<ContractData>('Рег. номер', 'registrationNumber', '250px', false, (prev, next) => stringComparer(prev.registrationNumber, next.registrationNumber), { customRender: 'toString' }, { title: 'registrationNumber' }),
			new TableColumn<ContractData>('Дата регистрации', 'registrationData', '250px', false, (prev, next) => dateComparer(prev.registrationData, next.registrationData), { customRender: 'dateFormat' }, { title: 'registrationData' }),
			new TableColumn<ContractData>('Категория договора', 'category', '250px', false, (prev, next) => stringComparer(prev.category.toString(), next.category.toString()), { customRender: 'toString' }, { title: 'category' }),
			new TableColumn<ContractData>('Вид договора', 'type', '250px', false, (prev, next) => stringComparer(prev.type.toString(), next.type.toString()), { customRender: 'toString' }, { title: 'type' }),
			new TableColumn<ContractData>('Подвид договора', 'subtype', '250px', false, (prev, next) => stringComparer(prev.subtype.toString(), next.subtype.toString()), { customRender: 'toString' }, { title: 'subtype' }),
			new TableColumn<ContractData>('Основной договор', 'baseContract', '250px', false, (prev, next) => stringComparer(prev.baseContract.toString(), next.baseContract.toString()), { customRender: 'toString' }, { title: 'baseContract' }),
			new TableColumn<ContractData>('Дата начала', 'startDate', '250px', false, (prev, next) => dateComparer(moment(prev.startDate), moment(next.startDate)), { customRender: 'dateFormat' }, { title: 'startDate' }),
			new TableColumn<ContractData>('Дата окончания', 'finishDate', '250px', false, (prev, next) => dateComparer(moment(prev.finishDate), moment(next.finishDate)), { customRender: 'dateFormat' }, { title: 'finishDate' }),
			new TableColumn<ContractData>('Место хранения', 'storage', '250px', false, (prev, next) => stringComparer(prev.storage, next.storage), { customRender: 'toString' }),
			new TableColumn<ContractData>('Сумма', 'summ', '150px', false, (prev, next) => stringComparer(prev.summ.toString(), next.summ.toString()), { customRender: 'toString' }),
			new TableColumn<ContractData>('Валюта', 'currency', '150px', false, (prev, next) => stringComparer(prev.currency, next.currency), { customRender: 'toString' }),
			new TableColumn<ContractData>('Код бюджета', 'codeBudget', '150px', false, (prev, next) => stringComparer(prev.codeBudget, next.codeBudget), { customRender: 'toString' }),
			new TableColumn<ContractData>('Сумма НДС', 'ndsSumm', '150px', false, (prev, next) => stringComparer(prev.ndsSumm.toString(), next.ndsSumm.toString()), { customRender: 'toString' }),
			new TableColumn<ContractData>('Контакт контрагента', 'reporter', '250px', false, (prev, next) => stringComparer(prev.reporter.toString(), next.reporter.toString()), { customRender: 'toString' }, { title: 'reporter' }),
			new TableColumn<ContractData>('Объект', 'enterprise', '250px', false, (prev, next) => stringComparer(prev.enterprise.toString(), next.enterprise.toString()), { customRender: 'toString' }, { title: 'enterprise' }),
			new TableColumn<ContractData>('Проверка контрагента', 'verification', '200px', false, (prev, next) => stringComparer(prev.verification, next.verification), { customRender: 'toString' }),
			new TableColumn<ContractData>('Инициатор', 'initiator', '250px', false, (prev, next) => stringComparer(prev.initiator.toString(), next.initiator.toString()), { customRender: 'toString' }, { title: 'initiator' }),
			new TableColumn<ContractData>('Юрист', 'developer', '250px', false, (prev, next) => stringComparer(prev.developer.toString(), next.developer.toString()), { customRender: 'toString' }, { title: 'developer' }),
			new TableColumn<ContractData>('Ответственный', 'executor', '250px', false, (prev, next) => stringComparer(prev.executor.toString(), next.executor.toString()), { customRender: 'toString' }, { title: 'executor' }),
			
		]
	}
}