import { map as _map, some as _some } from 'lodash'
import { ContactsEntity } from '../models/PartnerJson'
import { Reporter } from '../reporters/Reporter'
import { Field, IField } from '@/global/models/Field'
import { ISerilazable } from '@/global/models/Serilazable'
import { Type } from 'class-transformer'
import { Entity } from '@/global/models/Entity'

export class Contacts implements ISerilazable<ContactsEntity> {
  protected webSite: string
  protected workPhone: string
  @Type(() => Reporter)
  protected reporters: Reporter[]
  protected email: string
  protected fax: string
  public constructor(other: Contacts)
  public constructor(webSite: string, workPhone: string, email: string, fax: string, reporters: Reporter[])
  public constructor(...args: unknown[]) {
    const other = args[0] as Contacts | undefined
    const hasRebuild = args.length === 1
    const webSite = (hasRebuild ? other?.webSite : args[0] as string) || ''
    const workPhone = (hasRebuild ? other?.workPhone : args[1] as string) || ''
    const email = (hasRebuild ? other?.email : args[2] as string) || ''
    const fax = (hasRebuild ? other?.fax : args[3] as string) || ''
    const reporters = (hasRebuild ? other?.reporters : args[4] as Reporter[]) || []
    this.webSite = webSite
    this.workPhone = workPhone
    this.reporters = Array.from(reporters)
    this.email = email
    this.fax = fax
  }

  public static any(): Contacts {
    return new Contacts('', '', '', '', [])
  }

  public static createFromData(entity: ContactsEntity): Contacts {
    const reporters = _map(entity.reporters, (reporterEntity) => Reporter.createFromData(reporterEntity))
    return new Contacts(entity.webSite, entity.workPhone, entity.email, entity.fax, reporters)
  }

  public entity(): ContactsEntity {
    return {
      webSite: this.webSite,
      workPhone: this.workPhone,
      reporters: [],
      email: this.email,
      fax: this.fax,
    }
  }

  public asFields(): IField<string>[] {
    return [
      new Field(this.email, 'email', { label: 'Email' }, 'FLInput', ['email']),
      new Field(this.workPhone, 'workPhone', { label: 'Тел. организации' }, 'FLInput'),
      new Field(this.webSite, 'webSite', { label: 'Веб-сайт контрагента' }, 'FLInput'),
    ]
  }
  public hasReporter(id: number): boolean
  public hasReporter(otherReporter: Reporter | number): boolean {
    const id = typeof otherReporter === 'number' ? otherReporter : otherReporter.id
    return _some(this.reporters, (reporter) => reporter.equalsById(new Entity(id)))
  }
}
