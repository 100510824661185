import { AnyField, IField } from '@/global/models/Field'
import { Row } from '@/global/models/Row'

export class PartnerRow extends Row {
  public get itemTitle(): IField<string> {
    return this.textFields.get('itemTitle') || new AnyField()
  }

  public get inn(): IField<string> {
    return this.textFields.get('inn') || new AnyField()
  }

  public get email(): IField<string> {
    return this.textFields.get('email') || new AnyField()
  }

  public get workPhone(): IField<string> {
    return this.textFields.get('workPhone') || new AnyField()
  }
}
