
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { File } from '@/files/File'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import _ from 'lodash'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { plainToInstance } from 'class-transformer'
import { handleAxiosError } from '@/services/utils'
import { PostRequest } from '@/api/requests/PostRequest'
import { UploadFileData } from '@/files/types'
@Component
export default class Files extends Vue {
  @Prop({ type: Number }) public documentId!: number
  private loading = false
  private files: File[]
	constructor() {
    super()
    this.files = []
	}

  public get columns(): ITableColumn<string>[] {
    return [
      new TableColumn('Наименование', 'title', 150, false, false),
      new TableColumn('Автор', 'author', 150, false, false),
      new TableColumn('Когда создано', 'created', 150, false, false),
      new TableColumn('Кем изменено', 'modifier', 150, false, false),
      new TableColumn('Когда изменено', 'modified', 150, false, false),
      new TableColumn('', 'download', 50, false, false, { customRender: 'download' }),
      new TableColumn('', 'remove', 50, false, false, { customRender: 'remove' }),
    ]
  }
  @Watch('documentId', { immediate: true })
  public async onDocumentChange(): Promise<void> {
    this.files = await this.loadFiles()
  }

  public async loadFiles(): Promise<File[]> {
    try {
      this.loading = true
      const request = new AuthorizationRequest(new Request<unknown[]>(`/files/${this.documentId}`))
      const response = await request.response()
      const files = plainToInstance(File, response.data)
      return files
    } catch (ex) {
      handleAxiosError(ex as Error)
      return []
    } finally {
      this.loading = false
    }
  }

  public async upload(content: UploadFileData): Promise<void> {
    try {
      this.loading = true
      const data = new FormData()
      data.append('content', content.file)
      data.append('documentId', this.documentId.toString())
      const request = new AuthorizationRequest(new PostRequest(new Request<unknown>('/files', { 'Content-Type': 'multipart/form-data' }), data))
      const response = await request.response()
      const file = plainToInstance(File, response.data)
      const i = _.findIndex(this.files, item => item.equalsById(file))
      if (i < 0) this.files.push(file)
      else this.$set(this.files, i, file)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }

  public async remove(record: File): Promise<void> {
    try {
      this.loading = true
      await record.delete()
      const i = _.findIndex(this.files, item => item.equalsById(record))
      this.files.splice(i, 1)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }

  public async download(record: File): Promise<void> {
    try {
      this.loading = true
      await record.download()
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }

}
