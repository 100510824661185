import { AuthorizationAccess } from '@/oauth/models/AuthorizationAccess'
import { ActionResultErrorEntity, ActionResultError } from '@/oauth/models/ActionResultError'
import store from '@/store'
import { AxiosError, AxiosResponse } from 'axios'
import { Request } from './Request'

export class AuthorizationRequest<T> extends Request<T> {
  private access: AuthorizationAccess
  public constructor(origin: Request<T>)
  public constructor(origin: Request<T>, access: AuthorizationAccess)
  public constructor(origin: Request<T>, access?: AuthorizationAccess) {
    super(origin)
    const authAccess = access ?? store.getters['oauth/access']
    this.access = authAccess
    this.requestMessage.headers = authAccess.requestConfig().headers
  }

  public async response(): Promise<AxiosResponse<T>> {
    if (this.access.isExpired()) {
      try {
        const access = await this.access.token()
        store.dispatch('oauth/setAccess', access)
        return await new AuthorizationRequest(this, access).response()
      } catch (ex) {
        const axiosError = ex as AxiosError<ActionResultErrorEntity>
        const exResult = ActionResultError.create(axiosError.response?.data || { UserMessage: 'Ошибка', Status: 405, ExMessage: 'Неизвестная ошибка' })
        throw exResult
      }
    }
    return await super.response()
  }
}
