import { Type } from 'class-transformer'
import moment from 'moment'

export class TimeInterval {
  constructor()
  constructor(start: Date, finish: Date)
  constructor(start?: Date, finish?: Date) {
    this.start = start ?? moment(0).toDate()
    this.finish = finish ?? moment(0).toDate()
  }

  public get duration(): number {
    return moment(this.finish).diff(this.start, 'day')
  }
  @Type(() => Date)
  public start: Date
  @Type(() => Date)
  public finish: Date
}