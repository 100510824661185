import { Entity } from '@/global/models/Entity'

export class ValueEntity extends Entity {
  protected title: string
  constructor()
  constructor(other: ValueEntity)
  constructor(id: number, title: string)
  constructor(entity: Entity, title: string)
  constructor(entity?: Entity | number, title?: string) {
    const id = entity instanceof Entity ? entity.id : entity
    super(id ?? 0)
    this.title = title ?? (entity as ValueEntity)?.title ?? ''
  }

  public toString(): string {
    return this.title
  }
}
