import { ISerilazable } from '@/global/models/Serilazable'
import { IEquatable } from '@/infrastucture/IEquatable'
import { ClaimEntity } from './types'

export class Claim implements ISerilazable<ClaimEntity>, IEquatable<Claim> {
  protected type: string
  protected value: string
  public constructor(other: Claim)
  public constructor(type: string, value: string)
  public constructor(...args: unknown[]) {
    const other = args[0] as Claim | undefined
    const type = other?.type ?? args[0] as string ?? ''
    const value = other?.value ?? args[1] as string ?? ''
    this.type = type
    this.value = value
  }
  
  public equals(value: Claim): boolean {
    return this.type === value.type && this.value === value.value
  }

  public entity(): ClaimEntity {
    return {
      issuer: 'LOCAL AUTHORITY',
      originalIssuer: 'LOCAL AUTHORITY',
      properties: {},
      subject: null,
      type: this.type,
      value: this.value,
      valueType: 'http://www.w3.org/2001/XMLSchema#string',
    }
  }

  public toString(): string {
    return this.value
  }
}