import { NavigationGuardNext, RawLocation, Route } from 'vue-router'
import { IEntity } from '../IEntity'
import Module from './Module'

export default abstract class ModuleWithRoute<T extends IEntity, TData> extends Module<T, TData> {
  protected detailName: string
  protected moduleName: string
  protected fastLinkitemId = -1
	constructor(moduleName: string, detailName: string) {
    super()
    this.detailName = detailName
    this.moduleName = moduleName
    if (this.$route.name === this.detailName) {
      this.fastLinkitemId = parseInt(this.$route.params.itemId) || -1
      this.$router.push({ name: this.moduleName })
    }
	}

  public async onChange(): Promise<void> {
    if (this.fastLinkitemId !== -1) {
      this.openCard(this.fastLinkitemId)
      this.fastLinkitemId = -1
    }
  }

  public get viewOnly(): boolean {
    return this.$route.params.mode === 'view'
  }

  public viewRoute(itemId: number): RawLocation {
    return { name: this.detailName, params: { mode: 'view', itemId: itemId + '' }  }
  }

  public editRoute(itemId: number): RawLocation {
    return { name: this.detailName, params: { mode: 'edit', itemId: itemId + '' }  }
  }

  public beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
    const self = this as ModuleWithRoute<T, TData>
    if (to.name === this.moduleName) self.itemIsVisible = false
    if (to.name === this.detailName) {
      const id = parseInt(to.params.itemId)
      if (!self.selectedItem && id > 0) {
        const item = self.items.byId(id)
        if (!item) return
        self.selectedItem = item
      }
      self.itemIsVisible = true
    }
    next()
  }

  public toEdit(): void {
    if (this.items && this.selectedItem && !this.selectedItem.isAny) this.$router.push(this.editRoute(this.selectedItem.id))
  }

  public openCard(key: number): void {
    if (key === -1) {
      this.selectedItem = this.anyItem()
      // this.itemIsVisible = true
      this.$router.push(this.editRoute(this.selectedItem.id))
    } else {
      const item = this.items.byId(key)
      if (!item) return
      this.selectedItem = item
      // this.itemIsVisible = true
      this.$router.push(this.viewRoute(this.selectedItem.id))
    }
  }

  public closeCard(): void {
    // this.itemIsVisible = false
    // Если редактируется и есть selectedItem то -2
    const goValue = !this.viewOnly && (!!this.selectedItem && !this.selectedItem.isAny) ? -2 : -1
    this.$router.go(goValue)
    this.selectedItem = null
    
  }
}