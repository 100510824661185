
import { Component } from 'vue-property-decorator'
import CardContent from '@/global/components/CardContent.vue'
import { ContentTab, ViewCardContent } from '@/global/viewModels/ViewCardContent'
import Module from '@/global/models/Module'
import UsersTab from './UsersTab.vue'
import Departments from './Departments.vue'
import Facilities from './Facilities.vue'
@Component({
  components: {
    CardContent,
    UsersTab,
    Departments,
    Facilities,
  },
})
export default class Settings extends Module {
  protected content: ViewCardContent
  public constructor() {
    super()
    const tabs = [new ContentTab('users', 'Пользователи'), new ContentTab('departments', 'Отделы'), new ContentTab('facilities', 'Объекты')]
    this.content =  new ViewCardContent(tabs, 'users')
  }
}
