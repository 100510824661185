import { Person } from '@/infrastucture/users/Person'

export class Employee extends Person {
  protected email: string
  protected phone: string
  protected jobTitle: string
  public constructor()
  public constructor(other: Employee)
  public constructor(other: Person, email: string, phone: string, jobTitle: string)
  public constructor(...args: unknown[]) {
    const other = (args[0] as Person) ?? new Person()
    const email = (args[1] as string) ?? (args[0] as Employee)?.email ?? ''
    const phone = (args[2] as string) ?? (args[0] as Employee)?.phone ?? ''
    const jobTitle = (args[3] as string) ?? (args[0] as Employee)?.jobTitle ?? ''
    super(other)
    this.email = email
    this.phone = phone
    this.jobTitle = jobTitle
  }
}