
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import CardContent from '@/global/components/CardContent.vue'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { DocumentObjectiveProcess, WorkflowObjectiveProcess } from '@/domain/ObjectiveProcess'
import { handleAxiosError } from '@/services/utils'
import { ObjectiveData } from '../types'
import { ContentTab, ViewCardContent } from '@/global/viewModels/ViewCardContent'
import { Field, IField } from '@/global/models/Field'
import { Dictionary } from 'vue-router/types/router'
import Comments from '@/infrastucture/components/Comments.vue'

@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    CardContent,
    FieldSet,
    Comments,
  },
})
export default class ObjectiveCardContent extends Vue {
  @Prop({ type: Object }) public process!: DocumentObjectiveProcess | WorkflowObjectiveProcess
  private loading = false
  protected cardContent: ViewCardContent
  protected commentContent: ViewCardContent
	constructor() {
    super()
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
    this.commentContent = new ViewCardContent([new ContentTab('common', 'Обсуждения документа')], 'common')
	}

  public get itemData(): ObjectiveData {
    return this.process.toData()
  }

  public get itemFields(): Dictionary<IField<unknown>> {
    return {
      description: new Field(this.itemData.description, 'description', { label: 'Описание',  }, 'FLTextArea'),
      start: new Field(this.itemData.start, 'start', { label: 'Начало',  }, 'FLDatePicker'),
      finish: new Field(this.itemData.finish, 'finish', { label: 'Окончание',  }, 'FLDatePicker'),
      priority: new Field(this.itemData.priority, 'priority', { label: 'Приоритет', valueEntities: [], labelInValue: true  }, 'FLSelect'),
      initiator: new Field(this.itemData.initiator, 'initiator', { label: 'Инициатор', valueEntities: [], labelInValue: true  }, 'FLSelect'),
      assignedTo: new Field(this.itemData.assignedTo, 'assignedTo', { label: 'Ответственный', valueEntities: [], labelInValue: true  }, 'FLSelect'),
    }
  }

  public get controlFieldList(): IField<unknown>[] {
    return [
      new Field(this.itemData.reciever, 'reciever', { label: 'Приёмщик', valueEntities: [], labelInValue: true  }, 'FLSelect'),
    ]
  }

  public get itemFieldsList(): IField<unknown>[] {
    return Object.values(this.itemFields)
  }

  public get itemId(): number {
    return this.process.id
  }

  @Watch('itemId', { immediate: true })
  public async onChange(): Promise<void> {
    // await this.loadObjectives()
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }
}
