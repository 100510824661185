import {
  find as _find,
  filter as _filter,
  some as _some,
  every as _every,
  forEach as _forEach,
  map as _map,
  findIndex as _findIndex,
  ListIterateeCustom,
  ArrayIterator
} from 'lodash'
import { Vue } from 'vue-property-decorator'

export type EqualityComparer<T> = (target: T, source: T) => boolean

export interface ICollection<T> {
  insert(element: T, i: number): void
  insertRange(elements: Array<T>, i: number): void
  remove(i: number): void
  remove(element: T, comparer?: EqualityComparer<T>): void
  push(...elements: T[]): void
  pop(): void
  count: number
  at(i: number): T
  indexOf(element: T, comparer?: EqualityComparer<T>): number
  update(element: T, comparer?: EqualityComparer<T>): void
  toArray(): T[]
  find(predicate: ListIterateeCustom<T, boolean>) : T | undefined
  where(predicate: ListIterateeCustom<T, boolean>) : ICollection<T>
  any(predicate: ListIterateeCustom<T, boolean>): boolean
  all(predicate: ListIterateeCustom<T, boolean>): boolean
  // eslint-disable-next-line
  forEach(predicate: ArrayIterator<T, any> | undefined): void
  select<TResult>(predicate: ArrayIterator<T, TResult>): ICollection<TResult>
  toCollection() : ICollection<T>
  clear(): void
}
export class Collection<T> implements ICollection<T> {
  protected primaryCollection: Array<T>
  public constructor(collection: Array<T> = []) {
    this.primaryCollection = Array.from(collection)
  }

  public at(i: number): T {
    return this.primaryCollection[i]
  }

  public get count(): number {
    return this.primaryCollection.length
  }

  public push(...elements: T[]): void {
    this.primaryCollection.push(...elements)
  }

  public pop(): void {
    this.primaryCollection.pop()
  }

  public insert(element: T, i: number): void {
    this.primaryCollection.splice(i, 0, element)
  }

  public insertRange(elements: Array<T>, i: number): void {
    this.primaryCollection.splice(i, 0, ...elements)
  }

  public remove(element: T, comparer?: EqualityComparer<T>): void
  public remove(i: number): void
  public remove(...args: unknown[]): void {
    console.log(args)
    if (typeof args[0] === 'number') this.primaryCollection.splice(args[0] as number, 1)
    else {
      const item = args[0] as T
      const comparer = args[1] as EqualityComparer<T> || ((target, source) => target === source)
      const index = this.indexOf(item, (target, source) => comparer(target, source))
      console.log(Array.from(this.primaryCollection), index)
      if (index < 0) return
      this.primaryCollection.splice(index, 1)
    }
  }

  public update(element: T, comparer?: EqualityComparer<T>): void {
    const index = this.indexOf(element, comparer || ((target, source) => target === source))
    if (index < 0) return
    Vue.set(this.primaryCollection, index, element)
  }

  public find(predicate: ListIterateeCustom<T, boolean>) : T | undefined {
    return _find(this.primaryCollection, predicate)
  }

  public where(predicate?: ListIterateeCustom<T, boolean>) : Collection<T> {
    const filtered = _filter(this.primaryCollection, predicate)
    return new Collection<T>(filtered)
  }

  public any(predicate: ListIterateeCustom<T, boolean>): boolean {
    return _some(this.primaryCollection, predicate)
  }

  public all(predicate: ListIterateeCustom<T, boolean>): boolean {
    return _every(this.primaryCollection, predicate)
  }

  // eslint-disable-next-line
  public forEach(predicate: ArrayIterator<T, any> | undefined): void {
    _forEach(this.primaryCollection, predicate)
  }

  public select<TResult>(predicate: ArrayIterator<T, TResult>): Collection<TResult> {
    const selected = _map<T, TResult>(this.primaryCollection, predicate)
    return new Collection<TResult>(selected)
  }

  public toArray(): T[] {
    return Array.from(this.primaryCollection)
  }

  public toCollection(): ICollection<T> {
    return new Collection(this.toArray())
  }

  public indexOf(element: T, comparer: EqualityComparer<T>): number {
    const index = _findIndex(this.primaryCollection, (item) => comparer(element, item))
    return index
  }

  public clear(): void {
    this.primaryCollection = []
  }
}
