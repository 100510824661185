import { Address } from '@/domain/addresses/Address'
import { AddressType } from '@/domain/addresses/AddressType'
import { Department } from '@/domain/Department'
import { Facility } from '@/domain/Facility'
import { ICollection } from '@/global/models/Collection'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { PartnerCollection } from '@/partners/models/PartnerCollection'
import { Reporters } from '@/partners/reporters/Reporters'
import { Users } from '@/infrastucture/users/Users'
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
@Component({
  computed: {
    ...mapGetters({
      reporters: 'manuals/reporters',
      currencies: 'manuals/currencies',
      partners: 'manuals/partners',
      addresses: 'manuals/addresses',
      addressTypes: 'manuals/addressTypes',
      users: 'manuals/users',
      contractCategories: 'manuals/contractCategories',
      contractTypes: 'manuals/contractTypes',
      contractSubtypes: 'manuals/contractSubtypes',
      departments: 'manuals/departments',
      // facilities: 'manuals/facilities',
      facilities: 'manuals/activeFacilities',
      statusList: 'manuals/statusList',
      documentMembers: 'manuals/documentMembers',
      workflowTypes: 'manuals/workflowTypes',
      accountTypes: 'manuals/accountTypes',
      paymentTypes: 'manuals/paymentTypes',
      documentTypes: 'manuals/documentTypes',
      deliveryTypes: 'manuals/deliveryTypes',
    }),
  },
})
export default class DocumentCard extends Vue {
  protected readonly partners!: PartnerCollection
  protected readonly reporters!: Reporters
  protected readonly addresses!: ICollection<Address>
  protected readonly addressTypes!: ICollection<AddressType>
  protected readonly users!: Users
  protected readonly contractCategories!: ValueEntity[]
  protected readonly contractTypes!: ValueEntity[]
  protected readonly contractSubtypes!: ValueEntity[]
  protected readonly departments!: Department[]
  protected readonly facilities!: Facility[]
  protected readonly statusList!: ValueEntity[]
  protected readonly documentMembers!: ValueEntity[]
  protected readonly workflowTypes!: ValueEntity[]
  protected readonly priorities!: ValueEntity[]
  protected readonly currencies!: string[]
  protected readonly accountTypes!: ValueEntity[]
  protected readonly paymentTypes!: ValueEntity[]
  protected readonly documentTypes!: ValueEntity[]
  protected readonly deliveryTypes!: ValueEntity[]

  public get partnersData(): ValueEntity[] {
    return this.partners.select(item => item.toData()).toArray()
  }

  public get usersData(): ValueEntity[] {
    return this.users.select(user => user.toData()).toArray()
  }
}
