import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Type } from 'class-transformer'

export class Stage extends ValueEntity {
  @Type(() => ValueEntity)
  private memberType: ValueEntity
  constructor()
  constructor(other: Stage)
  constructor(other: ValueEntity, memberType: ValueEntity)
  constructor(other?: ValueEntity, memberType?: ValueEntity) {
    const origin = other as Stage | undefined
    super(origin ?? new ValueEntity())
    this.memberType = origin?.memberType ?? memberType ?? new ValueEntity()
  }
  public equalsByMember(memberType: ValueEntity): boolean {
    return this.memberType.equalsById(memberType)
  }
}