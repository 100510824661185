
import { Component, Prop, Vue } from 'vue-property-decorator'
import FloatLabel from './FloatLabel.vue'
@Component({
  components: {
    FloatLabel,
  },
})
export default class FLTextArea extends Vue {
  @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: String, default: '' }) public value!: string
  @Prop({ type: String, default: '' }) public defaultValue!: string
  @Prop({ type: Boolean, default: false }) public allowClear!: boolean
  @Prop({ type: [Object, Boolean], default: false }) public autoSize!: any | boolean
  protected hasFocus = false

  public get hasValue(): boolean {
    return !!this.value && this.value !== this.defaultValue
  }

  public onChange(event: InputEvent): void {
    this.$emit('change', event)
    // eslint-disable-next-line
    this.$emit('input', (event.target as any)?.value || '')
  }
  public onPressEnter(event: KeyboardEvent): void {
    this.$emit('press-enter', event)
  }
  public onFocus(event: FocusEvent): void {
    this.hasFocus = true
    this.$emit('focus', event)
  }
  public onBlur(event: FocusEvent): void {
    this.hasFocus = false
    this.$emit('blur', event)
  }
}
