import { Address } from '@/domain/addresses/Address'
import { AddressType } from '@/domain/addresses/AddressType'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { DeserilazableRequest } from '@/api/requests/DeserilazableRequest'
import { Request } from '@/api/requests/Request'
import { Collection, ICollection } from '@/global/models/Collection'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { PartnerCollection } from '@/partners/models/PartnerCollection'
import { Reporters } from '@/partners/reporters/Reporters'
import { first } from '@/services/utils'
import { User } from '@/infrastucture/users/User'
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex'
import { IManualsState, RootState } from './types'
import { Users } from '@/infrastucture/users/Users'
import _ from 'lodash'

const state: IManualsState = {
  isInit: false,
  partners: new PartnerCollection([]),
  reporters: new Reporters([]),
  addresses: new Collection<Address>([]),
  addressTypes: new Collection<AddressType>([]),
  users: new Users(new User()),
  contractCategories: [],
  contractTypes: [],
  contractSubtypes: [],
  departments: [],
  facilities: [],
  statusList: [],
  documentMembers: [],
  objectiveMembers: [],
  workflowTypes: [],
  currencies: ['рубли', 'доллары', 'евро'],
  priorities: [],
  accountTypes: [],
  paymentTypes: [],
  documentTypes: [],
  deliveryTypes: [],
}

const getters: GetterTree<IManualsState, RootState> = {
  isInit(state: IManualsState) {
    return state.isInit
  },
  priorities(state: IManualsState) {
    return state.priorities
  },
  objectiveMembers(state: IManualsState) {
    return state.objectiveMembers
  },
  reporters(state: IManualsState) {
    return state.reporters
  },
  currencies(state: IManualsState) {
    return state.currencies
  },
  partners(state: IManualsState) {
    return state.partners
  },
  addresses(state: IManualsState) {
    return state.addresses
  },
  addressTypes(state: IManualsState) {
    return state.addressTypes
  },
  users(state: IManualsState) {
    return state.users
  },
  contractCategories(state: IManualsState) {
    return state.contractCategories
  },
  contractTypes(state: IManualsState) {
    return state.contractTypes
  },
  contractSubtypes(state: IManualsState) {
    return state.contractSubtypes
  },
  departments(state: IManualsState) {
    return state.departments
  },
  facilities(state: IManualsState) {
    return state.facilities
  },
  activeFacilities(state: IManualsState) {
    return _.filter(state.facilities, facility => facility.isActive)
  },
  statusList(state: IManualsState) {
    return state.statusList
  },
  documentMembers(state: IManualsState) {
    return state.documentMembers
  },
  workflowTypes(state: IManualsState) {
    return state.workflowTypes
  },
  accountTypes(state: IManualsState) {
    return state.accountTypes
  },
  paymentTypes(state: IManualsState) {
    return state.paymentTypes
  },
  documentTypes(state: IManualsState) {
    return state.documentTypes
  },
  deliveryTypes(state: IManualsState) {
    return state.deliveryTypes
  },
  valueEntityByTitle(state: IManualsState) {
    return (collection: string, title: string) => first(state[collection] as ValueEntity[]  || [], item => item.toString() === title)
  }
}

const mutations: MutationTree<IManualsState> = {
  setReporters(state, payload: Reporters) {
    state.reporters = payload
  },
  setPartners(state, payload: PartnerCollection) {
    state.partners = payload
  },

  setAddresses(state, payload: ICollection<Address>) {
    state.addresses = payload
  },
  setUsers(state, payload: Users) {
    state.users = payload
  }
}
const actions: ActionTree<IManualsState, RootState> = {
  setReporters({ commit }, payload: Reporters) {
    commit('setReporters', payload)
  },
  setPartners({ commit }, payload: PartnerCollection) {
    commit('setPartners', payload)
  },
  setAddresses({ commit }, payload: ICollection<Address>) {
    commit('setAddresses', payload)
  },
  setUsers({ commit }, payload: Users) {
    commit('setUsers', payload)
  },
  setCollection({ state }, { name, collection }) {
    state[name] = collection
  },
  setInit({ state }, value: boolean) {
    state.isInit = value || false
  },
  async reload({ state }, { collection, uri, deserializationType }) {
    const request = new DeserilazableRequest(new AuthorizationRequest(new Request(uri)), deserializationType)
    const response = await request.response()
    state[collection] = response.data
  },
}

export const manuals: Module<IManualsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
