import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { ITableSource } from '@/global/table/models/TableCollection'
import { Collection } from '@/global/models/Collection'
import { DepartmentData } from './DepartmentData'

export class DepartmentTableSource extends Collection<DepartmentData> implements ITableSource<DepartmentData> {
	public constructor(array: DepartmentData[]) {
		super(array)
	}

  public columns(): ITableColumn<DepartmentData>[] {
		return [
			new TableColumn('addButton', 'empty', 20, false, false, undefined, { title: 'addButton' }),
			new TableColumn<DepartmentData>('Наименование', 'itemTitle', '250px', false, false, { customRender: 'toString' }),
			new TableColumn<DepartmentData>('Буквенный код', 'codeLiteral', '250px', false, false, { customRender: 'toString' }),
      new TableColumn<DepartmentData>('Руководитель отдела', 'manager', '250px', false, false, { customRender: 'toString' }),
		]
	}

  public source(): DepartmentData[] {
    return this.toArray()
  }
}