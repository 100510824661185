import { ICollection } from '@/global/models/Collection'
import { entityComparer } from '@/global/models/EntityCollection'
import { DecoratableReporter } from './DecoratableReporter'
import { Reporter } from './Reporter'

class BaseRecordReporter extends DecoratableReporter {
  protected collection: ICollection<Reporter>
  public constructor(origin: Reporter, collection: ICollection<Reporter>) {
    super(origin)
    this.collection = collection
  }
}

export class RecordReporter extends BaseRecordReporter {
  public async transfer(): Promise<void> {
    await super.transfer()
    this.collection.update(new Reporter(this), entityComparer) // обновили во всех
  }
}

export class InsertableRecordReporter extends BaseRecordReporter {
  public async transfer(): Promise<void> {
    await super.transfer()
    this.collection.push(new Reporter(this.origin)) // добавили во всех
  }
}

export class RemovableRecordReporter extends BaseRecordReporter {
  public async transfer(): Promise<void> {
    await super.transfer()
    this.collection.remove(new Reporter(this), entityComparer) // удалили из всех
  }
}
