import { IField } from '@/global/models/Field'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import _ from 'lodash'
import { Moment } from 'moment'

export enum ObjectiveType {
  workflow = 'Рабочий процесс',
  contract = 'По документу - Договоры',
  document = 'По документу - Корреспонденция',
  bills = 'По документу - Финансы',
  default = 'Без документа'
}

export const objectiveTypeList = _.filter(Object.values(ObjectiveType), (item) => item !== ObjectiveType.default)

export interface ObjectiveData {
  // eslint-disable-next-line
  [key: string]: any
  itemType: ObjectiveType
  title: string
  itemTitle: string
  description: string
  start: Moment | null
  finish: Moment | null
  initiator: ValueEntity,
  assignedTo: ValueEntity,
  reciever: ValueEntity,
  percentComplete: number
  priority: ValueEntity
  status: ValueEntity
  id: number
}

export interface DocumentObjectiveData extends ObjectiveData {
  document: ValueEntity
}

export interface ObjectiveFields {
  title: IField<string>
  description: IField<string>
  start: IField<Moment | null>
  finish: IField<Moment | null>
  priority: IField<ValueEntity>
  initiator: IField<ValueEntity>
  assignedTo: IField<ValueEntity>
  hasReciver: IField<boolean>
  reciever: IField<ValueEntity>
}
