import { Entity } from '@/global/models/Entity'
import { User } from '@/infrastucture/users/User'
import { Type } from 'class-transformer'
import { Dictionary } from 'lodash'
import { IEntity } from './IEntity'

export class UserAccess implements IEntity {
  constructor(claims: Dictionary<string> = {}, user: User = new User()) {
    this.claims = claims
    this.user = user
  }

  public claims: Dictionary<string>
  @Type(() => User)
  public user: User

  public get id(): number {
    return this.user.id
  }

  public get isAny(): boolean {
    return this.user.isAny
  }

  public addIdentity(id: number): void {
    this.user.addIdentity(id)
  }

  public equals(other: Entity): boolean {
    return this.user.equals(other)
  }
  
  public equalsById(other: IEntity): boolean {
    return this.user.equalsById(other)
  }
  /* public hasAccess(document: IIncludable<User>, moduleName: ModuleName): boolean {
    var moduleNameValue = moduleName.MemberValue()
    return Claims.ContainsKey(moduleNameValue) && (Claims[moduleNameValue] == AccessType.Full.MemberValue() || (Claims[moduleNameValue] == AccessType.Standard.MemberValue() && document.Has(User)))
  } */
}