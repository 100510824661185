import { forEach as _forEach, map as _map } from 'lodash'
import { Moment } from 'moment'
import { IField } from './Field'

function fieldToDict<T>(fields: IField<T>[]) {
  const keyValues = _map(fields, (field) => {
    return [field.key, field] as [string, IField<T>]
  })
  return new Map<string, IField<T>>(keyValues)
}

export class Fieldset<T = number> {
  private _key: T
  private _textFields: IField<string>[]
  private _dateFields: IField<Moment | null>[]
  protected textFields: Map<string, IField<string>>
  protected dateFields: Map<string, IField<Moment | null>>
  public constructor(other: Fieldset<T>)
  public constructor(key: T, textFields: IField<string>[], dateFields: IField<Moment | null>[])
  public constructor(...args: unknown[]) {
    const key = (args[0] as Fieldset<T>)._key ?? (args[0] as T)
    const textFields = (args[0] as Fieldset<T>)._textFields ?? (args[1] as IField<string>[])
    const dateFields = (args[0] as Fieldset<T>)._dateFields ?? (args[2] as IField<Moment | null>[])
    this._key = key
    this._textFields = textFields
    this._dateFields = dateFields
    this.textFields = fieldToDict(textFields)
    this.dateFields = fieldToDict(dateFields)
  }

  public get key(): T {
    return this._key
  }

  public get isAny(): boolean {
    return !this.key
  }

  public equals(other: Fieldset<T>): boolean {
    return this.key === other.key
  }

  protected rangeFields<T>(keys: string[], dict: Map<string, IField<T>>): IField<T>[] {
    const list: IField<T>[] = []
    _forEach(keys, (key) => {
      const field = dict.get(key)
      if (field) list.push(field)
    })
    return list
  }

  protected content(key: string): string {
    return this.textFields.get(key)?.content() || ''
  }

  protected dateContent(key: string): Moment | null {
    return this.dateFields.get(key)?.content() || null
  }

  public allFields(): IField<string | Moment | null>[] {
    return [...this._textFields, ...this._dateFields]
  }
}
