import { IField } from '@/global/models/Field'
import { Fieldset } from '@/global/models/Fieldset'
import { Reporter } from './Reporter'

export class DecoratableReporter extends Reporter {
  protected origin: Reporter
  public constructor(origin: Reporter) {
    super(origin)
    this.origin = origin
  }

  public asForm(): Fieldset {
    return this.origin.asForm()
  }

  public asFields(): IField<string>[] {
    return this.origin.asFields()
  }

  public async transfer(): Promise<void> {
    await this.origin.transfer()
  }
}