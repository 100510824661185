import { IEntity } from '@/infrastucture/IEntity'
import { find as _find } from 'lodash'
import { ISection } from '../buttonPanel/viewModels/Section'

export interface ITab extends ISection {
  // counters: Counter[]
  disabled: boolean
  // updateCount(): Promise<void>
}
export interface IViewCardContent {
  currentTab: string
  tabs: ITab[]
  byTitle(title: string): ITab
  // updateCounters(): Promise<void>
}

export class ContentTab implements ITab {
  public key: string
  public title: string
  public constructor(key: string, title: string) {
    this.key = key
    this.title = title
  }

  public get disabled(): boolean {
    return false
  }
}

export class ItemContentTab<T extends IEntity> extends ContentTab {
  private readonly item: T
  public constructor(key: string, title: string, item: T) {
    super(key, title)
    this.item = item
  }

  public get disabled(): boolean {
    return this.item.isAny
  }
}

export class ViewCardContent implements IViewCardContent {
  public tabs: ITab[]
  public constructor(tabs: ITab[], public currentTab: string) {
    this.tabs = tabs
  }

  public byTitle(title: string): ITab {
    return _find(this.tabs, (tab) => tab.title === title) || new ContentTab('empty', '')
  }
}