
import { ITableSource } from '@/global/table/models/TableCollection'
import { Component, Prop, Vue } from 'vue-property-decorator'
import FLInput from '@/global/components/FLInput.vue'
import FLSelect from '@/global/components/FLSelect.vue'
import { ITableColumn } from '@/global/table/viewModels/Column'
import { IField } from '@/global/models/Field'

@Component({
  components: {
    FLInput,
    FLSelect,
  },
})
export default class CardTable<T> extends Vue {
  @Prop({ type: Object, required: true }) public collection!: ITableSource<T>
  @Prop({ type: Array, default: () => [] }) public fieldList!: IField<unknown>[]
  @Prop({ type: Boolean, default: false }) public loading!: boolean

  protected columns: ITableColumn<T>[]

  public constructor() {
    super()
    this.columns = this.collection.columns()
  }

  public get invalid(): boolean {
    return this.$validator.fields.items.some((field) => field.flags.invalid || false)
  }
}
