import { map as _map } from 'lodash'
import { Request } from '@/api/requests/Request'
import { ReporterEntity } from '../models/PartnerJson'
import { Reporter } from './Reporter'
import { IAsyncCollection } from '@/global/models/AsyncCollection'
import { EntityCollection } from '@/global/models/EntityCollection'
import { Partner } from '../models/Partner'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'

export class Reporters extends EntityCollection<Reporter> implements IAsyncCollection<Reporter> {
  public static async createAsync(): Promise<Reporters> {
    const request = new AuthorizationRequest(new Request<ReporterEntity[]>('/api/reporters'))
    const response = await request.response()
    const reporters = _map(response.data, (entity) => Reporter.createFromData(entity))
    return new Reporters(reporters)
  }

  public forPartner(partner: Partner): Reporters {
    return new Reporters(this.where((reporter) => partner.hasReporter(reporter)).toArray())
  }

  public async addAsync(element: Reporter, index = this.count): Promise<Reporter> {
    this.insert(element, index)
    return element
  }

  public async updateAsync(element: Reporter): Promise<void> {
    this.update(element)
  }

  public async removeAsync(element: Reporter): Promise<void> {
    this.remove(element)
  }
}