<script>
export default {
  // eslint-disable-next-line
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        version="1.1"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 50 50"
      >
        <g id="Слой_x0020_1">
          <path
            fill="currentColor"
            d="M25 0c-13.81,0 -25,11.19 -25,25 0,13.81 11.19,25 25,25 13.81,0 25,-11.19 25,-25 0,-13.8 -11.19,-25 -25,-25zm-6.53 25.43l-3.88 0 0 3.04 3.88 0 0 2.03 -3.88 0 0 3.05 3.88 0 0 5.3 3.25 0 0 -5.3 5.05 0 0 -3.05 -5.05 0 0 -2.03 0.73 0 0 -0.09 2.91 0c3.08,0 5.53,-0.84 7.34,-2.51 1.81,-1.67 2.71,-3.83 2.71,-6.45 0,-2.63 -0.81,-4.66 -2.44,-6.11 -1.63,-1.44 -3.93,-2.16 -6.89,-2.16l-7.61 0 0 14.28zm3.25 -11.35l3.8 0c4.32,0 6.48,1.83 6.48,5.49 0,1.87 -0.58,3.31 -1.76,4.34 -1.17,1.02 -2.89,1.53 -5.13,1.53l-2.66 0 0 -0.02 -0.73 0 0 -11.34 0 0zm3.28 31.68c-11.46,0 -20.76,-9.3 -20.76,-20.76 0,-11.46 9.3,-20.76 20.76,-20.76 11.46,0 20.76,9.3 20.76,20.76 0,11.46 -9.3,20.76 -20.76,20.76z"
          />
        </g>
      </svg>
    )
  },
}
</script>