import { DateInterval } from '@/infrastucture/DateInterval'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Parties } from './Parties'
import { Summ } from './Summ'
import { WeaklyRegistration } from './WeaklyRegistration'
import { Document } from './Document'
import { ContractData } from '@/modules/ContractData'
import { WorkflowProcess } from './WorkflowProcess'
import { Type } from 'class-transformer'
import moment from 'moment'
import _ from 'lodash'
import { Registration } from './Registration'
import store from '@/store'
import { Member } from './Member'
import { Users } from '@/infrastucture/users/Users'
import { valueEntityByTitle } from '@/store/models/Manuals'
import { partnerByReporter } from '@/services/cardUtils'

export class Contract extends Document {
	@Type(() => ValueEntity)
	private category: ValueEntity
	@Type(() => ValueEntity)
	private type: ValueEntity
	@Type(() => ValueEntity)
	private subtype: ValueEntity
	@Type(() => DateInterval)
	private interval: DateInterval
	private isSigned: boolean
	private partnerIsSigned: boolean
	@Type(() => WeaklyRegistration)
	private contractRegistration: WeaklyRegistration
	private note: string
	@Type(() => ValueEntity)
	private baseContract: ValueEntity
	@Type(() => Summ)
	private summ: Summ
	@Type(() => Parties)
	private parties: Parties
	constructor()
	constructor(other: Contract)
	constructor(origin: Document, category: ValueEntity, type: ValueEntity, subtype: ValueEntity, interval: DateInterval, isSigned: boolean, partnerIsSigned: boolean, registration: WeaklyRegistration, note: string, summ: Summ, parties: Parties)
	constructor(origin: Document, category: ValueEntity, type: ValueEntity, subtype: ValueEntity, interval: DateInterval, isSigned: boolean, partnerIsSigned: boolean, registration: WeaklyRegistration, note: string, summ: Summ, parties: Parties, baseContract: ValueEntity)
	constructor(origin?: Document, category?: ValueEntity, type?: ValueEntity, subtype?: ValueEntity, interval?: DateInterval, isSigned?: boolean, partnerIsSigned?: boolean, registration?: WeaklyRegistration, note?: string, summ?: Summ, parties?: Parties, baseContract?: ValueEntity) {
		const other = origin as Contract | undefined
		super(other ?? new Document())
		this.category = other?.category ?? category ?? new ValueEntity()
		this.type = other?.type ?? type ?? new ValueEntity()
		this.subtype = other?.subtype ?? subtype ?? new ValueEntity()
		this.interval = other?.interval ?? interval ?? new DateInterval()
		this.isSigned = other?.isSigned ?? isSigned ?? false
		this.partnerIsSigned = other?.partnerIsSigned ?? partnerIsSigned ?? false
		this.contractRegistration = other?.contractRegistration ?? registration ?? new WeaklyRegistration()
		this.note = other?.note ?? note ?? ''
		this.summ = other?.summ ?? summ ?? new Summ()
		this.parties = other?.parties ?? parties ?? new Parties()
		this.baseContract = other?.baseContract ?? baseContract ?? new ValueEntity()
	}

	public static create(): Contract {
		const status = valueEntityByTitle('statusList', 'Проект')
		const users: Users = store.getters['manuals/users']
		const members = [new Member(users.currentUser, valueEntityByTitle('documentMembers', 'Инициатор'))]
		const document = new Document(new ValueEntity(), new Registration(), status, valueEntityByTitle('workflowTypes', 'Договор - стандарт'), '', members)
		const contract = new Contract(document, valueEntityByTitle('contractCategories', 'Основной договор'), new ValueEntity(), new ValueEntity(),
			new DateInterval(), false, false, new WeaklyRegistration(), '', new Summ(), new Parties())
		return contract
	}

	public static get any(): Contract {
		return defaultDocument
	}

	public toData(): ContractData {
		const partner = partnerByReporter(this.parties.reporter.id)
		const title = this.isAny || !partner ? this.title : `${partner.toString()}-${this.registration.registrationNumber}`
		return {
			id: this.id,
			itemTitle: title,
			registrationData: moment(this.registration.registrationDate),
			category: this.category,
			type: this.type,
			subtype: this.subtype,
			startDate: moment(this.interval.start).isSameOrBefore(moment(0)) ? null : moment(this.interval.start),
			finishDate: moment(this.interval.finish).isSameOrBefore(moment(0)) ? null : moment(this.interval.finish),
			storage: this.storage,
			registrationNumber: this.registration.registrationNumber,
			validNumber: this.contractRegistration.validRegistrationNumber,
			comment: this.note,
			summ: this.summ.amount,
			currency: this.summ.currency,
			codeBudget: this.summ.budgetCode,
			ndsSumm: this.summ.vatAmount,
			reporter: this.parties.reporter.toData(),
			enterprise: this.parties.facility,
			baseContract: this.baseContract,
			verification: this.parties.partnerVerification,
			departmentTitle: 'ОИТ',
			initiator: this.member('Инициатор').toData(),
			developer: this.member('Юрист').toData(),
			approvers: _.map(this.membersByType('Согласующий'), member => member.toData()),
			validator: this.member('Утверждающий').toData(),
			executor: this.member('Ответственный').toData(),
			isSigned: this.isSigned,
			partnerIsSigned: this.partnerIsSigned,
		}
	}
	// eslint-disable-next-line
	public generateNumber(id: number): void {
		//
	}
}

const defaultDocument = new Contract()

export class ContractWorkflowProcess extends WorkflowProcess<Contract> {
  protected type
	constructor()
	constructor(origin: WorkflowProcess<Contract>)
	constructor(origin: WorkflowProcess<Contract>, document: Contract)
	constructor(origin?: WorkflowProcess<Contract>, document?: Contract) {
		if (!origin) super(new Contract())
		else if (!document) super(origin)
		else super(origin, document)
		this.type = Contract
	}

	public static create(): ContractWorkflowProcess {
		const document = Contract.create()
		return new ContractWorkflowProcess(new WorkflowProcess(document), document)
	}

	public toData(): ContractData {
		return this.document.toData()
	}
}
