import { Entity } from '@/global/models/Entity'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { Person } from '@/infrastucture/users/Person'
import { Type } from 'class-transformer'
import moment, { Moment } from 'moment'
import { IProcessStage } from './IProcessStage'
import { Member } from './Member'

export class ObjectiveStage extends Entity implements IProcessStage {
  @Type(() => ValueEntity)
  private stageType: ValueEntity
  @Type(() => Date)
  private start: Date
  @Type(() => Date)
  private finish: Date | undefined
  @Type(() => Member)
  private member: Member
  constructor()
  constructor(other: ObjectiveStage)
  constructor(origin: Entity, stageType: ValueEntity, start: Date, finish: Date | undefined, member: Member)
  constructor(origin?: Entity, stageType?: ValueEntity, start?: Date, finish?: Date | undefined, member?: Member) {
    const other = origin as ObjectiveStage | undefined
    super(other?.id ?? 0)
    this.stageType = other?.stageType ?? stageType ?? new ValueEntity()
    this.start = other?.start ?? start ?? moment(0).toDate()
    this.finish = other?.finish ?? finish
    this.member = other?.member ?? member ?? new Member()
  }

  public get isCompleted(): boolean {
    return !!this.finish && moment(this.finish).isAfter(moment(0))
  }

  public completed(): ObjectiveStage {
    return new ObjectiveStage(this, this.stageType, this.start, moment().toDate(), this.member)
  }

  public completeDate(): Moment | null {
    return this.finish ? moment(this.finish) : null
  }

  public equals(other: ValueEntity): boolean
  public equals(other: ObjectiveStage): boolean
  public equals(member: Member): boolean
  public equals(other: Person): boolean
  public equals(arg: Member | Person | ObjectiveStage | ValueEntity): boolean {
    return (arg instanceof Person && arg.equalsById(this.member)) ||
      (arg instanceof ObjectiveStage && this.equalsById(arg)) || (arg instanceof ValueEntity && this.stageType.equalsById(arg))
  }

  public toString(): string {
    return this.stageType.toString()
  }
}