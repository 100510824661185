import { DocumentObjectiveData, ObjectiveType } from '@/modules/types'
import { Bill } from './Bill'
import { Contract } from './Contract'
import { Document } from './Document'
import { DocumentObjective } from './DocumentObjective'
import { IncomingDocument } from './IncomingDocument'
import { OutgoingDocument } from './OutgoingDocument'

export class WorkflowObjective<T extends Document = Document> extends DocumentObjective<T> {
  public toString(): string {
    return `Задача этапа рабочего процесса (${this.title})`
  }

  public toData(): DocumentObjectiveData {
    const base = super.toData()
    base.itemType = ObjectiveType.workflow
    return base
  }
}

export class ContractWorkflowObjective extends WorkflowObjective<Contract> {
  protected type = Contract
}

export class BillWorkflowObjective extends WorkflowObjective<Bill> {
  protected type = Bill
}

export class IncomingDocumentWorkflowObjective extends WorkflowObjective<IncomingDocument> {
  protected type = IncomingDocument
}

export class OutgoingDocumentWorkflowObjective extends WorkflowObjective<OutgoingDocument> {
  protected type = OutgoingDocument
}