import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PutRequest } from '@/api/requests/PutRequest'
import { Request } from '@/api/requests/Request'
import { Entity } from '@/global/models/Entity'
import { Person } from '@/infrastucture/users/Person'
import { Expose, instanceToPlain, Type } from 'class-transformer'
import _ from 'lodash'
import moment from 'moment'

export class Comment extends Entity {
  @Expose({ name: 'author' })
  @Type(() => Person)
  private _author: Person

  @Expose({ name: 'created' })
  @Type(() => Date)
  private _created: Date

  @Type(() => Person)
  private currentUser: Person

  @Expose({ name: 'note' })
  private _note: string
  
  @Type(() => Person)
  private notifiers: Person[]
  constructor()
  constructor(id: number, author: Person, created: Date, currentUser: Person, note: string, notifiers: Person[])
  constructor(id?: number, author?: Person, created?: Date, currentUser?: Person, note?: string, notifiers?: Person[]) {
    super(id || 0)
    this._author = author ?? new Person()
    this._created = created ?? moment(0).toDate()
    this.currentUser = currentUser ?? new Person()
    this._note = note ?? ''
    this.notifiers = notifiers ?? []
  }

  public get isUnreaded(): boolean {
    return _.some(this.notifiers, item => item.equalsById(this.currentUser))
  }

  public get author(): string {
    return this._author.toString()
  }

  public get created(): string {
    return moment(this._created).format('DD.MM.YYYY')
  }

  public get note(): string {
    return this._note
  }

  public async read(): Promise<void> {
    if (!this.isUnreaded) return
    const json = instanceToPlain(this)
    const request = new AuthorizationRequest(new PutRequest(new Request('api/comments/setReaded'), json))
    await request.response()
    const i = _.findIndex(this.notifiers, item => item.equalsById(this.currentUser))
    this.notifiers.splice(i, 1)
  }
}