
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import CardContent from '@/global/components/CardContent.vue'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { DocumentObjectiveProcess } from '@/domain/ObjectiveProcess'
import { handleAxiosError } from '@/services/utils'
import { IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import Comments from '@/infrastucture/components/Comments.vue'
import ObjectiveCardContent from '@/modules/components/ObjectiveCardContent.vue'
import { ProcessSlot } from '@/workflow/ProcessSlot'
import { ProcessTitlesFactory } from '@/workflow/ProcessTitlesFactory'
import Stepper from '@/infrastucture/components/Stepper.vue'

@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    CardContent,
    FieldSet,
    Comments,
    ObjectiveCardContent,
    Stepper,
  },
})
export default class DocumentObjectiveCard extends Vue {
  @Prop({ type: Object }) public process!: DocumentObjectiveProcess
  private loading = false
  protected buttonPanel: ButtonPanelSource
  protected qaSection: IButtonSlot
	constructor() {
    super()
    this.buttonPanel = ButtonPanelSource.documentObjectiveCreate()
    this.qaSection = new ProcessSlot(this.process, new ProcessTitlesFactory())
	}

  public get itemId(): number {
    return this.process.id
  }

  @Watch('itemId', { immediate: true })
  public async onChange(): Promise<void> {
    // await this.loadObjectives()
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }
  }
}
