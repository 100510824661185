import { Section } from '@/global/buttonPanel/viewModels/Section'
import { EntityCollection } from '@/global/models/EntityCollection'
import { IFilteredTableSource } from '@/global/table/models/TableCollection'
import { Vue } from 'vue-property-decorator'
import { IEntity } from '../IEntity'

export default abstract class Module<T extends IEntity, TData = T> extends Vue {
  protected itemIsVisible = false
  private _rowSelectedItem: T | null
  protected abstract sections: Section[]
  protected abstract selectedItem: T | null
  // protected abstract get anyItem(): T
  public abstract items: EntityCollection<T>
  public constructor() {
    super()
    this._rowSelectedItem = null
  }

  public abstract dataCollection: IFilteredTableSource<TData>

  public abstract onChange(): Promise<void>

  protected get rowSelectedItem(): T | null {
    return this._rowSelectedItem
  }

  protected set rowSelectedItem(value: T | null ) {
    this._rowSelectedItem = value
  }

  public abstract anyItem(): T

  public openCard(key: number): void {
    if (key === -1) {
      this.selectedItem = this.anyItem()
      this.itemIsVisible = true
    } else {
      const item = this.items.byId(key)
      if (!item) return
      this.selectedItem = item
      this.itemIsVisible = true
    }
  }

  public closeCard(): void {
    this.itemIsVisible = false
    this.selectedItem = null
  }
}