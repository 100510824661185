import { Objective } from './Objective'
import { Document } from './Document'
import { Exclude, Type } from 'class-transformer'
import { DocumentObjectiveData, ObjectiveType } from '@/modules/types'
import { Contract } from './Contract'
import { Bill } from './Bill'
import { IncomingDocument } from './IncomingDocument'
import { OutgoingDocument } from './OutgoingDocument'

export class DocumentObjective<T extends Document = Document> extends Objective {
  @Exclude()
	// eslint-disable-next-line
  protected type: Function = Document
	// eslint-disable-next-line
	@Type((options: any) => (options?.newObject as DocumentObjective<T>).type)
  private document: T
  constructor()
  constructor(other: Objective, document: T)
  constructor(other?: Objective, document?: T) {
    super(other ?? new Objective())
    // eslint-disable-next-line
    this.document = document ?? null as any
  }

  public toData(): DocumentObjectiveData {
    return { ...super.toData(), document: this.document }
  }

  public toString(): string {
    return `Задача по документу: ${this.document.toString()}`
  }
}

export class ContractObjective extends DocumentObjective<Contract> {
  protected type
  constructor() {
    super()
    this.type = Contract
  }

  public toData(): DocumentObjectiveData {
    const base = super.toData()
    base.itemType = ObjectiveType.contract
    return base
  }
}

export class BillObjective extends DocumentObjective<Bill> {
  protected type
  constructor() {
    super()
    this.type = Bill
  }

  public toData(): DocumentObjectiveData {
    const base = super.toData()
    base.itemType = ObjectiveType.bills
    return base
  }
}

export class IncomingDocumentObjective extends DocumentObjective<IncomingDocument> {
  protected type
  constructor() {
    super()
    this.type = IncomingDocument
  }

  public toData(): DocumentObjectiveData {
    const base = super.toData()
    base.itemType = ObjectiveType.document
    return base
  }
}

export class OutgoingDocumentObjective extends DocumentObjective<OutgoingDocument> {
  protected type
  constructor() {
    super()
    this.type = OutgoingDocument
  }

  public toData(): DocumentObjectiveData {
    const base = super.toData()
    base.itemType = ObjectiveType.document
    return base
  }
}
