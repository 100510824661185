import { dateToString, stringToDate } from '@/services/utils'
import { Transform } from 'class-transformer'
import moment from 'moment'

export class Registration {
	constructor()
	constructor(registrationDate: Date, registrationNumber: string)
	constructor(registrationDate?: Date, registrationNumber?: string) {
		this.registrationDate = registrationDate ?? moment().toDate()
		this.registrationNumber = registrationNumber ?? ''
	}

	@Transform(stringToDate, { toClassOnly: true })
  @Transform(dateToString, { toPlainOnly: true })
	public registrationDate: Date
	public registrationNumber: string
}