
import { IModuleRouteVM } from '@/global/viewModels/ModuleRouteVM'
import { Component, /* Prop,  */Vue } from 'vue-property-decorator'
@Component
export default class TopMenu extends Vue {
  public get routerNavClass(): string {
    const routeName = this.currentRoute === 'about' ? 'about' : 'home'
    return `active-${routeName}`
  }

  public get currentRouteMeta(): IModuleRouteVM {
    const viewModel = this.$store.getters['moduleRoute/viewModel']
    return viewModel
  }

  public get currentRoute(): string {
    return this.$route.name || ''
  }

  public toHome(): void {
    if (this.currentRoute === 'about') this.$router.go(-1)
    else this.$router.push('/')
  }
}
