import moment, { Moment } from 'moment'
import { IField } from './Field'
import { Fieldset } from './Fieldset'

export class Row<T = number> extends Fieldset<T> {
  public get Id(): T {
    return this.key
  }
  public has(field: IField<string>): boolean
  public has(field: IField<Moment | null>): boolean
  public has(field: IField<string | Moment | null>): boolean
  public has(anyField: IField<unknown>): boolean {
    if (typeof anyField.content() === 'string') {
      const field = anyField as IField<string>
      const content = this.content(field.key)
      return !field.content() || (!!content && content.trim().includes(field.content().trim()))
    } else {
      const field = anyField as IField<Moment | null>
      const content = this.dateContent(field.key)
      const fieldContent = field.content()
      return !fieldContent || fieldContent.isSameOrBefore(moment.utc(0)) || (!!content && fieldContent.isSame(content))
    }
  }
}
