export class DocumentDescription {
  constructor()
  constructor(copiesCount: number, sheetCount: number, supplementsCount: number, description: string)
  constructor(copiesCount?: number, sheetCount?: number, supplementsCount?: number, description?: string) {
    this.copiesCount = copiesCount ?? 0
    this.sheetCount = sheetCount ?? 0
    this.supplementsCount = supplementsCount ?? 0
    this.description = description ?? ''
  }

  public copiesCount: number
  public sheetCount: number
  public supplementsCount: number
  public description: string
}