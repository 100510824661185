
import { IField, Field } from '@/global/models/Field'
import { Component, Prop, Watch } from 'vue-property-decorator'
import CardContent from '@/global/components/CardContent.vue'
import { ContentTab, ItemContentTab, ViewCardContent } from '@/global/viewModels/ViewCardContent'
import moment, { Moment } from 'moment'
import { ButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { ViewDelegateButton } from '@/global/buttonPanel/viewModels/ViewButton'
import { first, handleAxiosError } from '@/services/utils'
import { Contract, ContractWorkflowProcess } from '@/domain/Contract'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import { ValueEntity } from '@/infrastucture/ValueEntity'
import { CommonFields, ContractData, MembersFields, PartiesFields } from '../ContractData'
import { Partner } from '@/partners/models/Partner'
import { Users } from '@/infrastucture/users/Users'
import { Entity } from '@/global/models/Entity'
import { Document } from '@/domain/Document'
import _ from 'lodash'
import { Registration } from '@/domain/Registration'
import { Member } from '@/domain/Member'
import { User } from '@/infrastucture/users/User'
import { DateInterval } from '@/infrastucture/DateInterval'
import { WeaklyRegistration } from '@/domain/WeaklyRegistration'
import { Parties } from '@/domain/Parties'
import { Summ } from '@/domain/Summ'
import { instanceToPlain, plainToInstance } from 'class-transformer'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { PutRequest } from '@/api/requests/PutRequest'
import { Request } from '@/api/requests/Request'
import { PostRequest } from '@/api/requests/PostRequest'
import Files from '@/files/Files.vue'
import CommentsTab from '@/modules/cards/CommentsTab.vue'
import ObjectivesTab from '@/modules/components/ObjectivesTab.vue'
import ObjectiveCreationCard from '@/modules/cards/ObjectiveCreationCard.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { DocumentObjectiveProcess } from '@/domain/ObjectiveProcess'
import { ProcessTitlesFactory } from '@/workflow/ProcessTitlesFactory'
import { contractsAsync, partnerByReporter, reportersByPartner, userDepartmentField } from '@/services/cardUtils'
import { MemberTypeValue, StageTypeValue } from '@/infrastucture/LookupTypes'
import DocumentCard from './DocumentCard'
import { PartnerCollection } from '@/partners/models/PartnerCollection'
import DocumentButtonPanel from '@/modules/components/DocumentButtonPanel.vue'
import { IProcessTitlesFactory } from '@/workflow/IProcessTitlesFactory'
import { useAbs } from '@/infrastucture/fieldsWithSelecDelegate'
@Component({
  components: {
    CardContent,
    FieldSet,
    Files,
    CommentsTab,
    ObjectivesTab,
    ObjectiveCreationCard,
    ItemDrawer,
    DocumentButtonPanel,
  },
})
/* В констукторе не стоит работать со своими элементами из this, a вот с служебными типо store можно */
export default class ContractCard extends DocumentCard {
  @Prop({ type: Object }) public item!: ContractWorkflowProcess
  @Prop({ type: Boolean, default: false }) public viewOnly!: boolean
  protected creation: ButtonSlot
  protected action: ButtonSlot
  protected cardContent: ViewCardContent
  private titlesFactory: IProcessTitlesFactory
  protected loading = false
  private itemIsVisible = false
  private commonIsInvalid = true
  private partiesIsInvalid = true
  private membersIsInvalid = true
  protected partnerReporters: ValueEntity[]
  protected itemPartner: Partner | null
  protected partner
  protected reporter
  protected ndsSumm
  protected summ
  private contract
  private contracts: Contract[]
  private category

  public constructor() {
    super()
    const creation = new ButtonSlot([
      new ViewDelegateButton('edit', 'edit', 'Редактировать', 'ant-office-btn-link', () => { this.$emit('to-edit') }, () => !this.viewOnly, () => this.viewOnly),
      new ViewDelegateButton('save', 'save', 'Сохранить', 'ant-office-btn-link', async () => await this.save(), () => this.viewOnly || this.invalid(), () => !this.viewOnly),
    ])
    this.creation = creation
    this.action = new ButtonSlot([
      new ViewDelegateButton('create-objective', 'solution', 'Создать задачу', 'ant-office-btn-link', () => { this.openCard() }, () => !this.viewOnly, () => this.viewOnly),
    ])
    this.titlesFactory = new ProcessTitlesFactory()
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('file', 'Файлы', this.item),
      new ItemContentTab('objectives', 'Задачи', this.item),
      new ItemContentTab('comments', 'Обсуждения', this.item),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
    this.contracts = []
    const partner = partnerByReporter(this.itemData.reporter.id)
    this.itemPartner = partner || null
    this.summ = useAbs(new Field(this.itemData.summ, 'summ', { label: 'Сумма договора', min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER }, 'FLInputNumber'))
    this.ndsSumm = useAbs(new Field(this.itemData.ndsSumm, 'ndsSumm', { label: 'Сумма НДС', outerField: this.summ, min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER }, 'VatAmountDropdown'))
    this.contract = new Field(this.itemData.baseContract, 'contract',
      { label: 'Основной договор', valueEntities: this.contracts, labelInValue: true, disabled: !this.item.isAny && !this.itemData.baseContract.isAny }, 'FLSelect', ['required']),
    this.partner = new Field(new ValueEntity(this.itemPartner?.id || 0, this.itemPartner?.toString() || ''), 'partner',
      { label: 'Контрагент', valueEntities: this.partnersDataList, labelInValue: true }, 'FLSelect', ['required'])
    this.partnerReporters = reportersByPartner(this.itemPartner)
    this.reporter = new Field(this.itemData.reporter, 'reporter', { label: 'Контакт контрагента', valueEntities: this.partnerReporters, labelInValue: true }, 'FLSelect', ['required'])
    this.category = new Field(this.itemData.category, 'category',
      { label: 'Категория договора', valueEntities: this.contractCategoriesList, labelInValue: true, disabled: !this.item.isAny }, 'FLSelect', ['required'])
    this.$nextTick(() => {
      this.loadContracts()
    })
  }

  public get document(): Document {
    return _.get(this.item, 'document')
  }

  public get itemId(): number {
    return this.item.id
  }

  protected get partnersCollection(): PartnerCollection {
    return this.$store.getters['manuals/partners']
  }

  protected get partnersDataList(): ValueEntity[] {
    return this.partnersCollection.select(item => item.toData()).toArray()
  }

  protected get contractCategoriesList(): ValueEntity[] {
    return this.$store.getters['manuals/contractCategories']
  }

  public get itemData(): ContractData {
    return this.item.toData()
  }

  public get isSupplemental(): boolean {
    return this.category.content().toString() === 'Доп. соглашение'
  }

  public invalid(): boolean {
    return this.commonIsInvalid || this.partiesIsInvalid || this.membersIsInvalid
  }

  public get common(): CommonFields {
    const currencies = this.$store.getters['manuals/currencies']
    return {
      category: this.category,
      type: new Field(this.itemData.type, 'type', { label: 'Вид договора', valueEntities: this.contractTypes, labelInValue: true }, 'FLSelect', ['required']),
      subtype: new Field(this.itemData.subtype, 'subtype', { label: 'Подвид договора', valueEntities: this.contractSubtypes, labelInValue: true }, 'FLSelect', ['required']),
      startDate: new Field(this.itemData.startDate, 'startDate', { label: 'Дата начала', allowClear: true }, 'FLDatePicker', ['required']),
      finishDate: new Field(this.itemData.finishDate, 'finishDate', { label: 'Дата окончания', allowClear: true }, 'FLDatePicker'),
      storage: new Field(this.itemData.storage, 'storage', { label: 'Место хранения', allowClear: true }, 'FLInput'),
      registrationNumber: new Field(this.itemData.registrationNumber, 'registrationNumber', { label: '№ договора', allowClear: true, disabled: true }, 'FLInput'),
      validNumber: new Field(this.itemData.validNumber, 'validNumber', { label: 'Действующий № договора', allowClear: true, maxLength: 50 }, 'FLInput'),
      comment: new Field(this.itemData.comment, 'comment', { label: 'Комментарий', }, 'FLTextArea'),
      summ: this.summ,
      currency: new Field(this.itemData.currency, 'currency', { label: 'Валюта', labels: currencies, labelInValue: true, }, 'FLSelect', ['required']),
      codeBudget: new Field(this.itemData.codeBudget, 'codeBudget', { label: 'Код бюджета', maxLength: 50 }, 'FLInput'),
      ndsSumm: this.ndsSumm,
    }
  }

  public get commonFields(): IField<string | number | Moment | null | ValueEntity>[] {
    const common = Object.values(this.common)
    if (this.isSupplemental) common.splice(3, 0, this.contract)
    return common
  }

  public get parties(): PartiesFields {
    const verificationLabels = ['Проверка пройдена', 'Обратить внимание', 'Проверка не пройдена']
    return {
      partner: this.partner,
      reporter: this.reporter,
      enterprise: new Field(this.itemData.enterprise, 'enterprise', { label: 'Объект', valueEntities: this.facilities, labelInValue: true }, 'FLSelect', ['required']),
      verification: new Field(this.itemData.verification, 'verification', { label: 'Проверка контрагента', labels: verificationLabels, labelInValue: true }, 'FLSelect', ['required']),
    }
  }

  public get partiesFields(): IField<string | Moment | null | ValueEntity>[] {
    return Object.values(this.parties)
  }

  public get members(): MembersFields {
    const initiator = new Field(this.itemData.initiator, 'initiator', { label: 'Инициатор', valueEntities: this.usersData, labelInValue: true, disabled: true }, 'FLSelect', ['required'])
    const members = {
      initiator: initiator,
      department: userDepartmentField(initiator.content()),
      developer: new Field(this.itemData.developer, 'developer',
        { label: 'Юрист', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.development) }, 'UserSelect', ['required']),
      approvers: new Field(this.itemData.approvers, 'approvers', { label: 'Согласующие', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.approve) },
        'UserSelect'),
      validator: new Field(this.itemData.validator, 'validator',
        { label: 'Утверждающий', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.validation) }, 'UserSelect'),
      executor: new Field(this.itemData.executor, 'executor',
        { label: 'Ответственный', valueEntities: this.usersData, labelInValue: true, disabled: this.item.hasStage(StageTypeValue.execution) }, 'UserSelect'),
      isSigned: new Field(this.itemData.isSigned, 'isSigned', { label: 'Подписан (оригинал)' }, 'FLCheckbox'),
      partnerIsSigned: new Field(this.itemData.partnerIsSigned, 'partnerIsSigned', { label: 'Подписан контрагентом' }, 'FLCheckbox'),
    }
    return members
  }

  public get membersFields(): IField<string | ValueEntity | ValueEntity[] | boolean>[] {
    return Object.values(this.members)
  }
  
  public get selectedPartnerId(): number {
    return this.partner.content().id
  }

  @Watch('item', { immediate: false, deep: false })
  public onChange(): void {
    const tabs = [
      new ContentTab('common', 'Общие сведения'),
      new ItemContentTab('file', 'Файлы', this.item),
      new ItemContentTab('objectives', 'Задачи', this.item),
      new ItemContentTab('comments', 'Обсуждения', this.item),
    ]
    this.cardContent = new ViewCardContent(tabs, 'common')
    const partner = partnerByReporter(this.itemData.reporter.id)
    this.itemPartner = partner || null
    this.summ = useAbs(new Field(this.itemData.summ, 'summ', { label: 'Сумма договора', min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER }, 'FLInputNumber'))
    this.ndsSumm = useAbs(new Field(this.itemData.ndsSumm, 'ndsSumm', { label: 'Сумма НДС', outerField: this.summ, min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER }, 'VatAmountDropdown'))
    this.partner = new Field(new ValueEntity(new Entity(this.itemPartner?.id || 0), this.itemPartner?.toString() || ''), 'partner', { label: 'Контрагент', valueEntities: this.partnersData, labelInValue: true }, 'FLSelect', ['required'])
    this.partnerReporters = reportersByPartner(this.itemPartner)
    this.reporter = new Field(this.itemData.reporter, 'reporter', { label: 'Контакт контрагента', valueEntities: this.partnerReporters, labelInValue: true }, 'FLSelect', ['required'])
    this.category = new Field(this.itemData.category, 'category',
      { label: 'Категория договора', valueEntities: this.contractCategories, labelInValue: true, disabled: !this.item.isAny }, 'FLSelect', ['required'])
    this.contract = new Field(this.itemData.baseContract, 'contract',
      { label: 'Основной договор', valueEntities: this.contracts, labelInValue: true, disabled: !this.item.isAny && !this.itemData.baseContract.isAny }, 'FLSelect', ['required'])
  }

  @Watch('selectedPartnerId', { immediate: false, deep: true })
  public onPartnerChange(value: number, oldValue: number): void {
    if (value === oldValue) return
    this.parties.reporter.update(new ValueEntity())
    this.partnerReporters = reportersByPartner(this.partners.byId(value) || null)
    this.reporter.props.valueEntities = this.partnerReporters
  }

  public async loadContracts(): Promise<void> {
    this.contract.props.loading = true
    const contracts = await contractsAsync()
    this.contracts = _.filter(contracts, item => item.id !== this.itemId)
    this.contract.props.valueEntities = this.contracts
    this.contract.props.loading = false
  }

  public membersList(): Member[] {
    const users: Users = this.$store.getters['manuals/users']
    const userById = (id: number) => {
      const user = users.byId(id) || new User()
      return user
    }
    const initiator = new Member(userById(this.members.initiator.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.initiator))
    const developer = new Member(userById(this.members.developer.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.developer))
    const validator = new Member(userById(this.members.validator.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.validator))
    const approvers = _.map(this.members.approvers.content(), item => new Member(userById(item.id), first(this.documentMembers, item => item.toString() === MemberTypeValue.approvers)))
    const executor = new Member(userById(this.members.executor.content().id), first(this.documentMembers, item => item.toString() === MemberTypeValue.executor))
    const members = [initiator, developer, ...approvers, validator, executor]
    return _.filter(members, item => !item.isAny)
  }

  public async save(): Promise<void> {
    try {
      this.loading = true
      const itemTitle = `${this.parties.partner.content().toString()} ${this.common.validNumber.content().toString()}`
      const members: Member[] = this.membersList()
      const registration = new Registration(this.itemData.registrationData.toDate(), this.common.registrationNumber.content())
      const document = this.document.updated(itemTitle, registration, this.common.storage.content(), members)
      const summ = new Summ(this.common.summ.content(), this.common.codeBudget.content(), this.common.currency.content(), this.common.ndsSumm.content())
      const facility = _.find(this.facilities, item => item.equalsById(this.parties.enterprise.content()))
      const reporter = this.reporters.byId(this.parties.reporter.content().id)
      const start = this.common.startDate.content()
      const finish = this.common.finishDate.content()
      if (!reporter || !start || !facility) throw new Error('Не заполнены обязательные поля')
      const parties = new Parties(this.parties.verification.content(), facility, reporter)
      const interval = new DateInterval(start.toDate(), (finish || moment(0)).toDate())
      const weaklyRegistration = new WeaklyRegistration(registration, this.common.registrationNumber.content())
      const item = new Contract(document, this.common.category.content(), this.common.type.content(),
        this.common.subtype.content(), interval, false, false,
        weaklyRegistration, this.common.comment.content(), summ, parties, this.contract.content())
      if (this.item.isAny) {
        const request = new AuthorizationRequest(new PostRequest(new Request<unknown>('/api/contracts'), instanceToPlain(item)))
        const response = await request.response()
        const process = plainToInstance(ContractWorkflowProcess, response.data)
        this.$emit('added', process)
      }
      else {
        const request = new AuthorizationRequest(new PutRequest(new Request<void>('/api/contracts'), instanceToPlain(item)))
        await request.response()
        this.$emit('updated', item)
      }
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }

  public openCard(): void {
    this.itemIsVisible = true
  }

  public onAddedObjective(item: DocumentObjectiveProcess): void {
    const objectives = this.$refs.objectives as ObjectivesTab | undefined
    objectives?.addDocumentObjective(item)
    this.itemIsVisible = false
  }
}
