import { dateToString, stringToDate } from '@/services/utils'
import { Transform } from 'class-transformer'
import moment from 'moment'

export class DateInterval {
  constructor()
  constructor(start: Date, finish: Date)
  constructor(start?: Date, finish?: Date) {
    this.start = start ?? moment(0).toDate()
    this.finish = finish ?? moment(0).toDate()
  }

  @Transform(stringToDate, { toClassOnly: true })
  @Transform(dateToString, { toPlainOnly: true })
  public start: Date

  @Transform(stringToDate, { toClassOnly: true })
  @Transform(dateToString, { toPlainOnly: true })
  public finish: Date
}