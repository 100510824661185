import { AxiosResponse } from 'axios'
import { Request } from './Request'
import { ClassConstructor, plainToInstance } from 'class-transformer'

export class DeserilazableRequest<T> extends Request<T> {
  private origin: Request<T>
  private deserilazableType: ClassConstructor<T>
  public constructor(origin: Request<T>, deserilazableType: ClassConstructor<T>) {
    super(origin)
    this.origin = origin
    this.deserilazableType = deserilazableType
  }

  public async response(): Promise<AxiosResponse<T>> {
    const response = await this.origin.response()
    const instanse = plainToInstance(this.deserilazableType, response.data)
    response.data = instanse
    return response
  }
}