import { ValueEntity } from '@/infrastucture/ValueEntity'
import { User } from '@/infrastucture/users/User'
import { Type } from 'class-transformer'
import { Stage } from './Stage'

export class Member extends User {
	@Type(() => ValueEntity)
	private memberType: ValueEntity
	constructor()
	constructor(other: User, memberType: ValueEntity)
	constructor(other?: User, memberType?: ValueEntity) {
		super(other?? new User())
		this.memberType = memberType ?? new ValueEntity()
	}

	public equals(type: string): boolean
	public equals(stage: Stage): boolean
	public equals(memberType: ValueEntity): boolean
	public equals(arg: ValueEntity | Stage | string): boolean {
		if (arg instanceof Stage) return arg.equalsByMember(this.memberType)
		if (typeof arg === 'string') return this.memberType.toString() === arg
		return arg.equals(this.memberType)
	}
}