import 'reflect-metadata'
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/services/classComponent'
import 'ant-design-vue/dist/antd.css'
import '@/assets/css/style.scss'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    invalid: 'has-error'
  },
  events: 'input|blur',
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
