
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FLCheckbox extends Vue {
  @Prop({ type: String, default: '' }) public label!: string
  @Prop({ type: Boolean, default: false }) public disabled!: boolean
  @Prop({ type: Boolean, default: 0 }) public value!: boolean

  public onChange(event: Event): void {
    // eslint-disable-next-line
    const value = !!(event.target as any).checked
    this.$emit('change', value)
    this.$emit('input', value)
  }
}
