
import _ from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Document } from '@/domain/Document'
import { WorkflowProcess } from '@/domain/WorkflowProcess'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import { IButtonSlot } from '@/global/buttonPanel/viewModels/ButtonSlot'
import { Section } from '@/global/buttonPanel/viewModels/Section'
import { WorkflowSlot } from '@/workflow/WorkflowSlot'
import { IProcessTitlesFactory } from '@/workflow/IProcessTitlesFactory'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import Stepper from '@/infrastucture/components/Stepper.vue'

@Component({
  components: {
    ButtonPanel,
    ButtonSection,
    Stepper,
  },
})
export default class DocumentButtonPanel extends Vue {
  @Prop({ type: Object, required: true }) public process!: WorkflowProcess<Document>
  @Prop({ type: Object, required: true }) public titlesFactory!: IProcessTitlesFactory
  @Prop({ type: Object, required: true }) public creation!: IButtonSlot
  @Prop({ type: Object, required: true }) public action!: IButtonSlot
  protected buttonPanel: ButtonPanelSource
  protected workflow: IButtonSlot
	constructor() {
    super()
    this.buttonPanel = new ButtonPanelSource([
      new Section('creation', 'Создание'),
      new Section('action', 'Быстрые действия'),
      new Section('workflow', 'Рабочий процесс'),
      new Section('stepper', 'Состояние'),
    ])
    this.workflow = new WorkflowSlot(this.process, this.titlesFactory)
	}

  public get document(): Document {
    return _.get(this.process, 'document')
  }
}
