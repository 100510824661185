import { ITransferable } from './AsyncCollection'
import { Entity } from './Entity'
import { EntityCollection } from './EntityCollection'

export interface IRecord extends ITransferable, Entity {
}

abstract class BaseRecord implements ITransferable {
  protected origin: IRecord
  protected collection: EntityCollection<IRecord>
  public constructor(origin: IRecord, collection: EntityCollection<IRecord>) {
    this.origin = origin
    this.collection = collection
  }

  public abstract transfer(): Promise<void>
}

export class Record extends BaseRecord {
  public async transfer(): Promise<void> {
    this.collection.update(this.origin) // Если нужно создать новый экземпляр от origin-a, то это можно сделать переопределив метод в коллекции
  }
}

export class InsertableRecord extends BaseRecord {
  public async transfer(): Promise<void> {
    this.collection.insert(this.origin, 0)
  }
}

export class RemovableRecord extends BaseRecord {
  public async transfer(): Promise<void> {
    this.collection.remove(this.origin)
  }
}
