export type Action = () => void | Promise<void>
export type BooleanFunc = () => boolean
// eslint-disable-next-line
export type Emitter = (event: string, ...args: any[]) => void

export interface IViewButton {
  key: string
  icon: string
  style: string
  title: string
  disabled: boolean
  visible: boolean
  action(): void | Promise<void>
}
export abstract class ViewButton implements IViewButton {
  public constructor(protected _key: string, protected _icon: string, protected _title: string, protected _style: string) {
  }

  public get key(): string {
    return this._key
  }

  public get icon(): string {
    return this._icon
  }

  public get title(): string {
    return this._title
  }

  public get style(): string {
    return this._style
  }

  public abstract get disabled(): boolean

  public abstract get visible(): boolean

  public abstract action(): void | Promise<void>
}

export class ViewDelegateButton extends ViewButton {
  protected _action: Action
  protected _disabled: BooleanFunc
  protected _visible: BooleanFunc
  public constructor(other: ViewDelegateButton)
  public constructor (key: string, icon: string, title: string, style: string, action: Action, disabled?: BooleanFunc, visible?: BooleanFunc)
  public constructor (...args: unknown[]) {
    const btn = args[0] as ViewDelegateButton
    const key = btn.key || args[0] as string
    const icon = btn.icon || args[1] as string
    const title = btn.title || args[2] as string
    const style = btn.style || args[3] as string
    const action = btn.action || args[4] as Action
    const disabled = btn._disabled || args[5] as BooleanFunc || (() => false)
    const visible = btn._visible || args[6] as BooleanFunc || (() => true)
    super(key, icon, title, style)
    this._action = action
    this._disabled = disabled
    this._visible = visible
  }

  public get disabled(): boolean {
    return this._disabled()
  }

  public get visible(): boolean {
    return this._visible()
  }

  public async action(): Promise<void> {
    await this._action()
  }
}

export class AsyncDelegateButton extends ViewButton {
  protected _action: () => Promise<void>
  protected _disabled: BooleanFunc
  protected _visible: BooleanFunc
  public constructor(other: AsyncDelegateButton)
  public constructor (key: string, icon: string, title: string, style: string, action: () => Promise<void>, disabled?: BooleanFunc, visible?: BooleanFunc)
  public constructor (...args: unknown[]) {
    const btn = args[0] as AsyncDelegateButton
    const key = btn.key || args[0] as string
    const icon = btn.icon || args[1] as string
    const title = btn.title || args[2] as string
    const style = btn.style || args[3] as string
    const action = btn._action || args[4] as () => Promise<void>
    const disabled = btn._disabled || args[5] as BooleanFunc || (() => false)
    const visible = btn._visible || args[6] as BooleanFunc || (() => true)
    super(key, icon, title, style)
    this._action = action
    this._disabled = disabled
    this._visible = visible
  }

  public get disabled(): boolean {
    return this._disabled()
  }

  public get visible(): boolean {
    return this._visible()
  }

  public async action(): Promise<void> {
    await this._action()
  }
}