import { map as _map } from 'lodash'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { Collection } from '@/global/models/Collection'
import { AddressEntity } from '@/partners/models/PartnerJson'
import { Address } from './Address'

export class Addresses extends Collection<Address> {
  public static async createAsync(): Promise<Addresses> {
    const request = new AuthorizationRequest(new Request<AddressEntity[]>('/api/partneraddresses'))
    const response = await request.response()
    const items = _map(response.data, (entity) => Address.createFromData(entity))
    return new Addresses(items)
  }
}
