import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { EntityCollection } from '@/global/models/EntityCollection'
import _ from 'lodash'
import { User } from './User'
import { UserData } from './UserData'

export class Users extends EntityCollection<User> {
  private _currentUser: User
  public constructor(currentUser: User, collection: User[] = []) {
    super(collection)
    this._currentUser = currentUser
  }

  public get currentUser(): User {
    return this._currentUser
  }

  public static async createAsync(): Promise<Users> {
    const request = new AuthorizationRequest(new Request<UserData[]>('/administration/users'))
    const response = await request.response()
    const currentUserRequest = new AuthorizationRequest(new Request<UserData>('/administration/users/currentUser'))
    const currentUserResponse = await currentUserRequest.response()
    const users = _.map(response.data, (userData) => User.createFromData(userData))
    const currentUser = User.createFromData(currentUserResponse.data)
    return new Users(currentUser, users)
  }
}
