import { Field, IField } from '@/global/models/Field'
import { ISerilazable } from '@/global/models/Serilazable'
import { TerritorialEntity } from '@/partners/models/PartnerJson'

export class TerritorialAddress implements ISerilazable<TerritorialEntity> {
  protected country: string
  protected region: string
  protected area: string
  protected settlement: string
  protected street: string
  protected house: string
  protected office: string
  public constructor()
  public constructor(other: TerritorialAddress)
  public constructor(country: string, region: string, area: string, settlement: string, street: string, house: string, office: string)
  public constructor(...args: unknown[]) {
    const other = args[0] as TerritorialAddress | undefined
    const country = other?.country ?? args[0] as string ?? ''
    const region = other?.region ?? args[1] as string ?? ''
    const area = other?.area ?? args[2] as string ?? ''
    const settlement = other?.settlement ?? args[3] as string ?? ''
    const street = other?.street ?? args[4] as string ?? ''
    const house = other?.house ?? args[5] as string ?? ''
    const office = other?.office ?? args[6] as string ?? ''
    this.country = country
    this.region = region
    this.area = area
    this.settlement = settlement
    this.street = street
    this.house = house
    this.office = office
  }

  public entity(): TerritorialEntity {
    return { 
      country: this.country,
      region: this.region,
      area: this.area,
      settlement: this.settlement,
      street: this.street,
      house: this.house,
      office: this.office,
    }
  }

  public asFields(): IField<string>[] {
    return [
      new Field(this.area, 'area', { label: 'Область/Край' }, 'FLInput'),
      new Field(this.settlement, 'settlement', { label: 'Населенный пункт' }, 'FLInput', ['required']),
      new Field(this.street, 'street', { label: 'Улица' }, 'FLInput', ['required']),
      new Field(this.house, 'house', { label: 'Дом' }, 'FLInput', ['required']),
      new Field(this.office, 'office', { label: 'Офис/Помещение' }, 'FLInput'),
    ]
  }

  public toString(): string {
    return `${this.country}, ${this.region}, ${this.area}, ${this.settlement}, ${this.street}, ${this.house}, ${this.office}.`
  }
}