import { IField } from '@/global/models/Field'
import moment, { Moment } from 'moment'
import { Fieldset } from '@/global/models/Fieldset'
import { Partner } from './Partner'
import { Registration } from '@/partners/models/Registration'
import { Activity } from './Activity'
import { InsertablePartner, UpdatablePartner } from './UpdatablePartner'
import { ValidatableForm } from '@/global/models/ValidatableForm'
import { Validator } from 'vee-validate'

export class PartnerForm extends ValidatableForm {
  private _common: IField<string | Moment | null>[]
  private _contacts: IField<string>[]
  private partner: Partner
  public constructor(origin: Fieldset, validator: Validator, partner: Partner) {
    super(origin, validator)
    this._common = this.rangeFields(['itemTitle', 'inn', 'code', 'ogrn', 'kpp', 'fullTitle', 'okved', 'okato', 'oktmo', 'okpo'], this.textFields)
    const regDate = this.dateFields.get('registrationDate')
    if (regDate) this._common.splice(4, 0, regDate)
    this._contacts = this.rangeFields(['email', 'workPhone', 'webSite'], this.textFields)
    this.partner = partner
  }

  public get commonFields(): IField<string | Moment | null>[] {
    return this._common
  }

  public get contactFields(): IField<string>[] {
    return this._contacts
  }

  public async save(): Promise<Partner> {
    const registration = new Registration(this.textFields.get('inn')?.content() || '', moment.utc(this.dateFields.get('registrationDate')?.content() || ''),
      this.textFields.get('kpp')?.content() || '', this.textFields.get('ogrn')?.content() || '', this.textFields.get('code')?.content() || '',
      this.textFields.get('fullTitle')?.content() || '')
    const activity = new Activity(this.textFields.get('okved')?.content() || '', this.textFields.get('okato')?.content() || '', this.textFields.get('oktmo')?.content() || '',
      this.textFields.get('okpo')?.content() || '', '')
    const partner = new UpdatablePartner(this.partner, registration, activity, this.textFields.get('itemTitle')?.content() || '', this.textFields.get('webSite')?.content() || '',
      this.textFields.get('workPhone')?.content() || '', this.textFields.get('email')?.content() || '')
    if (this.partner.isAny) {
      const insertable = new InsertablePartner(partner)
      await insertable.transfer()
      return insertable
    } else {
      await partner.transfer()
      return partner
    }
  }
}
