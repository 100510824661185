
import { Component, Watch } from 'vue-property-decorator'
import Table from '@/global/table/components/Table.vue'
import ButtonPanel from '@/global/buttonPanel/components/ButtonPanel.vue'
import ButtonSection from '@/global/buttonPanel/components/ButtonSection.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { Manuals } from '@/store/models/Manuals'
import { handleAxiosError } from '@/services/utils'
import ModuleWithRoute from '@/infrastucture/components/ModuleWithRoute'
import { Section } from '@/global/buttonPanel/viewModels/Section'
import { EntityCollection } from '@/global/models/EntityCollection'
import { ButtonPanel as ButtonPanelSource } from '@/global/buttonPanel/viewModels/ButtonPanel'
import ObjectiveCardWrapper from './ObjectiveCardWrapper.vue'
import FieldSet from '@/infrastucture/components/FieldSet.vue'
import CheckableButton from '@/infrastucture/components/CheckableButton.vue'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import _ from 'lodash'
import { plainToClass } from 'class-transformer'
import { BillObjectiveProcess, BillWorkflowObjectiveProcess, ContractObjectiveProcess, ContractWorkflowObjectiveProcess, DefaultObjectiveProcess, IncomingDocumentObjectiveProcess, IncomingDocumentWorkflowObjectiveProcess, ObjectiveProcess, OutgoingDocumentObjectiveProcess, OutgoingDocumentWorkflowObjectiveProcess } from '@/domain/ObjectiveProcess'
import { ObjectiveData } from '../types'
import { Objective } from '@/domain/Objective'
import { ObjectiveTableSource } from '@/modules/ObjectiveTableSource'
import { AxiosResponse } from 'axios'
import { Field } from '@/global/models/Field'
@Component({
  components: {
    Table,
    ButtonPanel,
    ButtonSection,
    ItemDrawer,
    ObjectiveCardWrapper,
    FieldSet,
    CheckableButton,
  },
  
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
    const self = this as Objectives
    self.beforeRouteUpdate(to, from, next)
  },
})

export default class Objectives extends ModuleWithRoute<ObjectiveProcess<Objective>, ObjectiveData> {
  protected sections: Section[]
  public items: EntityCollection<ObjectiveProcess<Objective>>
  public dataCollection: ObjectiveTableSource
  protected buttonPanel: ButtonPanelSource
  protected loading = false
  protected selectedItem: ObjectiveProcess<Objective> | null
  public constructor() {
    super('objectives', 'ObjectiveDetail')
    this.items = new EntityCollection()
    this.dataCollection = new ObjectiveTableSource([])
    this.sections = [
      new Section('limitations', 'Сроки'),
      new Section('filters', 'Фильтры'),
    ]
    this.buttonPanel = new ButtonPanelSource(this.sections)
    this.selectedItem = null
  }

  public get isInit(): boolean {
    return this.items.count > 0
  }

  public get topFields(): Field<unknown>[] {
    return this.dataCollection.topFilters()
  }

  @Watch('isInit', { immediate: true })
  public async onChange(): Promise<void> {
    if (this.isInit) return
    await this.loadCollection()
    await super.onChange()
  }

  public async loadCollection(): Promise<void> {
    try {
      this.loading = true
      const manuals = new Manuals(this.$store)
      await manuals.load()
      const items = await this.objectivesList()
      this.items = new EntityCollection(items)
      this.dataCollection = new ObjectiveTableSource(this.items.select(item => item.toData()).toArray())
    } catch (ex) {
      handleAxiosError(ex as Error)
      console.warn(ex)
    } finally {
      this.loading = false
    }
  }

  public requests(): Request<unknown[]>[] {
    return [
      new AuthorizationRequest(new Request<unknown[]>('api/documentObjectiveList/contracts')),
      new AuthorizationRequest(new Request<unknown[]>('api/documentObjectiveList/bills')),
      new AuthorizationRequest(new Request<unknown[]>('api/documentObjectiveList/incomingDocuments')),
      new AuthorizationRequest(new Request<unknown[]>('api/documentObjectiveList/outgoingDocuments')),
      new AuthorizationRequest(new Request<unknown[]>('api/WorkflowObjectiveList/contracts')),
      new AuthorizationRequest(new Request<unknown[]>('api/WorkflowObjectiveList/bills')),
      new AuthorizationRequest(new Request<unknown[]>('api/WorkflowObjectiveList/incomingDocuments')),
      new AuthorizationRequest(new Request<unknown[]>('api/WorkflowObjectiveList/outgoingDocuments')),
    ]
  }

  public async objectivesList(): Promise<ObjectiveProcess<Objective>[]> {
    const payloads = _.map(this.requests(), request => request.response())
    const responses: AxiosResponse<unknown[], unknown>[] = await Promise.all(payloads)
    const contractObjectives = _.map(responses[0].data, (data) => plainToClass(ContractObjectiveProcess, data))
    const billObjectives = _.map(responses[1].data, (data) => plainToClass(BillObjectiveProcess, data))
    const incomingDocumentObjectives = _.map(responses[2].data, (data) => plainToClass(IncomingDocumentObjectiveProcess, data))
    const outgoingDocumentObjectives = _.map(responses[3].data, (data) => plainToClass(OutgoingDocumentObjectiveProcess, data))
    const contractWorkflowObjectives = _.map(responses[4].data, (data) => plainToClass(ContractWorkflowObjectiveProcess, data))
    const billWorkflowObjectives = _.map(responses[5].data, (data) => plainToClass(BillWorkflowObjectiveProcess, data))
    const incomingDocumentWorkflowObjectives = _.map(responses[6].data, (data) => plainToClass(IncomingDocumentWorkflowObjectiveProcess, data))
    const outgoingDocumentWorkflowObjectives = _.map(responses[7].data, (data) => plainToClass(OutgoingDocumentWorkflowObjectiveProcess, data))
    return [
      ...contractObjectives,
      ...billObjectives,
      ...incomingDocumentObjectives,
      ...outgoingDocumentObjectives,
      ...contractWorkflowObjectives,
      ...billWorkflowObjectives,
      ...incomingDocumentWorkflowObjectives,
      ...outgoingDocumentWorkflowObjectives,
    ]
  }

  public anyItem(): ObjectiveProcess<Objective> {
    return new DefaultObjectiveProcess()
  }

  public openForm(item: ObjectiveData): void {
    this.openCard(item.id)
  }

  public openCard(key: number): void {
    super.openCard(key)
  }
}
