
import { ITableSource } from '@/global/table/models/TableCollection'
import { Component, Vue } from 'vue-property-decorator'
import SettingsTable from '@/settings/SettingsTable.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import { EntityCollection, IEntityCollection } from '@/global/models/EntityCollection'
import ViewList from '@/global/models/ViewList'
import { updateEntity } from '@/services/utils'
import { Facility } from '@/domain/Facility'
import { FacilityData } from '@/modules/FacilityData'
import { FacilityTableSource } from '@/modules/FacilityTableSource'
import FacilityCard from './FacilityCard.vue'
@Component({
  components: {
    SettingsTable,
    ItemDrawer,
    FacilityCard,
  },
})
export default class Facilities extends ViewList<Facility, FacilityData> {
  protected selectedItem: Facility
  public constructor() {
    super()
    this.selectedItem = this.anyItem
    Vue.observable(this.facilities)
  }

  protected get anyItem(): Facility {
    return new Facility()
  }

  public get facilities(): Facility[] {
    return this.$store.getters['manuals/facilities']
  }

  public get items(): IEntityCollection<Facility> {
    return new EntityCollection(this.facilities)
  }

  public get dataCollection(): ITableSource<FacilityData> {
    return new FacilityTableSource(this.items.select(item => item.toData()).toArray())
  }

  public openRecord(item: FacilityData): void {
    this.openCard(item.id)
  }

  public onAdded(item: Facility): void {
    this.facilities.splice(0, 0, item)
    this.closeCard()
  }

  public onUpdated(item: Facility): void {
    updateEntity(this.facilities, item)
    this.closeCard()
  }
}
