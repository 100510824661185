
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Document } from '@/domain/Document'
import { WorkflowProcess } from '@/domain/WorkflowProcess'
import { EntityCollection } from '@/global/models/EntityCollection'
import { AuthorizationRequest } from '@/api/requests/AuthorizationRequest'
import { Request } from '@/api/requests/Request'
import { plainToInstance } from 'class-transformer'
import { DocumentObjectiveProcess } from '@/domain/ObjectiveProcess'
import { handleAxiosError } from '@/services/utils'
import { ObjectiveData } from '../types'
import DocumentObjectiveCard from '@/modules/cards/DocumentObjectiveCard.vue'
import ItemDrawer from '@/global/components/ItemDrawer.vue'
import WorkflowProcessTimeline from './WorkflowProcessTimeline.vue'

@Component({
  components: {
    DocumentObjectiveCard,
    ItemDrawer,
    WorkflowProcessTimeline,
  },
})
export default class ObjectivesTab extends Vue {
  @Prop({ type: Object }) public process!: WorkflowProcess<Document>
  private documentObjectives: EntityCollection<DocumentObjectiveProcess>
  private loading = false
  private itemIsVisible = false
  private selectedItem: DocumentObjectiveProcess | null

	constructor() {
    super()
    this.documentObjectives = new EntityCollection()
    this.selectedItem = null
	}

  public get dataCollection(): ObjectiveData[] {
    return this.documentObjectives.select(item => item.toData()).toArray()
  }

  public get documentId(): number {
    return this.process.id
  }

  @Watch('documentId', { immediate: true })
  public async onChange(): Promise<void> {
    await this.loadObjectives()
  }

  public openCard(key: number): void {
    this.selectedItem = this.documentObjectives.byId(key) || null
    if (this.selectedItem) this.itemIsVisible = true
  }

  public closeCard(): void {
    this.itemIsVisible = false
    this.selectedItem = null
  }

  public addDocumentObjective(item: DocumentObjectiveProcess): void {
    if (this.documentObjectives.any(current => current.equalsById(item))) return
    this.documentObjectives.push(item)
  }

  public async loadObjectives(): Promise<void> {
    try {
      this.loading = true
      const request = new AuthorizationRequest(new Request<unknown[]>(`api/documentObjectives/${this.documentId}`))
      const response = await request.response()
      const processes = plainToInstance(DocumentObjectiveProcess, response.data)
      this.documentObjectives.push(...processes)
    } catch (ex) {
      handleAxiosError(ex as Error)
    } finally {
      this.loading = false
    }

  }
}
