import { IEntity } from '@/infrastucture/IEntity'
import { Expose } from 'class-transformer'
import { AbstractEntityVisitor } from './EntityVisitor'
import { IAcceptor } from './IAcceptor'

export class Entity implements IAcceptor<AbstractEntityVisitor>, IEntity {
  @Expose({ name: 'id' })
  private _id: number
  public constructor()
  public constructor(id: number)
  public constructor(id?: number) {
    this._id = id ?? 0
  }

  public equalsById(other: Entity): boolean
  public equalsById(other: IEntity): boolean
  public equalsById(other: Entity | IEntity): boolean {
    return this.id === other.id
  }

  public equals(other: Entity): boolean {
    return this.equalsById(other)
  }

  public get id(): number {
    return this._id
  }

  public get isAny(): boolean {
    return this.id <= 0
  }

  public addIdentity(id: number): void {
    if (this.isAny) this._id = id
  }
  
  public accept(visitor: AbstractEntityVisitor): void {
    visitor.visitEntity({ Id: this.id })
  }
}
