import { Collection } from '@/global/models/Collection'
import { ITableSource } from '@/global/table/models/TableCollection'
import { ITableColumn, TableColumn } from '@/global/table/viewModels/Column'
import { ReporterForm } from './ReporterForm'

export class ReporterForms extends Collection<ReporterForm> implements ITableSource<ReporterForm> {
  public columns(): ITableColumn<ReporterForm>[] {
    return [
      new TableColumn('addButton', 'empty', 50, false, false, undefined, { title: 'addButton' }),
      new TableColumn('Имя', 'fullName', 450, false, false, { customRender: 'nameFields' }),
      new TableColumn('Должность', 'jobTitle.viewContent', 300, false, false, { customRender: 'jobTitleField' }),
      new TableColumn('Email', 'email.viewContent', 300, false, false, { customRender: 'emailField' }),
      new TableColumn('Телефон', 'phone.viewContent', 200, false, false, { customRender: 'phoneField' }),
      new TableColumn('', 'editOrSave', 100, false, false, { customRender: 'editOrSave' }),
      new TableColumn('', 'removeOrCancel', 100, false, false, { customRender: 'removeOrCancel' }),
    ]
  }

  public source(): ReporterForm[] {
    return this.toArray()
  }
}